import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import SportOddsWidgetModel from '../models/sport-odds-widget.model';
import GameModel from '../../../../../../../models/v2/game/game.model';
import BlockModel from '../../../../models/block.model';
import SportBookmakerSelectByEventId from '../../../../partials/sport-bookmaker-select/sport-bookmaker-select-by-event-id.component';
import SportBookmakerModel from '../../../../../../../models/sport-odds/sport-bookmaker.model';
import { extractMarketTypes } from '../../../../partials/sport-market-select/helpers/sport-markets.helper';
import OddsModel from '../../../../../../../models/sport-odds/odds.model';
import SportMarketSelect from '../../../../partials/sport-market-select/sport-market-select.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../../../v2/widgets-v2/helpers/component-attributes.constants';

type Properties = {
	block: BlockModel;
	t: any;
	onOddsWidgetChange?: any;
	game: GameModel;
	displayOddsLabel: boolean;
	isValid?: any;
	widgetType?: string;
	contentData?: any;
	contentLanguage: string;
	currentOdds?: SportOddsWidgetModel;
	sport: string;
};

type State = {
	oddsWidgetModel: SportOddsWidgetModel;
	isValid: boolean;
};

type RequiredProperties = {
	block: any;
	t: any;
	onOddsWidgetChange?: any;
	game?: GameModel;
	displayOddsLabel: boolean;
	isValid?: boolean;
	widgetType?: string;
	contentData?: any;
	contentLanguage: string;
	currentOdds?: SportOddsWidgetModel;
	sport: string;
};

class SportOddsWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);

		this.state = {
			oddsWidgetModel: props.currentOdds
				? SportOddsWidgetModel.builder(props.currentOdds)
						.withGame(props.currentOdds.game ? props.currentOdds.game : this.props.game)
						.build()
				: SportOddsWidgetModel.builder()
						.withGame(this.props.game ? this.props.game : ({} as GameModel))
						.withBookmakers(null)
						.withMarket('12')
						.build(),
			isValid: true,
		};
	}

	componentDidUpdate(prevProps: any, prevState: any) {
		if (this.props.onOddsWidgetChange && this.props.game && prevProps.game.id !== this.props.game.id) {
			this.updateWidgetModelState(SportOddsWidgetModel.builder(this.state.oddsWidgetModel).withGame(this.props.game).build());
		}
	}

	updateWidgetModelState = (model: SportOddsWidgetModel, from?: string) => {
		this.props.onOddsWidgetChange(model);
		this.setState({ ...this.state, oddsWidgetModel: model });
	};

	onGameChange = (game: GameModel) => {
		this.updateWidgetModelState(SportOddsWidgetModel.builder(this.state.oddsWidgetModel).withGame(game).build(), 'onGameChange');
	};

	onBookmakerChange = (bookmakers: SportBookmakerModel[]) => {
		if (bookmakers && bookmakers.length === 0) {
			this.updateWidgetModelState(SportOddsWidgetModel.builder(this.state.oddsWidgetModel).withBookmakers(null).withMarket('').build());
		} else {
			this.updateWidgetModelState(SportOddsWidgetModel.builder(this.state.oddsWidgetModel).withBookmakers(bookmakers).build());
		}
	};

	onMarketChange = (marketName: string) => {
		this.updateWidgetModelState(SportOddsWidgetModel.builder(this.state.oddsWidgetModel).withMarket(marketName).build());
	};

	onMarketValueChange = (market: any) => {
		this.updateWidgetModelState(
			SportOddsWidgetModel.builder(this.state.oddsWidgetModel)
				.withMarket({ name: this.state.oddsWidgetModel.market.name, value: market.key })
				.build(),
		);
	};

	onOddsListUpdate = (oddsList: OddsModel[]) => {
		this.updateWidgetModelState(SportOddsWidgetModel.builder(this.state.oddsWidgetModel).withOddsList(oddsList).build());
	};

	render() {
		const { oddsWidgetModel } = this.state;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);

		return (
			<>
				{this.props.displayOddsLabel && (
					<Row className='mb-1 mt-4'>
						<Col>
							<h5>{this.props.t('odds_data')}</h5>
						</Col>
					</Row>
				)}

				{bookmakerSelectionEnabled && (
					<Row>
						<Col>
							<FormGroup>
								<SportBookmakerSelectByEventId
									isValid={this.props.isValid}
									showAllBookmakers={false}
									bookmakerSelectionEnabled={bookmakerSelectionEnabled}
									t={this.props.t}
									eventId={oddsWidgetModel.game ? oddsWidgetModel.game.id : null}
									valueList={oddsWidgetModel.bookmakers ? oddsWidgetModel.bookmakers : null}
									onSelect={this.onBookmakerChange}
									onOddsListUpdate={this.onOddsListUpdate}
									contentLanguage={this.props.contentLanguage}
									sport={this.props.sport}
									id={`${this.props.sport}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}

				{bookmakerSelectionEnabled &&
					oddsWidgetModel.bookmakers &&
					oddsWidgetModel.bookmakers.length > 0 &&
					oddsWidgetModel.oddsList &&
					oddsWidgetModel.oddsList.length > 0 && (
						<Row>
							<Col>
								<FormGroup>
									<SportMarketSelect
										t={this.props.t}
										value={oddsWidgetModel.market}
										markets={extractMarketTypes(oddsWidgetModel, this.props.t)}
										onMarketSelect={this.onMarketChange}
										id={`${this.props.sport}-${SPORT_SELECT_ATTRIBUTES.MARKET_SELECT}`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
			</>
		);
	}
}

export default SportOddsWidgetEdit as React.ComponentType<RequiredProperties>;

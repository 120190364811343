import React from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Properties, State } from './Properties/AuthorEditProperties';
import HttpService from '../../../services/rest/HttpService';
import { toast } from 'react-toastify';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { ImageUpload } from '../../Partials/Fields/image-upload/image-upload';
import Author from '../../../models/author/Author';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import {
	AUTHOR_ENTITY_RECEIVED,
	AUTHOR_ENTITY_REQUEST,
	AUTHOR_ENTITY_UPDATE,
	AUTHOR_ENTITY_UPDATE_FAILED,
	AUTHOR_ENTITY_UPDATE_SUCCESS,
	returnObjectForAuthorEntityRequest,
	returnObjectForAuthorEntityUpdate,
	returnObjectForDefaultAuthorEntityUpdate,
} from '../../../store/action-creators/AuthorActionCreators';
import { Active } from '../../Partials/Fields/active/ActiveComponent';
import { IsDefault } from '../../Partials/Fields/is-default/IsDefaultComponent';
import { Bio } from '../../Partials/Fields/bio/BioComponent';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { actionService } from '../../../App';
import { Subscription } from 'rxjs';
import SeoModel from '../../../models/seo/Seo';
import { SeoComponentModel } from '../../Partials/Sidebar/Seo/models/SeoComponentModel';
import { authorToSeoComponentModelForEdit, seoComponentModelToAuthor } from './helpers/AuthorHelper';
import Seo from '../../Partials/Sidebar/Seo/SeoComponent';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import { ContentTypes } from '../../../constants/content-types';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';

class AuthorEdit extends React.Component<Properties, State> {
	actionServiceSubscription: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			author: Author.builder(props.author).build(),
			isContentLoading: false,
			isSidebarInEdit: false,
			avatarUploaded: false,
			withAvatar: false,
			file: {},
			openSidebarSettingsModalFlag: false,
		};

		this.registerActionEventListener();
	}

	registerActionEventListener() {
		this.actionServiceSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === AUTHOR_ENTITY_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === AUTHOR_ENTITY_RECEIVED) {
				this.toggleContentLoadingState(false);
			}

			if (action === AUTHOR_ENTITY_UPDATE_SUCCESS) {
				this.state.author.isDefault
					? this.props.updateDefaultAUthor(this.state.author)
					: this.props.updateDefaultAUthor(Author.builder().build());
			}
		});
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.getAuthor(this.props.match.params.id, this.props.project);
		appendBeforeUnloadResetTempSidebar(ContentTypes.AUTHOR);
	}

	componentWillUnmount() {
		this.actionServiceSubscription.unsubscribe();
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any): void {
		if (!Author.builder(prevProps.author).equals(this.props.author)) {
			this.setState({ author: this.props.author });
		}
	}

	initPageTitle() {
		document.title = this.props.t('author_edit');
	}

	onNameChange(name: string) {
		const seo = SeoModel.builder(this.state.author.seo).withAutoUpdateSeo(name).build();
		const author = Author.builder(this.state.author).withSeo(seo).withName(name).build();
		this.setState({ author });
	}

	onBioChange(bio: string) {
		const author = Author.builder(this.state.author).withBio(bio).build();
		this.setState({ author });
	}

	onSeoChange(seoModel: SeoComponentModel) {
		const author = seoComponentModelToAuthor(seoModel, this.state.author);
		this.setState({ author });
	}

	onFileChange = (file: any) => {
		this.setState({ file });
		this.uploadAvatar(file);
	};

	uploadAvatar = async (file: any) => {
		if (file && file.name) {
			const headers = { Project: this.props.project.domain, 'content-type': 'multipart/form-data' };
			const formData = new FormData();
			formData.append('file', file);
			await HttpService.postImg('/upload', formData, headers)
				.then((response: any) => {
					this.setState({ withAvatar: true });
					toast.success(this.props.t('image_upload_success'));
					const avatar = `${response.data.path}?operations=autocrop(512:512)`;
					const author = Author.builder(this.state.author).withAvatarImage(avatar).build();
					this.setState({ author, withAvatar: true, avatarUploaded: true });
				})
				.catch(() => {
					this.setState({ withAvatar: true, avatarUploaded: false });
					toast.error(this.props.t('image_too_big_upload_error'));
				});
		}
	};

	onIsDefaultChange(isDefault: boolean) {
		if (isDefault) {
			this.setState({ author: Author.builder(this.state.author).withIsDefault(isDefault).withActive(true).build() });
		} else {
			this.setState({ author: Author.builder(this.state.author).withIsDefault(isDefault).build() });
		}
	}

	onActiveChange(active: boolean) {
		if (!active) {
			this.setState({ author: Author.builder(this.state.author).withActive(active).withIsDefault(false).build() });
		} else {
			this.setState({ author: Author.builder(this.state.author).withActive(active).build() });
		}
	}

	onSubmit = async () => {
		if (this.state.withAvatar) {
			if (this.state.avatarUploaded) {
				await this.props.updateAuthor(this.state.author, this.props.project);
			} else {
				this.setState({ withAvatar: false });
				toast.error(this.props.t('author_update_failed'));
			}
		} else if (!this.state.withAvatar) {
			await this.props.updateAuthor(this.state.author, this.props.project);
		}
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.AUTHOR);
		this.setState(newState);
	};

	render() {
		const { t, defaultAuthor } = this.props;
		const { isSidebarInEdit, openSidebarSettingsModalFlag } = this.state;
		const seoModel = authorToSeoComponentModelForEdit(this.state.author);
		const sidebarComponentsMap = {
			seo: <Seo contentType={ContentTypes.AUTHOR} onChange={this.onSeoChange.bind(this)} value={seoModel} />,
		};
		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0 text-truncate'}>
										{t('edit')}: {this.props.author ? this.props.author.name : ''}
									</span>
									<BlockableButtonWrapper
										blockOnActions={[AUTHOR_ENTITY_UPDATE]}
										releaseOnActions={[AUTHOR_ENTITY_UPDATE_SUCCESS, AUTHOR_ENTITY_UPDATE_FAILED]}
									>
										<Button size='sm' color='primary' id='author-edit-save-top' className={'ml-auto text-nowrap'} onClick={this.onSubmit.bind(this)}>
											<i className='fa fa-floppy-o'></i> {t('save_author')}
										</Button>
									</BlockableButtonWrapper>
								</div>
							</div>
							<div className='card-body'>
								<div className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
									<Row>
										<Col xs='12'>
											<Label htmlFor='title'>{t('title')}</Label>
											<Title t={t} onChange={this.onNameChange.bind(this)} value={this.state.author ? this.state.author.name : ''} />
										</Col>
									</Row>
									<Row>
										<Col xs='12'>
											<Bio t={t} onChange={this.onBioChange.bind(this)} value={this.state.author ? this.state.author.bio : ''} />
											<ImageUpload avatar={this.state.author.avatarImage} change={this.onFileChange} authorName={this.state.author.name} />
										</Col>
									</Row>
									<Row>
										<Col xs='12'>
											<IsDefault
												t={t}
												onChange={this.onIsDefaultChange.bind(this)}
												value={this.state.author.isDefault ? this.state.author.id === (defaultAuthor && defaultAuthor.id) : false}
												isChecked={this.state.author.isDefault ? this.state.author.isDefault : false}
											/>
										</Col>
									</Row>
									<Row>
										<Col xs='12'>
											<Active
												t={t}
												onChange={this.onActiveChange.bind(this)}
												value={this.state.author.active ? this.state.author.active : false}
												isChecked={this.state.author.active ? this.state.author.active : false}
											/>
										</Col>
									</Row>
									<Row className={'mt-4'}>
										<Col>
											<BlockableButtonWrapper
												blockOnActions={[AUTHOR_ENTITY_UPDATE]}
												releaseOnActions={[AUTHOR_ENTITY_UPDATE_SUCCESS, AUTHOR_ENTITY_UPDATE_FAILED]}
											>
												<Button
													color={'primary'}
													id='author-edit-save-bottom'
													className={'text-uppercase font-weight-bold'}
													onClick={this.onSubmit.bind(this)}
												>
													<i className='fa fa-floppy-o'></i> {t('save_author')}
												</Button>
											</BlockableButtonWrapper>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.AUTHOR)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.AUTHOR}
							sidebarComponentsMap={sidebarComponentsMap}
							t={t}
							currentProject={this.props.project}
						/>
					</Col>
				</Row>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.AUTHOR, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.AUTHOR, defaultTempSidebarFromApiResponse[ContentTypes.AUTHOR].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		author: state.author.author,
		project: state.project.currentProject,
		defaultAuthor: state.author.defaultAuthor,
		types: state.types,
		profile: state.profile.profile,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateAuthor: (author: Author, project: Project) => dispatch(returnObjectForAuthorEntityUpdate(author, project)),
		getAuthor: (id: string, project: Project) => dispatch(returnObjectForAuthorEntityRequest(id, project)),
		updateDefaultAUthor: (author: Author) => dispatch(returnObjectForDefaultAuthorEntityUpdate(author)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), withRouter)(AuthorEdit) as React.ComponentType;

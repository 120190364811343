import Author from '../../models/author/Author';
import {
	AUTHOR_ENTITY_DELETE_FAILED,
	AUTHOR_ENTITY_DELETE_SUCCESS,
	AUTHOR_ENTITY_RECEIVED,
	AUTHOR_LISTING_RECEIVED,
	PROJECT_AUTHOR_LISTING_RECEIVED,
	DEFAULT_AUTHOR_UPDATE,
	PROJECT_DEFAULT_AUTHOR_RECEIVED,
	AUTHOR_LISTING_UPDATE,
} from '../action-creators/AuthorActionCreators';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';

interface InitialState {
	author: Author;
	authors: Author[];
	authorListing: Author[];
	pagination: PaginationMeta;
	authorDeleted: boolean;
	defaultAuthor: Author;
}

const initialState: InitialState = {
	author: Author.builder().build(),
	authors: [],
	authorListing: [],
	authorDeleted: false,
	pagination: PaginationMeta.builder().build(),
	defaultAuthor: Author.builder().build(),
};

function authorReducer(state: any = initialState, action: any) {
	if (action.type === PROJECT_AUTHOR_LISTING_RECEIVED) {
		state = Object.assign({}, state, {
			authors: action.payload,
		});
	}

	if (action.type === PROJECT_DEFAULT_AUTHOR_RECEIVED) {
		state = Object.assign({}, state, {
			defaultAuthor: action.payload,
		});
	}

	if (action.type === AUTHOR_LISTING_RECEIVED) {
		state = Object.assign({}, state, {
			authorListing: action.payload,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === AUTHOR_LISTING_UPDATE) {
		state = Object.assign({}, state, {
			authorListing: action.payload,
		});
	}

	if (action.type === AUTHOR_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			author: action.payload.author,
		});
	}

	if (action.type === AUTHOR_ENTITY_DELETE_SUCCESS) {
		return Object.assign({}, state, {
			authorDeleted: true,
		});
	}

	if (action.type === AUTHOR_ENTITY_DELETE_FAILED) {
		return Object.assign({}, state, {
			authorDeleted: false,
		});
	}

	if (action.type === DEFAULT_AUTHOR_UPDATE) {
		return Object.assign({}, state, {
			defaultAuthor: action.payload.author,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default authorReducer;

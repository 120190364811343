import { TypedJSON } from 'typedjson';
import CompetitionModel from '../../../../../../models/v2/competition/entity/competition.model';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';

export const responseToCompetitionModel = (response: any) => {
	const serializer = new TypedJSON(CompetitionModel);
	const remmapedCompetition = serializer.parse(response) as CompetitionModel;

	return remmapedCompetition;
};

export const competitionsToOptions = (competitions: CompetitionModel[]) => {
	if (competitions && competitions.length > 0) {
		return competitions.map((competition) => {
			return competitionToOption(competition);
		});
	}

	return [];
};

export const competitionToOption = (competition: CompetitionModel) => {
	const countryFlag =
		competition.country && competition.country.assets && competition.country.assets.flag && competition.country.assets.flag.url
			? competition.country.assets.flag.url
			: assetsPlaceholder.country;
	const hasCountry = competition.country && competition.country.id && competition.country.id.length > 0;

	if (competition) {
		return {
			value: competition.id,
			label: hasCountry ? `${competition.name}  - ${competition.country.name}` : competition.name,
			data: competition,
			type: 'competition',
			logo: hasCountry ? countryFlag : '',
		};
	}

	return {};
};

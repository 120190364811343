import { ContentTypes } from '../../../../../constants/content-types';
import { relatedConstants } from '../../../../../constants/related.constants';
import MetaModel from '../../../../../models/v2/Meta/MetaModel';
import { responseToModelMeta } from '../../../../../models/v2/Meta/meta-model.mapper';
import PaginationMeta from '../../../../../models/v2/Pagination/PaginationModel';
import { responseToModelImage } from '../../../../../models/v2/image/image.mapper';
import ImageModel from '../../../../../models/v2/image/image.model';
import MainMediaModel from '../../../../../models/v2/main-media/main-media.model';
import { imageResourceSubType, imageResourceType } from '../constants/general';
import { watermarkProp } from '../constants/redux-main-media.attributes';

export const populateMainMediaDefaultAttributes = (mainMedia: MainMediaModel): MainMediaModel => {
	return {
		...mainMedia,
		provider: relatedConstants.providers.smp,
		resourceType: imageResourceType,
		resourceSubtype: imageResourceSubType,
	};
};

export const imageToMainImageModel = (image: ImageModel, applyWatermark: boolean = false): MainMediaModel => {
	const mainMediaStarterModel = {
		resourceId: image.id,
		description: image.description,
		createdAt: image.createdAt,
		updatedAt: image.updatedAt,
		data: image,
		addDescriptionToMedia: false,
		addWatermarkToImageMedia: applyWatermark,
		provider: relatedConstants.providers.smp,
		resourceType: imageResourceType,
		resourceSubtype: imageResourceSubType,
		generic: null,
	};

	return populateMainMediaDefaultAttributes(mainMediaStarterModel);
};

export const selectionToMainMediaModel = (data: any, type: string, subType?: string): MainMediaModel => {
	const mainMediaStarterModel = {
		resourceId: data.id,
		description: null,
		createdAt: data.createdAt,
		updatedAt: data.updatedAt,
		data: data,
		addDescriptionToMedia: false,
		addWatermarkToImageMedia: false,
		provider: relatedConstants.providers.smp,
		resourceType: type,
		resourceSubtype: subType ? subType : null,
		generic: null,
	};

	return mainMediaStarterModel;
};

export const isWatermarkAvailableForContent = (contentType: string | null): boolean => {
	return (
		contentType === ContentTypes.ARTICLE ||
		contentType === ContentTypes.VIDEO ||
		contentType === ContentTypes.GALLERY ||
		contentType === ContentTypes.WIKI_PAGE
	);
};

export const extractMainImageObjectFromArray = (mainMediaArray: MainMediaModel[]): MainMediaModel | null => {
	const defaultElementIfNotFound = null;

	if (mainMediaArray && mainMediaArray.length > 0) {
		const mainMediaElIndex = mainMediaArray.findIndex((el: MainMediaModel) => el.resourceSubtype === imageResourceSubType);
		return mainMediaElIndex >= 0 ? mainMediaArray[mainMediaElIndex] : defaultElementIfNotFound;
	}

	return defaultElementIfNotFound;
};

export const extractExternalObjectFromArray = (mainMediaArray: MainMediaModel[], type: string): MainMediaModel | null => {
	const defaultElementIfNotFound = null;

	if (mainMediaArray && mainMediaArray.length > 0) {
		const mainMediaElIndex = mainMediaArray.findIndex((el: MainMediaModel) => el.resourceType === type);
		return mainMediaElIndex >= 0 ? mainMediaArray[mainMediaElIndex] : defaultElementIfNotFound;
	}

	return defaultElementIfNotFound;
};

export const remapResponseListImages = (responseImagesArray: []): ImageModel[] => {
	try {
		return responseImagesArray.map((responseImage: any) => responseToModelImage(responseImage)).filter((el) => el !== undefined);
	} catch (error) {
		return [];
	}
};

export const remapResponseAsMetaResource = (resp: any): PaginationMeta => {
	const metaResources = resp.data.meta;
	const remappedMetaResources = responseToModelMeta(metaResources) || new MetaModel();
	return remappedMetaResources.pagination;
};

export const validateSelectedPage = (currentPage: number): number => {
	return currentPage && currentPage > 0 ? currentPage : 1;
};

export const areAnyFiltersSelected = (contentSearchInput: string, advancedContentSearchInput: string): boolean => {
	const trimmedContentSearch = contentSearchInput.trim();
	const trimmedAdvancedContentSearchInput = advancedContentSearchInput.trim();
	return !!(trimmedContentSearch || trimmedAdvancedContentSearchInput);
};

export const generateRequestSearchQuery = (contentSearchInput: string, advancedContentSearchInput: string): string => {
	if (areAnyFiltersSelected(contentSearchInput, advancedContentSearchInput)) {
		return contentSearchInput.length > 0 ? contentSearchInput + advancedContentSearchInput : `*${advancedContentSearchInput}`;
	}

	return '*';
};

export const isCustomDataPopulatedWithWatermark = (genericRedux: Record<string, any> | null) => {
	if (genericRedux) {
		const keys = Object.keys(genericRedux);
		const isWatermarkKeyAvailable = keys.findIndex((el) => el === watermarkProp) >= 0;
		return isWatermarkKeyAvailable ? !!genericRedux[watermarkProp] : false;
	}

	return false;
};

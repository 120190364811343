import React from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import OddsModelV2 from '../models/odds-widget.model';

type Properties = {
	blockPreview: OddsModelV2;
	t: any;
	displayOddsLabel?: boolean;
};

export const OddsWidgetView: React.FunctionComponent<Properties> = ({ blockPreview, t, displayOddsLabel }) => {
	if (blockPreview && blockPreview.match && blockPreview.match.id) {
		return (
			<div>
				{displayOddsLabel && (
					<Row className='text-left pt-2'>
						<Col>
							<h6>{t('odds_widget_for_match')}</h6>
						</Col>
					</Row>
				)}
				<Row className={`${displayOddsLabel ? 'pl-4 pr-4' : 'pt-2 pl-4 pr-4'}`}>
					<Col className='text-left'>
						<Row className='mb-1'>
							<Col>
								<div className='mb-1 font-weight-bold d-inline-block mr-5'>
									{blockPreview.match.startTime ? moment(blockPreview.match.startTime).format('MMM Do YYYY, HH:mm') : ''}
								</div>
								<div className='d-inline-block'>
									<strong className='p-1'>{blockPreview.match.homeTeam ? blockPreview.match.homeTeam.name : ''}</strong>
									<img
										height='40'
										alt={blockPreview.match.homeTeam ? blockPreview.match.homeTeam.name : ''}
										src={blockPreview.match.homeTeam.urlLogo ? blockPreview.match.homeTeam.urlLogo : assetsPlaceholder.team}
										className='h-40 mx-1'
									/>
								</div>
								<div className='d-inline-block font-weight-bold'>:</div>
								<div className='d-inline-block'>
									<img
										height='40'
										alt={blockPreview.match.awayTeam ? blockPreview.match.awayTeam.name : ''}
										src={blockPreview.match.awayTeam.urlLogo ? blockPreview.match.awayTeam.urlLogo : assetsPlaceholder.team}
										className='h-40 mx-1'
									/>
									<strong className='p-1'>{blockPreview.match.awayTeam ? blockPreview.match.awayTeam.name : ''}</strong>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<hr></hr>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col>
								{t('show')}:
								{blockPreview.mainBookmaker && blockPreview.mainBookmaker.id && (
									<img
										key={blockPreview.mainBookmaker.id}
										height='20'
										alt={blockPreview.mainBookmaker.name}
										className='h-20 mx-1'
										src={blockPreview.mainBookmaker.logo ? blockPreview.mainBookmaker.logo : imagePlaceHolder}
										title={blockPreview.mainBookmaker.name}
									/>
								)}
							</Col>
						</Row>
						{blockPreview.market && blockPreview.market.name && blockPreview.market.name.length > 0 && (
							<Row>
								<Col className='text-left'>
									<span>{t('default_market')}: </span>
									<strong>{t(blockPreview.market.name)}</strong>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</div>
		);
	}

	return null;
};

import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { removeSuggestedEntitiesByOrigin } from '../../../../../../store/action-creators/suggested-entities';
import { store } from '../../../../../../store/store';
import { EditorTypes } from '../../../constants/block.types';

export default class EditorHelper {
	autoTagFeatureLinkOptionsHasLinkType = (type) => {
		const config = featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING);

		if (config && config.linkOptions) {
			return config.linkOptions[type] && config.linkOptions[type].length > 0;
		} else {
			return false;
		}
	};

	hasAutoTagLinkOptionsConfig = () => {
		const config = featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING);

		return config && config.hasOwnProperty('linkOptions');
	};

	setEditorFocused = (id, isFocused) => {
		localStorage.setItem('editor-focus-change', JSON.stringify({ id, focused: isFocused }));
	};

	isEditorFocused = (id) => {
		const focusInfoString = localStorage.getItem('editor-focus-change');

		if (focusInfoString && focusInfoString.length > 0) {
			const focusInfo = JSON.parse(localStorage.getItem('editor-focus-change'));

			if (`${focusInfo.id}` === `${id}`) {
				return focusInfo.focused;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	getContentForNewParagraph = () => {
		const content = localStorage.getItem('ckeditor-after-enter-content');

		if (content) {
			localStorage.setItem('ckeditor-after-enter-content', '');

			return content;
		}
	};

	getLinkingFeatureConfig = () => {
		const isEntityLinkingEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.AUTO_TAGGING);
		const autoTagConfig = featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING)
			? featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING)
			: null;
		const isEntityLinkingManual = autoTagConfig ? featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING).isManual : false;

		return {
			isLinkingEnabled: isEntityLinkingEnabled,
			isManual: isEntityLinkingManual,
			config: autoTagConfig,
		};
	};

	updateHTML = (clipboardPlugin, html, dataTransfer, editor, evt) => {
		html = clipboardPlugin._htmlDataProcessor.toView(html);
		clipboardPlugin.fire('inputTransformation', { content: html, dataTransfer });
		editor.editing.view.scrollToTheSelection();
		evt.stop();
	};

	static updateEditor(editor, placeholderName) {
		placeholderName = placeholderName ? placeholderName : 'ckeditor-fresh-init';

		if (window.editors === null || window.editors === undefined) {
			window.editors = {};
		}
		window.editors[placeholderName] = editor;
	}

	static getEditor(placeholderName) {
		placeholderName = placeholderName ? placeholderName : 'ckeditor-fresh-init';

		return window.editors ? window.editors[placeholderName] : null;
	}

	static destroyEditor(placeholderName, blockType) {
		placeholderName = placeholderName ? placeholderName : 'ckeditor-fresh-init';

		if (window.editors && window.editors[placeholderName]) {
			window.editors[placeholderName].destroy();
			delete window.editors[placeholderName];
		}

		try {
			// Remove suggested entities related with the current paragraph
			if (blockType === EditorTypes.paragraph) {
				store.dispatch(removeSuggestedEntitiesByOrigin(placeholderName));
			}
		} catch (e) {}
	}

	static addAdditionalAttributesToHeading(element, state, placeholderName) {
		const editor = this.getEditor(placeholderName);
		if (state.length > 0) {
			editor && editor.sourceElement.firstChild.setAttribute(element, state);
		} else {
			editor && editor.sourceElement.firstChild.removeAttribute(element);
		}
		return editor && editor.setData(editor.sourceElement.firstChild.outerHTML);
	}

	static extractID(content) {
		let flag = /id=/;

		if (content && content.match(flag)) {
			const paramsPattern = /id="(.*?)"/g;
			let extractParams = content.match(paramsPattern);
			const replacedString = extractParams && extractParams[0].replace('id=', '').replaceAll('"', '');

			if (replacedString !== null && replacedString != undefined) return replacedString;
		}

		return '';
	}

	static extractTabIndex(content) {
		let flag = /tabindex=/;
		let tabIndex;

		if (content && content.match(flag)) {
			const paramsPattern = /tabindex="(.*?)"/g;
			let extractParams = content.match(paramsPattern);
			tabIndex = extractParams && extractParams[0].replace(/[", tabindex=]/g, '');
			if (tabIndex !== null && tabIndex != undefined) return tabIndex;
		}

		return '';
	}
}

import Author from '../author/Author';
import Urls from '../urls/Urls';
import Seo from '../seo/Seo';
import Category from '../category/Category';
import MainMedia from '../mainmedia/MainMedia';
import { MainMediaJson } from '../mainmedia/MainMediaJson';
import ContentAttributes from '../content-attributes/content-attributes-model';
import BlockModel from '../../views/Partials/Blocky/models/block.model';
import { filterEmptyBlocks } from '../helpers/content-helpers';
import TranslationGroupModel from '../translation-groups/translation-group-model';
import { WikiJson } from './WikiJson';
import Wiki from './Wiki';
import { WikiRequestJson } from './WikiRequestJson';
import { DynamicContentProperty } from '../../constants/content-types';

export default class WikiBuilder {
	private json: WikiJson;

	constructor(wiki?: Wiki | WikiJson) {
		if (wiki && wiki instanceof Wiki) {
			this.json = wiki.toJSON();
		} else if (wiki) {
			this.json = wiki;
		} else {
			this.json = {} as WikiJson;
		}
	}

	withId(id: string): WikiBuilder {
		this.json.id = id;

		return this;
	}

	withEntityType(entityType: string): WikiBuilder {
		this.json.entityType = entityType;

		return this;
	}

	withTitle(title: string): WikiBuilder {
		this.json.title = title;

		return this;
	}

	withSubtitle(subtitle: string): WikiBuilder {
		this.json.subtitle = subtitle;

		return this;
	}

	withStrapline(strapline: string): WikiBuilder {
		this.json.strapline = strapline;

		return this;
	}

	withType(type: string): WikiBuilder {
		this.json.type = type;

		return this;
	}

	withBody(body: BlockModel[]): WikiBuilder {
		this.json.body = body;

		return this;
	}

	withPublishedAt(publishedAt: string): WikiBuilder {
		this.json.publishedAt = publishedAt;

		return this;
	}

	withUpdatedAt(updatedAt: string): WikiBuilder {
		this.json.updatedAt = updatedAt;

		return this;
	}

	withPublishedUntil(publishedUntil: string): WikiBuilder {
		this.json.publishedUntil = publishedUntil;

		return this;
	}

	withStatus(status: string): WikiBuilder {
		this.json.status = status;

		return this;
	}

	withComments(comments: any): WikiBuilder {
		this.json.comments = comments;

		return this;
	}

	withViewsCount(viewsCount: any): WikiBuilder {
		this.json.viewsCount = viewsCount;

		return this;
	}

	withTranslationGroup(translationGroup: TranslationGroupModel): WikiBuilder {
		this.json.translationGroup = translationGroup;

		return this;
	}

	withCommentsCount(commentsCount: any): WikiBuilder {
		this.json.commentsCount = commentsCount;

		return this;
	}

	withCustomAuthor(customAuthor: string): WikiBuilder {
		this.json.customAuthor = customAuthor;

		return this;
	}

	withLanguage(language: string): WikiBuilder {
		this.json.language = language;

		return this;
	}

	withAuthors(authors: Author[]): WikiBuilder {
		this.json.authors = authors;

		return this;
	}

	withImage(image: any): WikiBuilder {
		this.json.image = image;

		return this;
	}

	withGeneric(generic: any): WikiBuilder {
		this.json.generic = generic;

		return this;
	}

	withContentFooter(footer: string): WikiBuilder {
		this.json.footer = footer;

		return this;
	}

	withUrls(urls: Urls): WikiBuilder {
		this.json.urls = urls;

		return this;
	}

	withSeo(seo: Seo): WikiBuilder {
		this.json.seo = seo;

		return this;
	}

	withCategory(category: Category): WikiBuilder {
		this.json.category = category;

		return this;
	}

	withAdditionalCategories(additionalCategories: Category[]): WikiBuilder {
		this.json.additionalCategories = additionalCategories;

		return this;
	}

	withMainMedia(mainMedia: MainMedia[]): WikiBuilder {
		this.json.mainMedia = mainMedia;

		return this;
	}

	withCreatedBy(createdBy: any[]): WikiBuilder {
		this.json.createdBy = createdBy;

		return this;
	}

	withCreatedAt(createdAt: string): WikiBuilder {
		this.json.createdAt = createdAt;

		return this;
	}

	withDistributionRegions(distributionRegions: ContentAttributes[]): WikiBuilder {
		this.json.distributionRegions = distributionRegions;

		return this;
	}

	withDistributionChannels(distributionChannels: ContentAttributes[]): WikiBuilder {
		this.json.distributionChannels = distributionChannels;

		return this;
	}

	withOrigin(origin: any): WikiBuilder {
		this.json.origin = origin;

		return this;
	}

	extractDefaultContent = (content: ContentAttributes[] = []): ContentAttributes => {
		const defaultType = content.find((type: ContentAttributes) => type.isDefault);

		return defaultType as ContentAttributes;
	};

	// this is used to set dynamic 'properties' placed in general content tab

	withDynamicProperties(dynamicProperties: DynamicContentProperty[]): WikiBuilder {
		this.json.properties = dynamicProperties.reduce((obj, item) => Object.assign(obj, { [item.slug]: item.value }), {});

		return this;
	}

	toRequestJson(): WikiRequestJson {
		const authorsForRequest = this.json.authors
			? this.json.authors.filter((author: any) => author && author.id).map((author: Author) => author.id)
			: [];

		let json = {} as WikiRequestJson;
		json.seo = {
			slug: '',
			title: '',
			description: '',
			keywords: [],
			index: false,
			follow: false,
			redirect_type: '',
			jsonld: '',
		};

		json.id = this.json.id;
		json.entity_type = this.json.entityType;
		json.title = this.json.title;
		json.subtitle = this.json.subtitle;
		json.strapline = this.json.strapline;
		json.body = filterEmptyBlocks(this.json.body);
		json.translation_group_id = this.json && this.json.translationGroup && this.json.translationGroup.id ? this.json.translationGroup.id : '';
		json.published_at = this.json.publishedAt;
		json.created_at = this.json.createdAt;
		json.published_until = this.json.publishedUntil;
		json.status = this.json.status || '';
		json.type = this.json.type || '';
		json.authors = authorsForRequest;
		json.custom_author = this.json.customAuthor;
		json.language = this.json.language ? this.json.language : '';
		json.image_id = '';
		json.image_description = '';
		json.generic = this.json.generic;
		json.footer = this.json.footer;
		json.seo.slug = this.json.seo && this.json.seo.slug ? this.json.seo.slug.toLowerCase() : '';
		json.seo.title = this.json.seo && this.json.seo.title ? this.json.seo.title : '';
		json.seo.description = this.json.seo && this.json.seo.description ? this.json.seo.description : '';
		json.seo.keywords = this.json.seo && this.json.seo.keywords ? this.json.seo.keywords : [];
		json.seo.index = this.json.seo && this.json.seo.index ? this.json.seo.index : false;
		json.seo.follow = this.json.seo && this.json.seo.follow ? this.json.seo.follow : false;
		json.seo.redirect_type = this.json.seo && this.json.seo.redirectType ? this.json.seo.redirectType : '';
		json.seo.jsonld = this.json.seo && this.json.seo.jsonld ? this.json.seo.jsonld : '';
		json.main_media = this.json.mainMedia
			? this.json.mainMedia.map((media: MainMediaJson) => {
					return {
						resource_id: media.resourceId,
						resource_type: media.resourceType,
						resource_subtype: media.resourceSubtype,
						provider: media.provider,
						description: media.description,
						data: media.data,
					};
			  })
			: [];

		// this will extract 'properties' object and will populate the 'json' object with its dynamic entities
		Object.keys(this.json.properties).forEach((propertyKey: string) => (json[propertyKey] = this.json.properties[propertyKey]));

		return json;
	}

	isEmpty(): boolean {
		if (this.json) {
			const isTitleSet = this.json.title && this.json.title.length > 0;
			const isStraplineSet = this.json.strapline && this.json.strapline.length > 0;
			const isSubtitleSet = this.json.subtitle && this.json.subtitle.length > 0;
			const isBodySet =
				this.json.body &&
				((this.json.body.length === 1 &&
					this.json.body[0].data.content &&
					this.json.body[0].data.content.replace(/(<([^>]+)>)/gi, '') !== '') ||
					this.json.body.length > 1);
			const isCategorySet = this.json.category;
			const isAdditionalCategoriesSet = this.json.additionalCategories && this.json.additionalCategories.length > 0;
			const isPublishedAtSet = this.json.publishedAt && this.json.publishedAt.length > 0;
			const isPublishedUntilSet = this.json.publishedUntil && this.json.publishedUntil.length > 0;
			const isCustomAuthorSet = this.json.customAuthor && this.json.customAuthor.length > 0;
			const isSeoSet = !Seo.builder(this.json.seo).isEmpty();
			const isUrlsSet = !Urls.builder(this.json.urls).isEmpty();
			const isMainMediaSet = this.json.mainMedia && this.json.mainMedia.length > 0;

			return !(
				isTitleSet ||
				isStraplineSet ||
				isSubtitleSet ||
				isBodySet ||
				isPublishedAtSet ||
				isPublishedUntilSet ||
				isCustomAuthorSet ||
				isAdditionalCategoriesSet ||
				isSeoSet ||
				isUrlsSet ||
				isMainMediaSet ||
				isCategorySet
			);
		}

		return true;
	}

	build(): Wiki {
		return Wiki.fromJSON(this.json);
	}
}

import { Label } from 'reactstrap';
import React from 'react';
import HttpService from '../../../../services/rest/HttpService';
import {
	contentStatisticsToOptions,
	extractOptionIds,
	relatedTypesMap,
	responseToOptions,
} from './helpers/search-content-by-type-container.helper';
import ListItem from '../../../../models/list/list-item/ListItem';
import { capitalize } from '../../../../global-helpers/global.helpers';
import moment from 'moment';
import { cssIconByContentType, returnCorrectUrlPathToContent } from '../../../Resources/Lists/ListsContent/list-content.helper';
import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import ContentItemStatisticsComponent from '../../../Resources/Lists/ListsContent/subcomponents/content-item-statistics.component';
import SuggestedListPagination from './suggested-list-pagination.component';
import PaginationMeta from '../../../../models/pagination/PaginationMeta';
import ModelMapper from '../../../../models/ModelMapper';
import Related from '../../../../models/related/Related';
import { SPORTS_RELATED_TYPES } from '../../../../constants/constants';
import { Link } from 'react-router-dom';
import ListItemAddButtons from '../../Sidebar/lists/subcomponents/list-item-add-buttons.component';
import List from '../../../../models/list/List';
import { ContentTypes } from '../../../../constants/content-types';
import { responseToPaginationModel } from '../../../../models/v2/Pagination/pagination.mapper';

type Properties = {
	type: string;
	domain: string;
	onRelatedContentSelect: (option: any, addItemToTop?: boolean) => void;
	t: any;
	list: List;
	listItems: ListItem[];
	categoryIdFilter?: string;
	sportsFilter?: Related[];
	toggleFirstLockPositionError: (toggleError: boolean) => any;
	showAddToTopToggle: boolean;
};

export default class SuggestedListOptions extends React.Component<Properties, any> {
	constructor(props: Properties) {
		super(props);

		this.state = {
			contentPagination: PaginationMeta.builder().build(),
			isLoading: false,
			options: [],
		};
	}

	private contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

	componentDidMount() {
		const { type } = this.props;
		this.getContent(type, 1);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<any>, snapshot?: any) {
		if (prevProps.categoryIdFilter !== this.props.categoryIdFilter) {
			this.getContent(this.props.type, 1);
		}
		if (prevProps.type !== this.props.type) {
			this.getContent(this.props.type, 1);
		}
	}

	private updateContentPagination = (pagination: PaginationMeta) => {
		this.setState({ contentPagination: pagination });
	};

	private setLoading = (loading: boolean) => {
		this.setState({ isLoading: loading });
	};

	private setOptions = (options: any) => {
		this.setState({ options: options });
	};

	private requestFilterBuilder = (categoryId: string = '', footballFilter: Related[] = [], type: string, selectedPage: number) => {
		const sportsRequestFilter = `${ContentTypes.LIVE_BLOG}s`
			? this.sportsRequestLiveBlogBuilder(this.extractEntityIds(footballFilter))
			: this.sportsRequestBuilder(this.extractEntityIds(footballFilter));
		let request = type === `${ContentTypes.LIVE_BLOG}s` ? `/liveblogs?` : `/v2/${type}/search?query=*`;
		let requestFilter = '';

		if (categoryId.length > 0) {
			requestFilter =
				type === `${ContentTypes.LIVE_BLOG}s` ? requestFilter + `&category_ids=${categoryId}` : requestFilter + `&category=${categoryId}`;
		}

		if (sportsRequestFilter.length > 0) {
			requestFilter = requestFilter + sportsRequestFilter;
		}

		if (requestFilter.length > 0) {
			request = request + requestFilter + `&page=${selectedPage}`;
		} else {
			return '';
		}

		return request;
	};

	private sportsRequestBuilder = (entities: any) => {
		let request = '';
		if (entities.coach.length > 0) {
			request = request + `&coachIds=${entities.coach.join(',')}`;
		}
		if (entities.tournament.length > 0) {
			request = request + `&tournamentIds=${entities.tournament.join(',')}`;
		}
		if (entities.team.length > 0) {
			request = request + `&teamIds=${entities.team.join(',')}`;
		}
		if (entities.player.length > 0) {
			request = request + `&playerIds=${entities.player.join(',')}`;
		}
		if (entities.venue.length > 0) {
			request = request + `&venueIds=${entities.venue.join(',')}`;
		}
		if (entities.arena.length > 0) {
			request = request + `&arenaIds=${entities.arena.join(',')}`;
		}
		if (entities.competition.length > 0) {
			request = request + `&competitionIds=${entities.competition.join(',')}`;
		}

		return request.length > 1 ? request : '';
	};

	private sportsRequestLiveBlogBuilder = (entities: any) => {
		let request = '';
		if (entities.team.length > 0) {
			request = request + `&team_ids=${entities.team.join(',')}`;
		}
		if (entities.competition.length > 0) {
			request = request + `&competition_ids=${entities.competition.join(',')}`;
		}

		return request.length > 1 ? request : '';
	};

	extractEntityIds = (sports: Related[]) => {
		const teamIds: string[] = [];
		const tournamentIds: string[] = [];
		const playerIds: string[] = [];
		const coachIds: string[] = [];
		const venueIds: string[] = [];
		const arenaIds: string[] = [];
		const competitionIds: string[] = [];

		sports.forEach((entity: Related) => {
			if (entity.type === SPORTS_RELATED_TYPES.PLAYER) {
				playerIds.push(`${entity.data.id}`);
			}
			if (entity.type === SPORTS_RELATED_TYPES.TEAM) {
				teamIds.push(`${entity.data.id}`);
			}
			if (entity.type === SPORTS_RELATED_TYPES.COACH) {
				coachIds.push(`${entity.data.id}`);
			}
			if (entity.type === SPORTS_RELATED_TYPES.TOURNAMENT) {
				tournamentIds.push(`${entity.data.id}`);
			}
			if (entity.type === SPORTS_RELATED_TYPES.VENUE) {
				venueIds.push(`${entity.data.id}`);
			}
			if (entity.type === SPORTS_RELATED_TYPES.ARENA) {
				arenaIds.push(`${entity.data.id}`);
			}
			if (entity.type === SPORTS_RELATED_TYPES.COMPETITION) {
				competitionIds.push(`${entity.data.id}`);
			}
		});

		return {
			team: teamIds,
			tournament: tournamentIds,
			player: playerIds,
			coach: coachIds,
			venue: venueIds,
			arena: arenaIds,
			competition: competitionIds,
		};
	};

	private getContent = (type: string, selectedPage: number): any => {
		const { domain, categoryIdFilter, sportsFilter } = this.props;

		this.setLoading(true);

		const header = { Project: domain };
		const filterByCategoryEndpoint = this.requestFilterBuilder(categoryIdFilter, sportsFilter, type, selectedPage);
		const contentListEndpoint =
			type === `${ContentTypes.LIVE_BLOG}s` ? `/liveblogs?page=${selectedPage}` : `/v2/${type}?page=${selectedPage}`;
		const endpoint = filterByCategoryEndpoint.length > 0 ? filterByCategoryEndpoint : contentListEndpoint;
		HttpService.get(endpoint, null, header)
			.then((response: any) => {
				const options = responseToOptions(response.data.data, relatedTypesMap[type]);
				this.setState(
					{
						isLoading: false,
						contentPagination:
							type === `${ContentTypes.LIVE_BLOG}s`
								? responseToPaginationModel(response.data.meta)
								: ModelMapper.remapMetaPagination(response.data.meta.pagination),
						options: options,
					},
					() => {
						if (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
							options &&
							options.length > 0 &&
							type !== `${ContentTypes.LIVE_BLOG}s`
						) {
							this.getContentStatistics(options);
						}
					},
				);
			})
			.catch(() => this.setLoading(false));
	};

	private getContentStatistics = (options: any) => {
		const { type } = this.props;
		const ids = extractOptionIds(options);

		HttpService.getContentStatistics(this.contentStatisticsConfig.request_headers[0], `${this.contentStatisticsConfig.url}?${type}=${ids}`)
			.then((response: any) => {
				const optionsWithStatistics = contentStatisticsToOptions(options, response.data);
				this.setOptions(optionsWithStatistics);
			})
			.catch((e: any) => e);
	};

	render() {
		const { onRelatedContentSelect, t, list, listItems, type, showAddToTopToggle } = this.props;
		const { contentPagination, isLoading, options } = this.state;
		return (
			<div className='my-2'>
				<Label>
					<strong>{t(type)}:</strong>
				</Label>
				<ul className={`${isLoading ? 'loading-overlay' : ''} list-group`}>
					{options &&
						options.length > 0 &&
						options
							.filter((option: any) => listItems && !listItems.find((el: any) => el && el.data && el.data.id === option.data.id))
							.map((option: any, index: number) => {
								return (
									<li key={`option-data-${option.data.id}-${index}`} className='list-group-item d-flex align-items-center'>
										<div className='mr-2'>
											<ListItemAddButtons
												t={t}
												currentContent={option}
												list={list}
												onListItemAdd={onRelatedContentSelect}
												toggleFirstLockPositionError={this.props.toggleFirstLockPositionError}
												showAddToTopToggle={showAddToTopToggle}
											/>
										</div>

										<i className={`fa ${cssIconByContentType[option.type]} p-1`} title={capitalize(option.type)} />
										<div className='d-flex flex-column mr-auto'>
											<strong className='p-1'>
												<Link to={returnCorrectUrlPathToContent(option)} className='text-dark'>
													{option.data.title}
												</Link>
											</strong>
											{option.type.toLocaleLowerCase() !== ContentTypes.LIVE_BLOG && (
												<div className='d-flex pl-1 pr-1'>
													<ContentItemStatisticsComponent t={t} value={option.data} style='pr-2 d-flex' />
												</div>
											)}
										</div>
										<small>{`${t('published_at')}: ${moment(option.publishedAt).format('DD MMMM YYYY, HH:mm')}`}</small>
									</li>
								);
							})}
					{options && options.length === 0 && <div />}
				</ul>
				<div className='mt-2'>
					<SuggestedListPagination t={t} type={type} getContent={this.getContent} contentPagination={contentPagination} />
				</div>
			</div>
		);
	}
}

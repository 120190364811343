import GalleryBlockBuilder from './gallery-block.builder';
import { GalleryBlockJson } from './gallery-block.json';

export default class GalleryBlockModel {
	readonly id: string;
	readonly title: string;
	readonly image: string;
	readonly subtitle: string;
	readonly publishedAt: string;
	readonly items: [];
	readonly selectedItemId: string;
	readonly category: any;
	readonly status: string;
	readonly additionalCategories: any;
	readonly mainImage: any;
	readonly width: string;
	readonly alignment: string;
	readonly language: string;

	private constructor(
		id: string,
		title: string,
		image: string,
		subtitle: string,
		publishedAt: string,
		items: [],
		selectedItemId: string,
		category: any,
		status: any,
		additionalCategories: any[],
		mainImage: any,
		width: string,
		alignment: string,
		language: string,
	) {
		this.id = id;
		this.title = title;
		this.image = image;
		this.subtitle = subtitle;
		this.publishedAt = publishedAt;
		this.items = items;
		this.selectedItemId = selectedItemId;
		this.category = category;
		this.status = status;
		this.additionalCategories = additionalCategories;
		this.mainImage = mainImage;
		this.width = width;
		this.alignment = alignment;
		this.language = language;
	}

	toJson(): GalleryBlockJson {
		return {
			id: this.id,
			title: this.title,
			image: this.image,
			subtitle: this.subtitle,
			publishedAt: this.publishedAt,
			items: this.items,
			selectedItemId: this.selectedItemId,
			category: this.category,
			status: this.status,
			additionalCategories: this.additionalCategories,
			mainImage: this.mainImage,
			width: this.width,
			alignment: this.alignment,
			language: this.language,
		};
	}

	static fromJson(json: GalleryBlockJson): GalleryBlockModel {
		return new GalleryBlockModel(
			json.id,
			json.title,
			json.image,
			json.subtitle,
			json.publishedAt,
			json.items,
			json.selectedItemId,
			json.category,
			json.status,
			json.additionalCategories,
			json.mainImage,
			json.width,
			json.alignment,
			json.language,
		);
	}

	static builder(gallery?: GalleryBlockJson): GalleryBlockBuilder {
		return new GalleryBlockBuilder(gallery);
	}
}

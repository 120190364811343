import TennisSeasonStagesModel from '../../../../../../models/v2/tennis-competition-season/entity/tennis-season-stages.model';

export const tournamentsToOptions = (stages: TennisSeasonStagesModel[]) => {
	if (stages && stages.length > 0) {
		return stages.map((stage) => {
			return tournamentToOption(stage);
		});
	}

	return [];
};

export const tournamentToOption = (stage: TennisSeasonStagesModel) => {
	if (stage) {
		return {
			value: stage.id,
			label: stage.name,
			data: stage,
			type: 'tournament',
		};
	} else {
		return {};
	}
};

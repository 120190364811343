import React, { useEffect } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import { marketOptionsV2 } from './helpers/market-select.helpers';

type Properties = {
	onMarketSelect: any;
	value: any;
	t: any;
	limitTo1x2?: boolean;
	id: string;
};

const MarketSelect: React.FunctionComponent<Properties> = ({ value, t, onMarketSelect, limitTo1x2, id }) => {
	useEffect(() => {
		if (value && value.length > 0) {
			onMarketSelect(value);
		}
	}, []);

	const propertyValueToOption = (value: any) => {
		if (value && value.length > 0) {
			const marketOptionValue = marketOptionsV2(t).find((option) => option.value === value);

			if (marketOptionValue) {
				return marketOptionValue;
			}
		}

		return { value: '', label: '' };
	};

	const marketSelect = (selection: any) => {
		selection ? onMarketSelect(selection.value) : onMarketSelect(null);
	};

	return (
		<>
			<div data-qa={id}>
				<Label htmlFor={id}>{t('choose_default_market')}:</Label>
				<Select
					options={limitTo1x2 ? [marketOptionsV2(t)[0]] : marketOptionsV2(t)}
					onChange={marketSelect}
					value={propertyValueToOption(value)}
					isClearable={value && value.length > 0}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					placeholder={t('select')}
					id={id}
				/>
			</div>
		</>
	);
};

export default MarketSelect;

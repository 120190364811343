import axios from 'axios';
import { multiLingualService } from '../../../../../../../App';
import { store } from '../../../../../../../store/store';
import REACT_APP_URLS from '../../../../../../../global-helpers/global-url.helpers';

const SPORTS_SEARCH_API_URL = REACT_APP_URLS.REACT_APP_SEARCH_API_BASE_URL;

export default class SportsConnectionsHttpService {
	static instance = (acceptLang?: any) => {
		return axios.create({
			baseURL: SPORTS_SEARCH_API_URL,
			timeout: 30000,
			headers: {
				'Accept-Language': acceptLang
					? acceptLang
					: `${
							multiLingualService.checkIfProjectIsMultiLingual(store.getState().project.currentProject.languages) &&
							multiLingualService.checkRoutePath()
								? multiLingualService.setCorrectAcceptLanguageCode(
										store.getState().project.currentProject.languages.defaultLanguageCode.languageCode,
								  )
								: store.getState().project.currentProject.language
								? store.getState().project.currentProject.language.split('-')[0]
								: 'en'
					  }`,
			},
		});
	};

	static getSports = () => {
		return SportsConnectionsHttpService.instance().get('sports');
	};

	static getSportsData = (input: string, sportsType: string, entityTypes: string[], acceptLanguage?: string) => {
		return SportsConnectionsHttpService.instance(acceptLanguage).get(
			`suggest?name=${input}&sport=${sportsType}&entity_type=${entityTypes.join(',')}&translation_language=${acceptLanguage}`,
		);
	};

	static getCompetitionData = (input: string, sportsType: string, acceptLanguage?: string) => {
		return SportsConnectionsHttpService.instance(acceptLanguage).get(
			`suggest?name=${input}&sport=${sportsType}&entity_type=competition&translation_language=${acceptLanguage}`,
		);
	};

	static getGamesData = (url: string, acceptLanguage?: string) => {
		return SportsConnectionsHttpService.instance(acceptLanguage).get(url);
	};

	static requestMultipleSportsRequests = (urls: string[]) => {
		let requests: any[] = [];

		urls.forEach((url: string) => {
			const request = SportsConnectionsHttpService.instance().get(url);
			requests.push(request);
		});

		return axios.all(requests);
	};
}

import { ContentTypes, WidgetTypes, WidgetTypesV2 } from '../../../../../constants/block.types';
import { customBlocksService, featuresService } from '../../../../../../../../App';
import { FeatureTypes } from '../../../../../../../../services/feature-service/features.enum';

export const checkBlockIsSetAndEnabled = (name: string) => {
	if (name === ContentTypes.dugoutVideo && !featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.DUGOUT_INTEGRATION)) {
		return false;
	}

	if (name === ContentTypes.onnetworkVideo && !featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.ON_NETWORK_INTEGRATION)) {
		return false;
	}

	if (
		name === ContentTypes.audioBlock &&
		!featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.SPOTLIGHT_AUDIO_INTEGRATION) &&
		!featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.GB_VISION_INTEGRATION)
	) {
		return false;
	}

	if (name === ContentTypes.imago && !featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION)) {
		return false;
	}

	if (name === ContentTypes.youtubeSports && !featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.YOUTUBE_SPORTS)) {
		return false;
	}

	if (name === ContentTypes.customBlock && !customBlocksService.isFeatureEnabled(featuresService)) {
		return false;
	}

	return true;
};

export const displayBlockCategory = (block: any) => {
	return (
		(block && block.feature_id === FeatureTypes.FANS_UNITED) ||
		(block && block.feature_id === FeatureTypes.BASKETBALL) ||
		(block && block.feature_id === FeatureTypes.TENNIS) ||
		(block && block.feature_id === FeatureTypes.FOOTBALL_V2) ||
		(block && block.feature_id === FeatureTypes.CUSTOM_BLOCKS)
	);
};

export const displayBlockPreview = (block: any) => {
	return block.default_data.type === WidgetTypesV2.widget || block.default_data.type === WidgetTypes.widget;
};

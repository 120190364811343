export function allowDataResourceType(editor) {
	// Allow the "linkData" attribute in the editor model.
	editor.model.schema.extend('$text', { allowAttributes: 'dataResourceType' });

	// Tell the editor that the model "linkData" attribute converts into <a data-auto-tagged></a>
	editor.conversion.for('downcast').attributeToElement({
		model: 'dataResourceType',
		view: (attributeValue, writer) => {
			const linkElement = writer.createAttributeElement('a', { 'data-resource-type': attributeValue }, { priority: 5 });
			writer.setCustomProperty('dataRType', '', linkElement);

			return linkElement;
		},
		converterPriority: 'low',
	});

	// Tell the editor that <a data-auto-tagged="..."></a> converts into the "linkData" attribute in the model.
	editor.conversion.for('upcast').attributeToAttribute({
		view: {
			name: 'a',
			key: 'data-resource-type',
		},
		model: 'dataResourceType',
		converterPriority: 'low',
	});
}

export function allowDataResourceId(editor) {
	// Allow the "linkData" attribute in the editor model.
	editor.model.schema.extend('$text', { allowAttributes: 'dataResourceId' });

	// Tell the editor that the model "linkData" attribute converts into <a data-auto-tagged></a>
	editor.conversion.for('downcast').attributeToElement({
		model: 'dataResourceId',
		view: (attributeValue, writer) => {
			const linkElement = writer.createAttributeElement('a', { 'data-resource-id': attributeValue }, { priority: 5 });
			writer.setCustomProperty('dataRId', '', linkElement);

			return linkElement;
		},
		converterPriority: 'low',
	});

	// Tell the editor that <a data-auto-tagged="..."></a> converts into the "linkData" attribute in the model.
	editor.conversion.for('upcast').attributeToAttribute({
		view: {
			name: 'a',
			key: 'data-resource-id',
		},
		model: 'dataResourceId',
		converterPriority: 'low',
	});
}

export function allowDataSource(editor) {
	// Allow the "linkData" attribute in the editor model.
	editor.model.schema.extend('$text', { allowAttributes: 'dataSource' });

	// Tell the editor that the model "linkData" attribute converts into <a data-auto-tagged></a>
	editor.conversion.for('downcast').attributeToElement({
		model: 'dataSource',
		view: (attributeValue, writer) => {
			const linkElement = writer.createAttributeElement('a', { 'data-source': attributeValue }, { priority: 5 });
			writer.setCustomProperty('dataS', '', linkElement);

			return linkElement;
		},
		converterPriority: 'low',
	});

	// Tell the editor that <a data-auto-tagged="..."></a> converts into the "linkData" attribute in the model.
	editor.conversion.for('upcast').attributeToAttribute({
		view: {
			name: 'a',
			key: 'data-source',
		},
		model: 'dataSource',
		converterPriority: 'low',
	});
}

import React from 'react';
import { connect } from 'react-redux';
import ImageSelect from './subcomponents/image-select.component';
import ImageUpload from './subcomponents/image-upload.component';
import { Properties, State } from './properties/image-block-edit.properties';
import ImageBlockModel from './models/image-block.model';
import { blockWrapperService, blockManagementService } from '../../../subcomponents/blocky.component';
import { Col, FormGroup, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import BlockValidation from '../../../helpers/block-validation.helper';
import ErrorHandler from '../../../partials/error/error-handler-component';
import { toast } from 'react-toastify';

class ImageEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			activeTab: new Array(4).fill('1'),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { imageBlock: this.props.imageBlock };
		const isValid = validation.validate(this.props.block, data);

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = this.props.imageBlock.image.id;
			block.data.description = this.props.imageBlock.description;
			block.data.alt = this.props.imageBlock.alt;
			block.data.caption = this.props.imageBlock.caption;
			block.data.imageRatio = this.props.imageBlock.imageRatio;
			block.data.preview = { imageBlock: this.props.imageBlock };
			blockManagementService.blockUpdate(block, index);
		} else {
			if (this.props.imageBlock.description && this.props.imageBlock.description.length > 255) {
				toast.error(this.props.t('description_limit_exceed'));
			}
			if (this.props.imageBlock.alt && this.props.imageBlock.alt.length > 255) {
				toast.error(this.props.t('alt_limit_exceed'));
			}

			if (this.props.imageBlock.caption && this.props.imageBlock.caption.length > 255) {
				toast.error(this.props.t('caption_limit_exceed'));
			}

			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	toggle(tabPane: any, tab: string) {
		const newArray = this.state.activeTab.slice();
		newArray[tabPane] = tab;
		this.setState({ ...this.state, activeTab: newArray });
	}

	updateImageBlockState = (imageBlock: ImageBlockModel) => this.props.updateImageBlock(imageBlock);

	render() {
		return (
			<>
				<Row className='mb-2' id={`match-tagging-${this.props.block.id}`}>
					<Col>
						<Nav tabs>
							<NavItem>
								<NavLink id='image-block-select-tab' active={this.state.activeTab[0] === '1'} onClick={() => this.toggle(0, '1')}>
									<i className='fa fa-search'></i> {this.props.t('select')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink id='image-block-upload-tab' active={this.state.activeTab[0] === '2'} onClick={() => this.toggle(0, '2')}>
									<i className='fa fa-upload'></i> {this.props.t('upload')}
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={this.state.activeTab[0]}>
							<TabPane tabId='1' className='p-3 pb-0 bg-white'>
								<FormGroup className='mt-1'>
									{this.state.activeTab[0] === '1' && (
										<ImageSelect
											blockId={this.props.block.id}
											t={this.props.t}
											blockData={this.props.imageBlock}
											onChange={this.updateImageBlockState}
											domain={this.props.domain}
											authors={this.props.authors}
											owners={this.props.owners}
										/>
									)}
								</FormGroup>
							</TabPane>
							<TabPane tabId='2' className='p-3 pb-0 bg-white'>
								<FormGroup className='mt-1'>
									<ImageUpload
										contentData={this.props.contentData}
										blockId={this.props.block.id}
										t={this.props.t}
										blockData={this.props.imageBlock}
										onChange={this.updateImageBlockState}
										authors={this.props.authors}
										owners={this.props.owners}
									/>
								</FormGroup>
							</TabPane>
						</TabContent>
					</Col>
				</Row>
				<Row className='m-2'>
					{!this.state.isValid && this.props.imageBlock.image.id.length <= 0 && (
						<ErrorHandler t={this.props.t} errorMessage='please_select_image' />
					)}
				</Row>
			</>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		authors: state.author.authors,
		owners: state.imageOwners.owners,
	};
}

export default connect(mapStateToProps, null)(ImageEdit) as React.ComponentType<any>;

import React from 'react';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import ListingComponent from '../../Partials/BaseComponents/ListingComponent/ListingComponent';
import { Properties, State } from './Properties/CategoryListingProperties';
import {
	CATEGORY_ENTITY_DELETE_SUCCESS,
	returnObjectForCategoryEntityDelete,
	returnObjectForCategoryListingRequest,
	returnObjectForCategorySearch,
} from '../../../store/action-creators/CategoriesActionCreators';
import { ContentTypes } from '../../../constants/content-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import ConditionalRenderWrapper from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../store/action-creators/ImageActionCreator';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { returnObjectForWikiPaginationUpdate } from '../../../store/action-creators/WikiActionCreator';

class Categories extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			categorySearchInput: '',
			currentPage: '1',
		};
	}

	getCategoriesListener = () => this.props.getCategories(this.state.currentPage, this.props.currentProject, this.props.adminId);

	setCurrentPage(page: string) {
		this.props.analytics.sendPageView(this.props.location.pathname, `${parseInt(page) > 1 ? 'Categories - Page ' + page : 'Categories'}`);
		this.setState({
			...this.state,
			currentPage: page,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'Categories');
		this.props.getCategories(this.state.currentPage, this.props.currentProject, this.props.adminId);
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateWikiPagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {});
		this.props.updateContentFilters(filters);
	}

	componentWillUnmount() {
		window.removeEventListener(CATEGORY_ENTITY_DELETE_SUCCESS, this.getCategoriesListener);
	}

	initPageTitle() {
		document.title = this.props.t('categories');
	}

	render() {
		return (
			<ConditionalRenderWrapper expectedPermissions={['read_categories', 'write_categories', 'delete_categories']}>
				<ListingComponent
					contentType={ContentTypes.CATEGORY}
					getContent={this.props.getCategories}
					analytics={this.props.analytics}
					searchContent={this.props.searchCategories}
					currentProject={this.props.currentProject}
					onPageChange={this.setCurrentPage.bind(this)}
					showSearchBar={false}
					hidePagination={true}
					history={this.props.history}
					onDeleteContent={(id: string) => {
						this.props.deleteCategory(id, this.props.currentProject);
					}}
					content={this.props.categories}
					contentPagination={this.props.pagination}
				/>
			</ConditionalRenderWrapper>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		adminId: state.profile.profile.id,
		categories: state.category.categories,
		categoryDeleted: state.category.categoryDeleted,
		currentProject: state.project.currentProject,
		pagination: state.category.pagination,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getCategories: (page: string, project: Project, adminId: string) => {
			dispatch(returnObjectForCategoryListingRequest(page, project, adminId));
		},
		searchCategories: (text: string, project: Project) => dispatch(returnObjectForCategorySearch(text, project)),
		deleteCategory: (id: string, project: Project) => dispatch(returnObjectForCategoryEntityDelete(id, project)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateWikiPagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForWikiPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Categories) as React.ComponentType;

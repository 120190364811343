import {
	SET_BLOCKS_TO_COPY,
	BLOCKS_ARE_COPIED,
	INITILIZE_AUTOLINKING,
	RESET_AUTOLINKING,
	UPDATE_UNDO_REDO_BLOCKY,
	UNDO_BLOCKY,
	REDO_BLOCKY,
	RESET_UNDO_REDO_BLOCKY,
	INITIALIZE_UNDO_REDO_BLOCKY,
	UPDATE_PRESENT_UNDO_REDO_BLOCKY,
} from '../action-creators/BlockyActionCreator';
import BlockModel from '../../views/Partials/Blocky/models/block.model';
import UndoRedoBlockyModel from '../../views/Partials/Blocky/models/UndoRedoBlockyMode';
import { getLastElementsFromArray } from '../../global-helpers/global.helpers';

interface InitialState {
	blocksToCopy: BlockModel[];
	areBlocksCopied: boolean;
	blockPlaceholder: string[];
	blocksUndoRedo: UndoRedoBlockyModel;
}

const initialState: InitialState = {
	blocksToCopy: [],
	areBlocksCopied: false,
	blockPlaceholder: [],
	blocksUndoRedo: new UndoRedoBlockyModel(),
};

function blockyReducer(state: any = initialState, action: any) {
	if (action.type === INITIALIZE_UNDO_REDO_BLOCKY) {
		const newPresent = action.payload;
		return Object.assign({}, state, {
			blocksUndoRedo: {
				past: [],
				present: newPresent,
				future: [],
			},
		});
	}

	if (action.type === UPDATE_PRESENT_UNDO_REDO_BLOCKY) {
		const newPresent = action.payload;
		return Object.assign({}, state, {
			blocksUndoRedo: {
				...state.blocksUndoRedo,
				present: newPresent,
			},
		});
	}

	if (action.type === UPDATE_UNDO_REDO_BLOCKY) {
		const { newBlockArray, oldBlockArray } = action.payload;
		if (newBlockArray.length > 0 && oldBlockArray.length > 0) {
			// blocky cannot be empty, it should have at least 1 item (ex. paragraph)
			const { past } = state.blocksUndoRedo;
			const { maximumRedoUndoStates } = action;
			const newPast = getLastElementsFromArray([...past, [...oldBlockArray]], maximumRedoUndoStates);
			return Object.assign({}, state, {
				blocksUndoRedo: {
					past: newPast,
					present: newBlockArray,
					future: [],
				},
			});
		}
	}

	if (action.type === RESET_UNDO_REDO_BLOCKY) {
		return Object.assign({}, state, {
			blocksUndoRedo: new UndoRedoBlockyModel(),
		});
	}

	if (action.type === UNDO_BLOCKY) {
		const { maximumRedoUndoStates } = action;
		const { past, present, future } = state.blocksUndoRedo;
		const previous = past[past.length - 1];
		const pastWithoutTheNewPresent = past.slice(0, past.length - 1); // will get all elements except the last one
		const newPast = getLastElementsFromArray(pastWithoutTheNewPresent, maximumRedoUndoStates);
		return Object.assign({}, state, {
			blocksUndoRedo: {
				past: newPast,
				present: previous,
				future: [present, ...future],
			},
		});
	}

	if (action.type === REDO_BLOCKY) {
		const { maximumRedoUndoStates } = action;
		const { past, present, future } = state.blocksUndoRedo;
		const next = future[0];
		const futureWithoutTheNewPresent = future.slice(1); // will get all elements except the first one
		const newFuture = getLastElementsFromArray(futureWithoutTheNewPresent, maximumRedoUndoStates);
		return Object.assign({}, state, {
			blocksUndoRedo: {
				past: [...past, present],
				present: next,
				future: newFuture,
			},
		});
	}

	if (action.type === SET_BLOCKS_TO_COPY) {
		return Object.assign({}, state, {
			blocksToCopy: action.payload,
		});
	}

	if (action.type === BLOCKS_ARE_COPIED) {
		return Object.assign({}, state, {
			areBlocksCopied: action.payload,
		});
	}

	if (action.type === INITILIZE_AUTOLINKING) {
		return Object.assign({}, state, {
			blockPlaceholder: [...state.blockPlaceholder, action.payload],
		});
	}

	if (action.type === RESET_AUTOLINKING) {
		return Object.assign({}, state, {
			blockPlaceholder: [],
		});
	}

	return state;
}

export default blockyReducer;

import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import MainImage from './subcomponents/main-media/main-image';
import ImageListing from './subcomponents/toggle-images/image-listing/image-listing';
import ImagoImageListing from './subcomponents/toggle-images/imago-image-listing/imago-image-listing';
import UploadMainImage from './subcomponents/buttons/upload-image';
import EditMainImage from './subcomponents/buttons/edit-image';
import CropMainImage from './subcomponents/buttons/crop-image';
import ExternalMainMediaContainer from './subcomponents/external-main-media/container';
import MainImageErrors from './subcomponents/errors/errors';

type Properties = {
	t: any;
	areMandatoryFieldsFilled?: boolean;
	updateMandatoryFieldsFilled: (value: boolean) => void;
};

const SidebarMainMedia: FunctionComponent<Properties> = ({ t, areMandatoryFieldsFilled, updateMandatoryFieldsFilled }) => {
	return (
		<>
			<MainImage t={t} />
			<UploadMainImage t={t} />
			<EditMainImage t={t} />
			<CropMainImage t={t} />
			<MainImageErrors t={t} areMandatoryFieldsFilled={areMandatoryFieldsFilled} updateMandatoryFieldsFilled={updateMandatoryFieldsFilled} />
			<ImageListing t={t} />
			<ImagoImageListing t={t} />
			<Row>
				<Col className='text-center my-2'>
					<hr />
				</Col>
			</Row>
			<ExternalMainMediaContainer t={t} />
		</>
	);
};

export default SidebarMainMedia;

import React from 'react';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import ListingComponent from '../../Partials/BaseComponents/ListingComponent/ListingComponent';
import { Properties, State } from './Properties/TagListingProperties';
import {
	returnObjectForTagEntityDelete,
	returnObjectForTagListingRequest,
	returnObjectForTagSearch,
} from '../../../store/action-creators/TagActionCreators';
import { ContentTypes } from '../../../constants/content-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import ConditionalRenderWrapper from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { Subscription } from 'rxjs';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../store/action-creators/ImageActionCreator';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import { returnObjectForWikiPaginationUpdate } from '../../../store/action-creators/WikiActionCreator';

class TagListing extends React.Component<Properties, State> {
	tagsSubscription: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			tagSearchInput: '',
			toggleModal: false,
			currentPage: '1',
		};
	}

	getTagsListener = () => {
		this.props.getTags(this.state.currentPage, this.props.currentProject);
	};

	setCurrentPage(page: string) {
		this.props.analytics.sendPageView(this.props.location.pathname, `${parseInt(page) > 1 ? 'TagListing - Page ' + page : 'TagListing'}`);
		this.setState({
			...this.state,
			currentPage: page,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'TagListing');
		this.props.getTags(this.state.currentPage, this.props.currentProject);
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateWikiPagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {});
		this.props.updateContentFilters(filters);
	}

	// componentWillUnmount() {
	// 	window.removeEventListener(TAG_ENTITY_DELETE_SUCCESS, this.getTagsListener);
	// }

	initPageTitle() {
		document.title = this.props.t('tags');
	}

	render() {
		return (
			<ConditionalRenderWrapper expectedPermissions={['read_tags', 'write_tags', 'delete_tags']}>
				<ListingComponent
					contentType={ContentTypes.TAG}
					getContent={this.props.getTags}
					analytics={this.props.analytics}
					searchContent={this.props.searchTags}
					currentProject={this.props.currentProject}
					onPageChange={this.setCurrentPage.bind(this)}
					history={this.props.history}
					onDeleteContent={(id: string) => {
						this.props.deleteTag(id, this.props.currentProject);
					}}
					hidePagination={true}
					content={this.props.tags}
					contentPagination={this.props.pagination}
				/>
			</ConditionalRenderWrapper>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		tags: state.tag.tags,
		tagDeleted: state.tag.tagDeleted,
		currentProject: state.project.currentProject,
		pagination: state.tag.pagination,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getTags: (page: string, project: Project, text: string) => {
			dispatch(returnObjectForTagListingRequest(page, project, text));
		},
		searchTags: (text: string, project: Project) => dispatch(returnObjectForTagSearch(text, project)),
		deleteTag: (id: string, project: Project) => dispatch(returnObjectForTagEntityDelete(id, project)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateWikiPagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForWikiPaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(TagListing) as React.ComponentType;

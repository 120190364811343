import React from 'react';
import { Properties } from './properties/image-block-view.properties';
import * as helpers from './helpers/image-block-view.helper';
import Image from '../../../../../../models/image/Image';

export const ImageView: React.FunctionComponent<Properties> = ({ preview, isLoading, t }) => {
	if (preview.image && preview.image.id) {
		return (
			<div className={`${isLoading ? 'loading-overlay' : ''}`}>
				<div className='block-content d-flex content-container'>
					<img
						alt={preview.image.description}
						className='content-image img-fluid mb-2 mb-md-0 rounded'
						src={helpers.getMainImageUrl(preview.image)}
					/>
					<div className='gallery-title text-left w-100'>
						{preview.description && <h6 className='m-0 p-0'>{preview.description}</h6>}
						<div className='mt-3'>
							<span>{t('image_width')}:</span>
							<strong className='ml-2'>{preview.width}</strong>
						</div>
						<div className='mt-1'>
							<span>{t('image_alignment')}:</span>
							<strong className='ml-2'>{t(preview.alignment)}</strong>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='block-content d-flex content-container'>
			<img alt='' className='content-image img-fluid mb-2 mb-md-0 rounded' src={helpers.getMainImageUrl(Image.builder().build())} />
		</div>
	);
};

import { OptionTypeData } from '../../../../../constants/select';
import { capitalize } from '../../../../../global-helpers/global.helpers';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';

export const sportTypeToOption = (sport: SportsTypesModel): OptionTypeData => {
	if (sport) {
		return {
			label: capitalize(sport.title),
			value: sport.sport,
			data: sport,
		};
	}

	return {} as OptionTypeData;
};

export const availableSportsToOption = (availableSports: SportsTypesModel[]): OptionTypeData[] => {
	return availableSports.map((availableSport) => {
		return {
			label: capitalize(availableSport.title),
			value: availableSport.sport,
			data: availableSport,
		};
	});
};

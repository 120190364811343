import React, { Component } from 'react';
import ButtonDropdown from 'reactstrap/es/ButtonDropdown';
import DropdownToggle from 'reactstrap/es/DropdownToggle';
import DropdownMenu from 'reactstrap/es/DropdownMenu';
import DropdownItem from 'reactstrap/es/DropdownItem';
import { Button, Badge, UncontrolledDropdown } from 'reactstrap';
import { getEntityMedia, remapAdditionalTags } from './helpers/drop-down-entity-tag.component.helpers';

export default class DropdownEntityTag extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};
	}

	toggle = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	};

	insertTag = (tag, firstOnly) => {
		tag['firstOnly'] = firstOnly;
		this.props.onTagInsert(tag);
	};

	render() {
		const { tag, t } = this.props;
		const { isOpen } = this.state;

		return (
			<ButtonDropdown size='m' isOpen={isOpen} toggle={this.toggle} className={'mr-3 margin-b-1'}>
				<Button
					outline
					id='caret'
					color='secondary'
					onClick={() => {
						this.insertTag(tag, true);
					}}
				>
					{tag ? tag.text : ''}
				</Button>
				{tag && tag.more && tag.more > 1 && (
					<>
						<UncontrolledDropdown setActiveFromChild>
							<DropdownToggle caret color='primary' className='more-occurrence-dropdown'>
								{'+' + tag.more + ' more '}
							</DropdownToggle>
							<DropdownMenu>
								{tag.moreTags &&
									tag.moreTags.length > 1 &&
									tag.moreTags.map((tagAdd, index) => {
										return (
											<DropdownItem
												className='additional-tags-dropdown-item'
												key={index}
												onClick={() => {
													this.insertTag(remapAdditionalTags(tagAdd, tag), false);
												}}
											>
												<>
													<img className={'option-logo' + '-' + tagAdd.subtype} src={getEntityMedia(tagAdd)} /> {tagAdd.entity.name}
												</>
											</DropdownItem>
										);
									})}
							</DropdownMenu>
						</UncontrolledDropdown>
					</>
				)}
				{tag && tag.more && tag.more === 1 && (
					<>
						<DropdownToggle className='insert-occurrence-dropdown' outline caret color='secondary' />
						<DropdownMenu>
							<DropdownItem
								key='dropdown-item-all-occurrences'
								onClick={() => {
									this.insertTag(tag, false);
								}}
							>
								{t('insert_all_occurrences')}
							</DropdownItem>
							<DropdownItem
								key='dropdown-item-first-occurrence'
								onClick={() => {
									this.insertTag(tag, true);
								}}
							>
								{t('insert_first_occurrence')}
							</DropdownItem>
						</DropdownMenu>
					</>
				)}
			</ButtonDropdown>
		);
	}
}

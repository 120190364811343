import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import TennisTournamentModel from '../../../../../../models/v2/tennis-tournament/tennis-tournament.model';
import ErrorHandler from '../../../partials/error/error-handler-component';
import { customOption } from '../../../partials/shared/custom-select-option';
import { tournamentsToOptions, tournamentToOption } from './tennis-tournament-select.helper';
import TennisRoundModel from '../../../../../../models/v2/round/tennis-round/tennis-round.model';
import { responseToModelTennisRound } from '../../../../../../models/v2/round/tennis-round/tennis-round-mapper';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';

type Properties = {
	onTournamentSelect: (selection: TennisTournamentModel, availableRounds: TennisRoundModel[]) => any;
	selectedTournament: TennisTournamentModel;
	t: any;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	language: string;
	sport: string;
	id: string;
	tournaments: TennisTournamentModel[];
};

export const TennisTournamentSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [tournamentOptions, setTournamentOptions] = useState<TennisTournamentModel[]>([]);
	const { tournaments } = props;

	useEffect(() => {
		updateTournamentsOptionsState(tournaments);
	}, [tournaments]);

	const updateTournamentsOptionsState = (tournaments: TennisTournamentModel[]) => {
		setTournamentOptions(tournaments);
	};

	const isSelected = props.selectedTournament.id && props.selectedTournament.id.length > 0;

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{props.t('tournament')}</Label>
				{props.isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={props.t('select')}
					formatOptionLabel={customOption}
					options={tournamentsToOptions(tournamentOptions)}
					value={props.selectedTournament && props.selectedTournament.id ? tournamentToOption(props.selectedTournament) : {}}
					noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
					isClearable={props.isClearable && isSelected}
					onChange={(selection: SelectMenuOptionType) => {
						if (selection && selection.data !== null) {
							const rounds = selection.data.rounds.map((item: TennisRoundModel) => responseToModelTennisRound(item));
							props.onTournamentSelect(selection.data, rounds);
						} else {
							props.onTournamentSelect({} as TennisTournamentModel, []);
						}
					}}
				/>
				{!props.isValid && !props.selectedTournament && <ErrorHandler t={props.t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

import ImageBlockBuilder from './image-block.builder';
import { ImageBlockJson } from './image-block.json';
import Image from '../../../../../../../models/image/Image';
import Author from '../../../../../../../models/author/Author';

export default class ImageBlockModel {
	readonly image: Image;
	readonly width: string;
	readonly alignment: string;
	readonly alt: string;
	readonly caption: string;
	readonly description: any;
	readonly authors: Author[];
	readonly imageRatio: any;
	readonly addDescriptionToMedia: boolean;
	readonly addWatermarkChecked: boolean;
	readonly removeWatermarkChecked: boolean;

	private constructor(
		image: Image,
		width: string,
		alignment: string,
		alt: string,
		caption: string,
		description: any,
		authors: Author[],
		imageRation: any,
		addDescriptionToMedia: boolean = false,
		addWatermarkChecked: boolean = false,
		removeWatermarkChecked: boolean = false,
	) {
		this.image = image;
		this.width = width;
		this.alt = alt;
		this.caption = caption;
		this.alignment = alignment;
		this.description = description;
		this.authors = authors;
		this.imageRatio = imageRation;
		this.addDescriptionToMedia = addDescriptionToMedia;
		this.addWatermarkChecked = addWatermarkChecked;
		this.removeWatermarkChecked = removeWatermarkChecked;
	}

	toJson(): ImageBlockJson {
		return {
			image: this.image,
			width: this.width,
			alignment: this.alignment,
			alt: this.alt,
			caption: this.caption,
			description: this.description,
			authors: this.authors,
			imageRatio: this.imageRatio,
			addDescriptionToMedia: this.addDescriptionToMedia,
			addWatermarkChecked: this.addWatermarkChecked,
			removeWatermarkChecked: this.removeWatermarkChecked,
		};
	}

	static fromJson(json: ImageBlockJson): ImageBlockModel {
		return new ImageBlockModel(
			json.image,
			json.width,
			json.alignment,
			json.alt,
			json.caption,
			json.description,
			json.authors,
			json.imageRatio,
			json.addDescriptionToMedia,
			json.addWatermarkChecked,
			json.removeWatermarkChecked,
		);
	}
	static builder(imageBlock?: ImageBlockJson): ImageBlockBuilder {
		return new ImageBlockBuilder(imageBlock);
	}
}

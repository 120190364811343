import GalleryBlockModel from '../models/gallery-block.model';
import { extractMainImageSrc } from '../../content-block/helpers/main-image.helper';

export const remapResponceToGallery = (response: any) => {
	if (response && Object.entries(response).length > 0) {
		return GalleryBlockModel.builder()
			.withId(response.id)
			.withTitle(response.title)
			.withSubtitile(response.subtitle)
			.withPublishedAt(response.published_at)
			.withItems(response.items)
			.withStatus(response.status)
			.withCategory(response.category)
			.withAdditionalCategories(response.additional_categories)
			.withImage(extractMainImageSrc(response.main_media))
			.withMainImage(response.image && response.image.data)
			.withLanguage(response.language)
			.build();
	}

	return GalleryBlockModel.builder().build();
};

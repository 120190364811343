import React from 'react';
import ImageUploadContainer from '../../../../partials/image-upload/image-upload-container';

type Properties = {
	onCopySportsRelated: any;
	sportsRelatedContent: any;
	onImagesUploaded: any;
	copySportsRelated: any;
	t: any;
	blockId?: string;
};

export default class GalleryItems extends React.Component<Properties> {
	render() {
		const { onCopySportsRelated, sportsRelatedContent, onImagesUploaded, copySportsRelated, t, blockId } = this.props;

		return (
			<ImageUploadContainer
				t={t}
				display
				blockId={blockId}
				imageUploadButtonId={`blocky-gallery-items-upload-button-${blockId}`}
				copySportsRelated={copySportsRelated}
				sportsRelatedContent={sportsRelatedContent}
				onCopySportsRelated={(checked: boolean) => {
					onCopySportsRelated(checked);
				}}
				onMultiImageUploadSuccess={(data: any) => {
					onImagesUploaded(data);
				}}
				isSingleUpload={false}
				onImageUploadSuccess={() => undefined}
			/>
		);
	}
}

import * as React from 'react';
import { useContext } from 'react';
import { ButtonGroup, Col } from 'reactstrap';
import './ImageCells.scss';
import Image from '../../../../../models/image/Image';
import { ConditionalRenderContext } from '../../../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import ImageEditAndCropButtons from '../../image-buttons.component';
import Masonry from 'react-masonry-css';
import './masonry.scss';

type ImageCellsProps = {
	images: Image[];
	onEdit: (imageId: string) => any;
	onCrop: (imageId: string) => any;
	onImageClick: (image: Image) => any;
	analytics?: any;
	t: any;
};

export const ImageCells: React.FunctionComponent<ImageCellsProps> = ({ images, onImageClick, onCrop, onEdit }) => {
	if (images === undefined) {
		return null;
	}

	return (
		<Masonry
			breakpointCols={{
				default: 5,
				1380: 4,
				1200: 3,
				660: 2,
			}}
			className='my-masonry-grid'
			columnClassName='my-masonry-grid_column'
		>
			{images.map((image: Image, index: number) => {
				return (
					<div className={'image-container'} key={`image-cell-${image.id}`}>
						<div
							className={'expand-item'}
							onClick={() => {
								onImageClick(image);
							}}
						>
							<img
								className={'img-fluid w-100'}
								alt=''
								src={image.urls && image.urls.uploaded && image.urls.uploaded.embed ? image.urls.uploaded.embed : ''}
							/>
						</div>
						<div className={'image-footer'}>
							{image.description && image.description.length > 0 && (
								<div className={'image-description text-truncate px-2 py-1'}>{image.description}</div>
							)}
							<ButtonGroup className={'actions-wrapper position-absolute display-block w-100'}>
								<ImageEditAndCropButtons imageId={image.id} onEdit={onEdit} onCrop={onCrop} />
							</ButtonGroup>
						</div>
					</div>
				);
			})}
		</Masonry>
	);
};

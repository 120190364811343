import { EditorTypes } from './../../../constants/block.types';

export default class EditorTransformService {
	static H1 = 'h1';
	static H2 = 'h2';
	static H3 = 'h3';
	static H4 = 'h4';

	headings = {
		h1: (value) => {
			return `<h1>${value}</h1>`;
		},
		h2: (value) => {
			return `<h2>${value}</h2>`;
		},
		h3: (value) => {
			return `<h3>${value}</h3>`;
		},
		h4: (value) => {
			return `<h4>${value}</h4>`;
		},
	};

	transformations = {
		pToH: (transformModel) => {
			return this.transformToHeading(transformModel, transformModel.headingType);
		},
		pToQ: (transformModel) => {
			return this.transformParagraphToQuote(transformModel);
		},
		qToH: (transformModel) => {
			return this.transformToHeading(transformModel, transformModel.headingType);
		},
		qToP: (transformModel) => {
			return this.transformToParagraph(transformModel);
		},
		hToP: (transformModel) => {
			return this.transformToParagraph(transformModel);
		},
		hToQ: (transformModel) => {
			return this.transformHeadingToQuote(transformModel);
		},
		default: (transformModel) => {
			return transformModel.data;
		},
	};

	transform(editorType, editorData, buttonLabel) {
		let transformModel = new TransformModel(editorType, editorData, buttonLabel);

		return this.transformations[transformModel.transformType](transformModel);
	}

	transformParagraphToQuote(transformModel) {
		let clearedParagraph = transformModel.data.replace(/(<([^>]+)>)/, '', 1).replace(/(<([^>]+)>)$/, '', '$1');
		return `<blockquote>${clearedParagraph}</blockquote>`;
	}

	transformToParagraph(transformModel) {
		transformModel.data = transformModel.data.replace(/(<([^>]+)>)/, '', 1).replace(/(<([^>]+)>)$/, '', '$1');

		return `<p>${transformModel.data}</p>`;
	}

	transformHeadingToQuote(transformModel) {
		transformModel.data = transformModel.data.replace(/(<([^>]+)>)/, '', 1).replace(/(<([^>]+)>)$/, '', '$1');

		return `<blockquote>${transformModel.data}</blockquote>`;
	}

	transformToHeading(transformModel, headingType) {
		let clearedParagraph = transformModel.data.replace(/(<([^>]+)>)/, '', 1).replace(/(<([^>]+)>)$/, '', '$1');

		return this.headings[headingType](clearedParagraph);
	}
}

export class TransformModel {
	type;
	id;
	data;
	label;
	headingType;

	P_TO_H = 'pToH';
	P_TO_Q = 'pToQ';

	H_TO_P = 'hToP';
	H_TO_Q = 'hToQ';

	Q_TO_H = 'qToH';
	Q_TO_P = 'qToP';
	DEFAULT = 'default';

	constructor(type, data, label) {
		this.type = type;
		this.data = data;
		this.label = label;
	}

	get transformType() {
		if (this.type === EditorTypes.paragraph && this.label === 'Heading 1') {
			this.headingType = EditorTransformService.H1;

			return this.P_TO_H;
		}

		if (this.type === EditorTypes.paragraph && this.label === 'Heading 2') {
			this.headingType = EditorTransformService.H2;

			return this.P_TO_H;
		}

		if (this.type === EditorTypes.paragraph && this.label === 'Heading 3') {
			this.headingType = EditorTransformService.H3;

			return this.P_TO_H;
		}

		if (this.type === EditorTypes.paragraph && this.label === 'Heading 4') {
			this.headingType = EditorTransformService.H4;

			return this.P_TO_H;
		}

		if (this.type === EditorTypes.paragraph && this.label === 'Quote') {
			return this.P_TO_Q;
		}

		if (this.type === EditorTypes.quote && this.label === 'Heading 1') {
			this.headingType = EditorTransformService.H1;

			return this.Q_TO_H;
		}

		if (this.type === EditorTypes.quote && this.label === 'Heading 2') {
			this.headingType = EditorTransformService.H2;

			return this.Q_TO_H;
		}

		if (this.type === EditorTypes.quote && this.label === 'Heading 3') {
			this.headingType = EditorTransformService.H3;

			return this.Q_TO_H;
		}

		if (this.type === EditorTypes.quote && this.label === 'Heading 4') {
			this.headingType = EditorTransformService.H4;

			return this.Q_TO_H;
		}

		if (this.type === EditorTypes.quote && this.label === 'Paragraph') {
			return this.Q_TO_P;
		}

		if (this.type === EditorTypes.heading && this.label === 'Paragraph') {
			return this.H_TO_P;
		}

		if (this.type === EditorTypes.heading && this.label === 'Quote') {
			return this.H_TO_Q;
		}

		if (this.type === EditorTypes.quote && this.label === 'Quote') {
			return this.DEFAULT;
		}

		if (this.type === EditorTypes.paragraph && this.label === 'Paragraph') {
			return this.DEFAULT;
		}

		if (this.type === EditorTypes.heading && this.label === 'Heading 1') {
			return this.DEFAULT;
		}

		if (this.type === EditorTypes.heading && this.label === 'Heading 2') {
			return this.DEFAULT;
		}

		if (this.type === EditorTypes.heading && this.label === 'Heading 3') {
			return this.DEFAULT;
		}

		if (this.type === EditorTypes.heading && this.label === 'Heading 4') {
			return this.DEFAULT;
		}
	}
}

import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { onError } from '../action-creators/GeneralActions';
import {
	IMAGE_SEARCH_REQUEST,
	IMAGES_REQUEST,
	imagesReceived,
	PLAYING_SURFACE_IMAGES_REQUEST,
	playingSurfaceImagesReceived,
	PLAYING_SURFACE_IMAGE_SEARCH_REQUEST,
	IMAGO_IMAGES_REQUEST,
	imagoImagesReceived,
	IMAGO_IMAGE_SEARCH_REQUEST,
	PATCH_IMAGE_DESCRIPTION,
} from '../action-creators/ImageActionCreator';
import { searchIsApplied } from '../action-creators/UiActionCreator';

function* fetchImages(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const constructURL = action.payload.text && action.payload.text.length > 0 ? `/search?query=${action.payload.text}&` : '?';
		const images = yield call(HttpService.get, `/v2/images${constructURL}page=${action.payload.page}`, null, headers);
		yield put(imagesReceived(images));
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchImages(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const images = yield call(HttpService.get, `/v2/images/search?query=${action.payload.text}`, null, headers);
		yield put(imagesReceived(images));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchImagoImages(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const originId = action.payload.origin[0].id;
		const constructURL = action.payload.text && action.payload.text.length > 0 ? `/search?query=${action.payload.text}&` : '/search?query=*&';
		const images = yield call(HttpService.get, `/v2/images${constructURL}originId=${originId}&page=${action.payload.page}`, null, headers);
		yield put(imagoImagesReceived(images));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchImagoImages(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const images = yield call(HttpService.get, `/v2/images/search?query=${action.payload.text}`, null, headers);
		yield put(imagoImagesReceived(images));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchPlayingSurfaceImages(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const originId = action.payload.origin[0].id;
		const images = yield call(HttpService.get, `/v2/images/search?query=*&originId=${originId}`, null, headers);
		yield put(playingSurfaceImagesReceived(images));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchPlayingSurfaceImages(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const images = yield call(HttpService.get, `/v2/images/search?query=${action.payload.text}`, null, headers);
		yield put(playingSurfaceImagesReceived(images));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* patchImageDescription(action: any) {
	try {
		let imageRequestJson = {};
		let headers = { Project: action.payload.project.domain };

		if (action.payload.media[0]) {
			let imageId = action.payload.media[0].resourceId;
			imageRequestJson['description'] = action.payload.media[0].description;
			yield call(HttpService.patch, `/images/${imageId}`, imageRequestJson, headers);
		} else {
			let galleryItemId = action.payload.media.id;
			imageRequestJson['description'] = action.payload.media.description;
			yield call(HttpService.patch, `/images/${galleryItemId}`, imageRequestJson, headers);
		}
	} catch (error) {
		yield put(onError(error));
	}
}

function* imageSage() {
	yield takeEvery(IMAGES_REQUEST, fetchImages);
	yield takeEvery(IMAGE_SEARCH_REQUEST, searchImages);
	yield takeEvery(PLAYING_SURFACE_IMAGES_REQUEST, fetchPlayingSurfaceImages);
	yield takeEvery(PLAYING_SURFACE_IMAGE_SEARCH_REQUEST, searchPlayingSurfaceImages);
	yield takeEvery(IMAGO_IMAGES_REQUEST, fetchImagoImages);
	yield takeEvery(IMAGO_IMAGE_SEARCH_REQUEST, searchImagoImages);
	yield takeEvery(PATCH_IMAGE_DESCRIPTION, patchImageDescription);
}

export default imageSage;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { SportTypes, SportTypesValue } from '../../../../constants/sport.types';
import EventsContainer from './subcomponents/events/events';
import { FootballConnectionsWrapper } from './subcomponents/football-connections/wrapper';
import SportSelect from './subcomponents/sport-select/sport-select';
import TagsSelect from './subcomponents/tags-select/tags-select';
import SportsConnectionsSelect from './subcomponents/sports-connections/sports-connections';
import SuggestedEntities from './subcomponents/suggested-entities/suggested-entities.component';
import { clearSuggestedEntities } from '../../../../store/action-creators/suggested-entities';
import { connect } from 'react-redux';
import './tags.component.scss';
import SportsTypesModel from '../../../../models/v2/sports-types/sports-types.model';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { featuresService } from '../../../../App';

type Properties = {
	t: any;
	clearSuggestedEntities: Function;
	sports: SportsTypesModel[];
};

const SidebarTags: FunctionComponent<Properties> = ({ t, clearSuggestedEntities, sports }) => {
	// Not in external function because unit tests gives error when importing function with featuresService
	const availableSports = sports;
	const [selectedSport, setSelectedSport] = useState<SportsTypesModel>(
		featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL, FeatureTypes.FOOTBALL_CONNECTIONS])
			? availableSports[0]
			: ({} as SportsTypesModel),
	);

	useEffect(() => {
		clearSuggestedEntities();
	}, []);

	return (
		<div>
			<TagsSelect t={t} />
			{selectedSport && (
				<SportSelect t={t} selectedSport={selectedSport} availableSports={availableSports} setSelectedSport={setSelectedSport} />
			)}
			{selectedSport && selectedSport.sport === SportTypes.FOOTBALL && (
				<>
					<FootballConnectionsWrapper t={t} selectedSport={selectedSport} />
					<SuggestedEntities selectedSport={selectedSport} t={t} />
				</>
			)}
			{selectedSport && selectedSport.sport !== SportTypes.FOOTBALL && (
				<SportsConnectionsSelect t={t} selectedSport={selectedSport} allowMoreEntityTypes />
			)}
			{selectedSport && <EventsContainer t={t} selectedSport={selectedSport} />}
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		sports: state.sports.sportsTypes,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		clearSuggestedEntities: () => dispatch(clearSuggestedEntities()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarTags);

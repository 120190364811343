import axios from 'axios';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';

export default class FansUnitedHttpService {
	static instance = (baseUrl: string) => {
		return axios.create({
			baseURL: baseUrl,
			timeout: 30000,
		});
	};

	static getGames = (type: string) => {
		const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
		const disabledCache = `&disable_cache=${Math.floor(Date.now() / 1000)}`;
		if (config && config.configuration && config.configuration.api_key && config.configuration.client_id) {
			return FansUnitedHttpService.instance('https://prediction.fansunitedapi.com/v1').get(
				`/games?key=${config.configuration.api_key}&client_id=${config.configuration.client_id}&type=${type}&status=OPEN,PENDING${disabledCache}`,
			);
		}
	};

	static getLeaderboardTemplates = () => {
		const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
		const disabledCache = `&disable_cache=${Math.floor(Date.now() / 1000)}`;
		if (config && config.configuration && config.configuration.api_key && config.configuration.client_id) {
			return FansUnitedHttpService.instance('https://loyalty.fansunitedapi.com/v1').get(
				`/leaderboard/templates?key=${config.configuration.api_key}&client_id=${config.configuration.client_id}${disabledCache}`,
			);
		}
	};
}

import React from 'react';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import { Properties, State } from './Properties/playing-surface-images.properties';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
	playingSurfaceImageRequest,
	playingSurfaceImageSearchRequest,
	PLAYING_SURFACE_IMAGES_RECEIVED,
	PLAYING_SURFACE_IMAGES_REQUEST,
	returnObjectForPlayingSurfaceFiltersUpdate,
} from '../../../store/action-creators/ImageActionCreator';
import { Button, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { ImageCells } from './Subcomponents/image-cells/ImageCells';
import ImageEditModal from './Subcomponents/image-edit-modal/ImageEditModal';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import ReactPaginate from 'react-paginate';
import { PaginationProps } from '../../Partials/Sidebar/Media/subcomponents/media-main-image/subcomponents/image-listing/properties/ImageListPaginateProps';
import { Title } from '../../Partials/BaseComponents/ListingComponent/subcomponents/ContentTitle/ContentTitle';
import Image from '../../../models/image/Image';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import ConditionalRenderWrapper from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { actionService } from '../../../App';
import LightboxContainer from './Subcomponents/lightbox/LightboxContainer';
import ImageCropContainer from '../../Partials/Shared/image-crop/image-crop-container';
import AdvancedContentFilters from '../../Partials/Shared/advanced-content-filter/advanced-content-filter.component';
import ContentAttributes from '../../../models/content-attributes/content-attributes-model';
import { Subscription } from 'rxjs';
import { ContentTypes } from '../../../constants/content-types';
import AdvancedFilterModel from '../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import { extractSearchQueryFromFilters } from '../../Partials/Shared/advanced-content-filter/helpers/advanced-content-filter.helper';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';

class PlayingSurfaceImageListing extends React.Component<Properties, State> {
	actionServiceSubscription: Subscription = {} as Subscription;

	constructor(props: any) {
		super(props);
		this.state = {
			imageSearchInput: props.imageFilters && props.imageFilters.searchText ? props.imageFilters.searchText : '',
			searchedText: '',
			currentPage: '',
			imageId: '',
			showCropModal: false,
			showEditModal: false,
			enlargeImage: false,
			imageToEnlarge: {} as Image,
			isContentLoading: false,
			advancedContentSearchInput: '',
			pageSearchQuery: '',
		};
		this.registerActionEventListener();
	}

	setCurrentPage(page: string) {
		this.props.analytics.sendPageView(
			this.props.location.pathname,
			`${parseInt(page) > 1 ? 'Playing Surface images - Page ' + page : 'Playing Surface images'}`,
		);
		this.setState({
			...this.state,
			currentPage: page,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'Playing Surface images');
		this.requestImages();

		const filters = remmapContentFilters({}, {}, {}, {}, this.props.imageFilters);
		this.props.updateContentFilters(filters);
	}

	componentWillUnmount() {
		this.actionServiceSubscription.unsubscribe();
	}

	initPageTitle() {
		document.title = `Playing Surface ${this.props.t('images')}`;
	}

	requestImages(page?: string) {
		const { currentProject } = this.props;
		const searchQuery = this.state.pageSearchQuery.length > 0 ? this.state.pageSearchQuery : '';
		const origin = this.props.origins.filter((origin: ContentAttributes) => origin.name === 'Playing Surface');

		const queryFromFilters = extractSearchQueryFromFilters(this.props.imageFilters);

		queryFromFilters.length > 0 && queryFromFilters !== '*'
			? // get images by selected filters
			  this.props.getPlayingSurfaceImages(page ? page : '1', queryFromFilters, origin, currentProject)
			: this.props.getPlayingSurfaceImages(page ? page : '1', searchQuery, origin, currentProject);
	}

	toggleEditModal(imageId: string, isOpen: boolean) {
		this.setState({
			...this.state,
			showEditModal: isOpen,
			imageId,
		});
	}

	toggleCropModal(imageId: string, isOpen: boolean) {
		this.setState({
			...this.state,
			showCropModal: isOpen,
			imageId,
		});
	}

	toggleImageEnlarge = (image: Image, enlarge: boolean) => {
		this.setState({
			...this.state,
			enlargeImage: enlarge,
			imageToEnlarge: image,
		});
	};

	displayPagination(pagination: PaginationMeta) {
		return (
			<ReactPaginate
				{...PaginationProps(this.props.t, pagination.currentPage)}
				pageCount={pagination.totalPages ? pagination.totalPages : 0}
				onPageChange={(data: any) => this.requestImages(data.selected + 1)}
			/>
		);
	}

	onContentSearchInput(data: any) {
		let searchText = data.target.value;
		this.setState({ imageSearchInput: data.target.value });
		this.props.imageFiltersUpdate(AdvancedFilterModel.builder(this.props.imageFilters).withSearchText(searchText).build());

		if (searchText.length < 1 && this.state.advancedContentSearchInput.length < 1) {
			this.props.analytics.sendEvent('Click', 'List Page', 'Search Performed');
			this.props.imageFiltersUpdate(AdvancedFilterModel.builder(this.props.imageFilters).withSearchText('').build());
			this.setState(
				(state) => {
					return { ...state, imageSearchInput: '', searchedText: '', pageSearchQuery: '' };
				},
				() => this.requestImages(),
			);
		}
	}

	onImageSearch(event: any) {
		if (event.keyCode === 13 && (this.state.imageSearchInput.length > 0 || this.state.advancedContentSearchInput.length > 0)) {
			this.onSearchContent(this.state.imageSearchInput + this.state.advancedContentSearchInput);
		}
	}

	onSearchContent = (text: string) => {
		const { advancedContentSearchInput } = this.state;

		if (text.trim().length === 0 && advancedContentSearchInput.trim().length === 0) {
			this.setState(
				(state) => {
					return { ...state, imageSearchInput: '', searchedText: '', pageSearchQuery: '' };
				},
				() => this.requestImages(),
			);
		} else {
			const origin = this.props.origins.filter((origin: ContentAttributes) => origin.name === 'Playing Surface');
			const searchText =
				text.length > 0
					? text + advancedContentSearchInput + `&originId=${origin[0].id}`
					: `*${advancedContentSearchInput + `&originId=${origin[0].id}`}`;
			this.props.searchImage(searchText, this.props.currentProject);
			this.setState({ ...this.state, searchedText: this.state.imageSearchInput, pageSearchQuery: searchText });
		}
	};

	registerActionEventListener() {
		this.actionServiceSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === PLAYING_SURFACE_IMAGES_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === PLAYING_SURFACE_IMAGES_RECEIVED) {
				this.toggleContentLoadingState(false);
			}
		});
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	updateAdvancedInputState = (text: string) => {
		this.props.imageFiltersUpdate(AdvancedFilterModel.builder(this.props.imageFilters).withSearchText(this.state.imageSearchInput).build());
		this.setState({ ...this.state, advancedContentSearchInput: text });
	};

	filterContent = () => {
		const { advancedContentSearchInput, imageSearchInput } = this.state;

		if (imageSearchInput.trim().length === 0 && advancedContentSearchInput.trim().length === 0) {
			this.setState(
				(state) => {
					return { ...state, imageSearchInput: '', searchedText: '', pageSearchQuery: '' };
				},
				() => this.requestImages(),
			);
		} else {
			const origin = this.props.origins.filter((origin: ContentAttributes) => origin.name === 'Playing Surface');
			const searchText =
				imageSearchInput.length > 0
					? imageSearchInput + advancedContentSearchInput + `&originId=${origin[0].id}`
					: `*${advancedContentSearchInput + `&originId=${origin[0].id}`}`;
			this.props.searchImage(searchText, this.props.currentProject);
			this.setState({ ...this.state, searchedText: this.state.imageSearchInput, pageSearchQuery: searchText });
		}
	};

	getAllContent = () => {
		const origin = this.props.origins.filter((origin: ContentAttributes) => origin.name === 'Playing Surface');
		this.props.getPlayingSurfaceImages('1', '', origin, this.props.currentProject);
	};

	render() {
		const { playingSurfaceImages, t, currentProject, pagination } = this.props;
		const { imageId, showEditModal, showCropModal, enlargeImage, imageToEnlarge } = this.state;

		return (
			<ConditionalRenderWrapper expectedPermissions={['read_images', 'write_images', 'delete_images']}>
				<div className='animated fadeIn'>
					<div className='card'>
						<Title title={`Playing Surface ${t('images')}`} />
						<div className='card-body'>
							<div className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
								<Row>
									<Col md='6' className='d-flex flex-column ml-auto'>
										<InputGroup>
											<Input
												type='text'
												id='playing-surface-search-input'
												name='search-input'
												placeholder={this.props.t('content_search_placeholder')}
												onChange={this.onContentSearchInput.bind(this)}
												onKeyDown={this.onImageSearch.bind(this)}
												value={this.state.imageSearchInput}
											/>
											<InputGroupAddon addonType='append'>
												<Button
													type='button'
													color='primary'
													id='playing-surface-search-button'
													onClick={() => this.onSearchContent(this.state.imageSearchInput)}
												>
													<i className={'fa fa-search'}>&nbsp;</i>
													{this.props.t('search')}
												</Button>
											</InputGroupAddon>
										</InputGroup>
									</Col>
								</Row>
								<Row className='mb-2'>
									<Col className='ml-auto'>
										<AdvancedContentFilters
											t={t}
											filtersInSidebar={true}
											searchContent={this.filterContent}
											updateAdvancedSearchText={this.updateAdvancedInputState}
											getAllContent={this.getAllContent}
											contentSearchInput={this.state.imageSearchInput}
											showOriginSelect={false}
											contentType={ContentTypes.PLAYING_SURFACE}
										/>
									</Col>
								</Row>
								<Row className={'mb-3'}>
									<ImageCells
										images={playingSurfaceImages}
										onEdit={(imageId: string) => {
											this.toggleEditModal(imageId, true);
										}}
										onImageClick={(image: Image) => {
											this.toggleImageEnlarge(image, true);
										}}
										onCrop={(imageId: string) => {
											this.toggleCropModal(imageId, true);
										}}
										t={t}
									/>
								</Row>
								{playingSurfaceImages && playingSurfaceImages.length === 0 && (
									<h6 className='d-flex justify-content-center mb-2'>{this.props.t('no_data_found')}</h6>
								)}
							</div>
							<ImageEditModal
								onClose={(imageUpdated: boolean) => {
									this.toggleEditModal('', false);

									if (imageUpdated) {
										this.requestImages();
									}
								}}
								imageId={imageId}
								currentProject={currentProject}
								open={showEditModal}
								t={t}
								isPlayingSurface={true}
							/>
							{this.displayPagination(pagination)}
						</div>
					</div>
				</div>
				<ImageCropContainer
					t={t}
					imageId={imageId}
					currentProject={currentProject}
					open={showCropModal}
					onClose={() => {
						this.toggleCropModal('', false);
					}}
				/>
				{enlargeImage && (
					<LightboxContainer
						t={t}
						currentProject={currentProject}
						imageToEnlarge={imageToEnlarge}
						toggleImageEnlarge={this.toggleImageEnlarge}
					/>
				)}
			</ConditionalRenderWrapper>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		playingSurfaceImages: state.image.playingSurfaceImages,
		currentProject: state.project.currentProject,
		pagination: state.image.pagination,
		origins: state.imageOwners.owners,
		imageFilters: state.image.playingSurfaceFilters,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getPlayingSurfaceImages: (page: string, text: string, origin: ContentAttributes[], project: Project) =>
			dispatch(playingSurfaceImageRequest(page, text, origin, project)),
		searchImage: (text: string, project: Project) => dispatch(playingSurfaceImageSearchRequest(text, project)),
		imageFiltersUpdate: (filters: any) => dispatch(returnObjectForPlayingSurfaceFiltersUpdate(filters)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(PlayingSurfaceImageListing) as React.ComponentType<
	Properties
>;

export enum SportTypes {
	FOOTBALL = 'football',
	BASKETBALL = 'basketball',
	TENNIS = 'tennis',
}

export type SportTypesValue = SportTypes.BASKETBALL | SportTypes.FOOTBALL | SportTypes.TENNIS;

export enum SportBlockTypes {
	EVENT_BLOCK = 'event',
	TOURNAMENT_BLOCK = 'tournament',
}

export enum WidgetScriptTypes {
	WIDGETS = 'widgets-loader-script',
	FOOTBALL_WIDGETS = 'widgets-football-loader-script',
	BASKETBALL_WIDGETS = 'widgets-basketball-loader-script',
	TENNIS_WIDGETS = 'widgets-tennis-loader-script',
}

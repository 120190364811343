import React from 'react';
import { connect } from 'react-redux';
import BlockModel from '../models/block.model';
import { ViewTypes } from '../constants/general.constants';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	lang?: string;
	contentData?: any;
	contentLanguage?: any;
};

const BlockUpdateController = (WrappedComponent: any) => {
	class BlockUpdateControllerHOC extends React.Component<Properties> {
		shouldComponentUpdate(nextProps: Readonly<Properties>): boolean {
			return (
				nextProps.block.data.changeId !== this.props.block.data.changeId ||
				this.props.view !== nextProps.view ||
				this.props.lang !== nextProps.lang
			);
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	}

	function mapStateToProps(state: any) {
		return {
			lang: state.profile.profile.language,
		};
	}

	return connect(mapStateToProps, null)(BlockUpdateControllerHOC);
};

export default BlockUpdateController;

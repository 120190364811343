import Project from '../../models/project/Project';
import Author from '../../models/author/Author';

export const PROJECT_AUTHOR_LISTING_RECEIVED = '[ACTION] PROJECT_AUTHOR_LISTING_RECEIVED';

export const AUTHOR_LISTING_REQUEST = '[ACTION] AUTHOR_LISTING_REQUEST';
export const AUTHOR_LISTING_RECEIVED = '[ACTION] AUTHOR_LISTING_RECEIVED';

export const AUTHOR_LISTING_UPDATE = '[ACTION] AUTHOR_LISTING_UPDATE';

export const AUTHOR_SEARCH = '[ACTION] AUTHOR_SEARCH';

export const AUTHOR_ENTITY_DELETE = '[ACTION] AUTHOR_ENTITY_DELETE';
export const AUTHOR_ENTITY_DELETE_SUCCESS = '[ACTION] AUTHOR_ENTITY_DELETE_SUCCESS';
export const AUTHOR_ENTITY_DELETE_FAILED = '[ACTION] AUTHOR_ENTITY_DELETE_FAILED';

export const AUTHOR_ENTITY_CREATE = '[ACTION] AUTHOR_ENTITY_CREATE';
export const AUTHOR_ENTITY_CREATE_SUCCESS = '[ACTION] AUTHOR_ENTITY_CREATE_SUCCESS';
export const AUTHOR_ENTITY_CREATE_FAILED = '[ACTION] AUTHOR_ENTITY_CREATE_FAILED';

export const AUTHOR_ENTITY_UPDATE = '[ACTION] AUTHOR_ENTITY_UPDATE';
export const AUTHOR_ENTITY_UPDATE_FAILED = '[ACTION] AUTHOR_ENTITY_UPDATE_FAILED';
export const AUTHOR_ENTITY_UPDATE_SUCCESS = '[ACTION] AUTHOR_ENTITY_UPDATE_SUCCESS';

export const AUTHOR_ENTITY_REQUEST = '[ACTION] AUTHOR_ENTITY_REQUEST';
export const AUTHOR_ENTITY_RECEIVED = '[ACTION] AUTHOR_ENTITY_RECEIVED';
export const AUTHOR_ENTITY_REQUEST_FAILED = '[ACTION] AUTHOR_ENTITY_REQUEST_FAILED';

export const PROJECT_DEFAULT_AUTHOR_RECEIVED = '[ACTION] PROJECT_DEFAULT_AUTHOR_RECEIVED';
export const DEFAULT_AUTHOR_UPDATE = '[ACTION] DEFAULT_AUTHOR_UPDATE';

export function returnObjectForProjectAuthorListingReceived(authors: any) {
	return {
		type: PROJECT_AUTHOR_LISTING_RECEIVED,
		payload: authors,
	};
}

export function returnObjectForProjectDefaultAuthorReceived(author: any) {
	return {
		type: PROJECT_DEFAULT_AUTHOR_RECEIVED,
		payload: author,
	};
}

export function returnObjectForAuthorListingReceived(authors: any) {
	return {
		type: AUTHOR_LISTING_RECEIVED,
		payload: authors,
	};
}

export function returnObjectForAuthorListingRequest(page: string, project: Project, text: string) {
	return {
		type: AUTHOR_LISTING_REQUEST,
		payload: { page, project, text },
	};
}

export function returnObjectForAuthorListingUpdate(authors: Author[]) {
	return {
		type: AUTHOR_LISTING_UPDATE,
		payload: authors,
	};
}

export function returnObjectForAuthorEntityCreate(author: Author, project: Project) {
	return {
		type: AUTHOR_ENTITY_CREATE,
		payload: { author, project },
	};
}

export function returnObjectForAuthorEntityCreateSuccess(id: string) {
	return {
		type: AUTHOR_ENTITY_CREATE_SUCCESS,
		payload: id,
	};
}

export function returnObjectForAuthorEntityUpdate(author: Author, project: Project) {
	return {
		type: AUTHOR_ENTITY_UPDATE,
		payload: { author, project },
	};
}

export function returnObjectForAuthorEntityUpdateFailed() {
	return {
		type: AUTHOR_ENTITY_UPDATE_FAILED,
	};
}

export function returnObjectForAuthorEntityUpdateSuccess() {
	return {
		type: AUTHOR_ENTITY_UPDATE_SUCCESS,
	};
}

export function returnObjectForAuthorEntityCreateFailed() {
	return {
		type: AUTHOR_ENTITY_CREATE_FAILED,
	};
}

export function returnObjectForAuthorEntityRequest(id: string, project: Project) {
	return {
		type: AUTHOR_ENTITY_REQUEST,
		payload: { id, project },
	};
}

export function returnObjectForAuthorEntityReceived(author: any) {
	return {
		type: AUTHOR_ENTITY_RECEIVED,
		payload: author,
	};
}

export function returnObjectForAuthorSearch(text: string, project: Project) {
	return {
		type: AUTHOR_SEARCH,
		payload: { text, project },
	};
}

export function returnObjectForAuthorEntityDelete(id: string, project: Project) {
	return {
		type: AUTHOR_ENTITY_DELETE,
		payload: { id, project },
	};
}

export function returnObjectForAuthorEntityDeleteSuccess() {
	return {
		type: AUTHOR_ENTITY_DELETE_SUCCESS,
	};
}

export function returnObjectForAuthorEntityDeleteFailed() {
	return {
		type: AUTHOR_ENTITY_DELETE_FAILED,
	};
}

export function returnObjectForDefaultAuthorEntityUpdate(author: Author) {
	return {
		type: DEFAULT_AUTHOR_UPDATE,
		payload: { author },
	};
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditorEditBlock from './editor-block-edit.component';
import './editor-block.component.scss';
import Global from '../../helpers/global.helpers';
import { blockManagementService } from '../../subcomponents/blocky.component';
import { PARAGRAPH_BLOCK } from '../../constants/block-list.constants';
import BlockModel from '../../models/block.model';
import { EditorTypes } from '../../constants/block.types';
import debounce from 'lodash.debounce';
import EditorHelper from './helpers/editor-block-edit.helper';
import { allowDataResourceId, allowDataResourceType, allowDataSource } from './ck-editor-custom-plugins/anchor-custom-attr.plugin';
import _ from 'lodash';

export const toolbars = {
	paragraph: ['bold', 'italic', 'underline', 'link', 'highlight', 'changeParagraph', 'alignment', 'specialCharacters'],
	heading: ['heading', 'bold', 'italic', 'underline', 'link', 'highlight', 'changeHeading', 'alignment', 'specialCharacters'],
	quote: ['blockQuote', 'bold', 'italic', 'underline', 'link', 'highlight', 'changeQuote', 'alignment', 'specialCharacters'],
	table: ['insertTable', 'bold', 'italic', 'underline', 'link', 'alignment', 'specialCharacters'],
	list: ['numberedList', 'bulletedList', 'bold', 'italic', 'underline', 'link', 'specialCharacters'],
};

class EditorBlock extends Component {
	constructor(props) {
		super(props);
		const editorHelper = new EditorHelper();
		this.state = {
			linkingConfig: editorHelper.getLinkingFeatureConfig(),
			editorConfig: this.createCKEditorConfig(props.block.data, editorHelper.getLinkingFeatureConfig().isLinkingEnabled),
		};
	}

	shouldComponentUpdate(nextProps) {
		const tagsCurrent = this.props.block.data.tags ? this.props.block.data.tags.filter((tag) => !tag.removed) : [];
		const tagsNext = nextProps.block.data.tags ? nextProps.block.data.tags.filter((tag) => !tag.removed) : [];

		return (
			this.props.block.data.content !== nextProps.block.data.content ||
			this.props.block.data.type !== nextProps.block.data.type ||
			!_.isEqual(tagsCurrent, tagsNext) ||
			this.props.profileLang !== nextProps.profileLang
		);
	}

	createCKEditorConfig = (data, isAutoLinkingEnabled) => {
		const linkDecorators = isAutoLinkingEnabled
			? {
					autolinkDisableDecorator: {
						mode: 'manual',
						label: 'Do not auto-link',
						attributes: {
							class: 'autolink-disabled',
						},
					},
					autolinkEnableDecorator: {
						mode: 'manual',
						label: 'Autolink',
						attributes: {
							class: 'autolink-enabled',
						},
					},
					newTabDecorator: {
						mode: 'manual',
						label: 'Open in new tab',
						attributes: {
							target: '_blank',
						},
					},
			  }
			: {
					newTabDecorator: {
						mode: 'manual',
						label: 'Open in new tab',
						attributes: {
							target: '_blank',
						},
					},
			  };

		return {
			toolbar: toolbars[data.type],
			heading: {
				options: [
					{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
					{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
					{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
					{ model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
				],
			},
			link: { decorators: linkDecorators },
			extraPlugins: isAutoLinkingEnabled ? [allowDataResourceId, allowDataResourceType, allowDataSource] : [],
		};
	};

	onTypeChange = (data) => {
		const { linkingConfig } = this.state;
		this.setState(
			{
				editorConfig: this.createCKEditorConfig(data, linkingConfig.isLinkingEnabled),
			},
			() => {
				this.onEditorChange(data);
			},
		);
	};

	onEditorChange = (data) => {
		const { block, index } = this.props;
		data.placeholderName = block.id;
		const blockModel = BlockModel.builder().withType(block.type).withData(data).withId(block.id).build();
		blockManagementService.blockUpdate(blockModel, index);
	};

	onEditorChangeDebounce = debounce((data) => {
		this.onEditorChange(data);
	}, 500);

	addParagraphBlock = () => {
		const { index } = this.props;
		const editorHelper = new EditorHelper();
		const content = editorHelper.getContentForNewParagraph();
		const data = { ...PARAGRAPH_BLOCK.default_data.data };
		data.content = content;

		const paragraphBlock = BlockModel.builder()
			.withData(Object.assign({}, data))
			.withId(Global.makeId(6))
			.withType(EditorTypes.editor)
			.build();

		editorHelper.setEditorFocused(paragraphBlock.id, true);
		blockManagementService.blockAdd({ block: paragraphBlock, index });
	};

	render() {
		const { block, onRemove, t } = this.props;
		const { linkingConfig, editorConfig } = this.state;
		block.data.placeholderName = block.id;

		return (
			<EditorEditBlock
				data={Object.assign({}, block.data)}
				onChange={this.onEditorChangeDebounce}
				onTypeChange={this.onTypeChange}
				linkingConfig={linkingConfig}
				editorConfig={editorConfig}
				t={t}
				onAddParagraph={this.addParagraphBlock}
				onRemove={onRemove}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		profileLang: state.profile.profile.language,
	};
}

export default connect(mapStateToProps, null)(EditorBlock);

import React, { Component } from 'react';
import { Col, Input, Row } from 'reactstrap';
import InlineEditor from '@mediaplatform/ckeditor-inline/ckeditor';
import { Subscription } from 'rxjs';
import { EditorTypes } from './../../constants/block.types';
import EntityLinkingService from './services/entity-linking.service';
import ManualLinkingControls from './subcomponents/manul-linking.component';
import EditorHelper from './helpers/editor-block-edit.helper';
import EditorTransformService from './services/editor-transform.service';
import { autoTagService } from './../../subcomponents/blocky.component';
import LoaderUIService from './services/loader.ui-service';
import AutoLinkingLoader from './subcomponents/auto-linking-loader.component';
import { connect } from 'react-redux';
import { addSuggestedEntity } from '../../../../../store/action-creators/suggested-entities';
import { modelFootballConnectionToFootballConnectionResponse } from '../../../../../models/v2/football-connection/response-football-connection.mapper';
import { generateFootballConnectionWithEntityType } from '../../../Sidebar/tags-refactored/helpers/suggested-entities.helper';

export const loaderUiService = new LoaderUIService();

class EditorEditBlock extends Component {
	globalTagsServiceSubscription = new Subscription();

	editorSwitchType = {
		'Heading 1': EditorTypes.heading,
		'Heading 2': EditorTypes.heading,
		'Heading 3': EditorTypes.heading,
		'Heading 4': EditorTypes.heading,
		Paragraph: EditorTypes.paragraph,
		Quote: EditorTypes.quote,
	};

	editorElementRef = null;

	constructor(props) {
		super(props);
		this.editorElementRef = React.createRef();
		this.state = {
			editor: null,
			isEnterListenerInit: false,
			cursorLastPosition: null,
			isLoadingAutoTag: false,
			linkingService: new EntityLinkingService(),
			tabIndex: '',
			id: '',
		};
	}

	setIsLoadingAutoTagState = (isLoading) => {
		this.setState({
			isLoadingAutoTag: isLoading,
		});
	};

	clearHeadingAttributesState = () => {
		this.setState({
			tabIndex: '',
			id: '',
		});
	};

	setToCorrectHeading(content, input) {
		if (content.includes('h4')) {
			return `<h4>${input}</h4>`;
		} else if (content.includes('h3')) {
			return `<h3>${input}</h3>`;
		} else if (content.includes('h2')) {
			return `<h2>${input}</h2>`;
		} else return `<h1>${input}</h1>`;
	}

	onEditorInputChange = (input) => {
		const { data, onChange, linkingConfig } = this.props;
		if (input.length === 0 && data && data.type) {
			switch (data.type) {
				case 'heading':
					let newContent = this.setToCorrectHeading(data.content, input);
					data.content = newContent;
					break;
				case 'paragraph':
					data.content = `<p>${input}</p>`;
					break;
				case 'quote':
					data.content = `<blockquote><p>${input}</p></blockquote>`;
					break;
			}
		} else {
			data.content = input;
		}

		if (data.content === null || data.content === undefined || data.content.length < 1) {
			data.tags = [];
		} else if (!linkingConfig.isManual) {
			this.state.linkingService.cancelAutoTagRequests();
		}

		onChange(data);
	};

	onEditorInputWithTagsChange = (input, tags) => {
		const { data, onChange } = this.props;
		const tempData = Object.assign({}, data);
		tempData.content = input;
		tempData.tags = tags;
		onChange(tempData);
	};

	updateEditorsListInWindow(editor, editorType) {
		if (editorType === undefined || editorType !== 'list') {
			this.disableCommand(editor.commands.get('enter'));
		}

		this.storeEditorTypeInLocalStorage(editor, editorType);
	}

	/**
	 * This is used as a means to communicate the currently focused editor to the copyOnEnter plugin
	 * in the Inline CKEditor.
	 * The plugin checks for 'paragraph' editor type. If the editor is of a 'paragraph' type the plugin
	 * copies the right hand side of the cursor placement and stores it in the localstorage with
	 * 'ckeditor-after-enter-content' key and afterwards deletes the same right hand side from the editor
	 *
	 * @param editor
	 * @param editorType
	 */
	storeEditorTypeInLocalStorage(editor, editorType) {
		editor.ui.focusTracker.on('change:isFocused', (evt, name, isFocused) => {
			if (isFocused) {
				localStorage.setItem('editor-type-focused', editorType);
			}
		});
	}

	/**
	 * Disables a given command from the user input e.g. enter command
	 * @param cmd
	 */
	disableCommand(cmd) {
		cmd.on('set:isEnabled', this.forceDisable, { priority: 'highest' });

		cmd.isEnabled = false;

		// Make it possible to enable the command again.
		return () => {
			cmd.off('set:isEnabled', this.forceDisable);
			cmd.refresh();
		};
	}

	forceDisable(evt) {
		evt.return = false;
		evt.stop();
	}

	initEditorKeyListener(editor) {
		const editorBlockData = JSON.parse(JSON.stringify(this.props.data));
		const { onAddParagraph, onTypeChange } = this.props;
		const { isEnterListenerInit } = this.state;

		if (editor && !isEnterListenerInit && editorBlockData.type !== 'list' && editorBlockData.type !== 'table') {
			editor.editing.view.document.on('keydown', (evt, data) => {
				if (data.keyCode === 13 && !data.shiftKey && !data.ctrlKey && onAddParagraph) {
					editorBlockData.type === 'paragraph' && this.automatedLinking(editor, editorBlockData);
					onAddParagraph();
				}
			});

			editor.on('execute', (event) => {
				const transformService = new EditorTransformService();
				editorBlockData.content = transformService.transform(editorBlockData.type, editor.getData(), event.source.label);
				editorBlockData.type = this.editorSwitchType[event.source.label];

				if (onTypeChange) {
					onTypeChange(editorBlockData);
					this.clearHeadingAttributesState();
				}
			});
		}
	}

	focusEditorIfNeeded(editor) {
		const { data } = this.props;
		const editorHelper = new EditorHelper();
		const isFocused = editorHelper.isEditorFocused(data.placeholderName);

		if (isFocused && editor) {
			editor.editing.view.focus();
			editorHelper.setEditorFocused(data.placeholderName, false);
		}
	}

	// Transform the input text into the type of the current editor
	updateInputTypeWithEditorType = (editor, data) => {
		const editorHelper = new EditorHelper();
		const clipboardPlugin = editor.plugins.get('Clipboard');

		editor.editing.view.document.on('clipboardInput', (evt, editorData) => {
			const dataTransfer = editorData.dataTransfer;
			let html = dataTransfer.getData('text/plain');

			switch (data.type) {
				case 'paragraph':
					html = `<p>${html}</p>`;
					editorHelper.updateHTML(clipboardPlugin, html, dataTransfer, editor, evt);
					break;

				case 'quote':
					html = `${html}`;
					editorHelper.updateHTML(clipboardPlugin, html, dataTransfer, editor, evt);
					break;

				case 'heading':
					html = `${html}`;
					editorHelper.updateHTML(clipboardPlugin, html, dataTransfer, editor, evt);
					this.clearHeadingAttributesState();
					break;

				default:
					html = dataTransfer.getData('text/html') !== '' ? dataTransfer.getData('text/html') : dataTransfer.getData('text/plain');
					// Fix for Mac OS Chrome pasting text - remove <style> tag with its content and preserve other html tags
					html = html.replace(/(<style[\w\W]+style>)/g, '');
					editorHelper.updateHTML(clipboardPlugin, html, dataTransfer, editor, evt);
			}
		});
	};

	componentWillUnmount() {
		this.globalTagsServiceSubscription.unsubscribe();
		this.state.linkingService.cancelAutoTagRequests();
		EditorHelper.destroyEditor(this.props.data.placeholderName, this.props.data.type);
	}

	componentDidMount() {
		let content = this.props.data.content;
		let id = EditorHelper.extractID(content);
		let tabIndex = EditorHelper.extractTabIndex(content);

		if (this.props.blockPlaceholder.includes(this.props.data.placeholderName)) {
			this.initCKEditor(true);
		} else {
			this.initCKEditor(false);
		}

		if ((tabIndex && tabIndex.length > 0) || (id && id.length > 0)) {
			this.setState({ id: id, tabIndex: tabIndex });
			EditorHelper.addAdditionalAttributesToHeading('tabindex', this.state.tabIndex, this.props.data.placeholderName);
			EditorHelper.addAdditionalAttributesToHeading('id', this.state.id, this.props.data.placeholderName);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { editorConfig, data } = this.props;

		if (editorConfig.toolbar[0] !== prevProps.editorConfig.toolbar[0]) {
			this.initCKEditor(true);
		}

		if (prevState.tabIndex !== this.state.tabIndex) {
			EditorHelper.addAdditionalAttributesToHeading('tabindex', this.state.tabIndex, data.placeholderName);
		}

		if (prevState.id !== this.state.id) {
			EditorHelper.addAdditionalAttributesToHeading('id', this.state.id, data.placeholderName);
		}

		// when the data has id/tabindex attribute we should extract it from the content because of undo/redo functionality
		// (we should update the tabindex/id input with correct values after undo/redo)
		if (prevProps.data.type === 'heading') {
			const stateId = EditorHelper.extractID(this.props.data.content);
			const stateTabIndex = EditorHelper.extractTabIndex(this.props.data.content);
			if (stateTabIndex !== this.state.tabIndex || stateId !== this.state.id) {
				this.setState({
					tabIndex: !!stateTabIndex ? stateTabIndex : '',
					id: !!stateId ? stateId : '',
				});
			}
		}

		if (prevProps.data.content !== data.content) {
			const editor = EditorHelper.getEditor(data.placeholderName);
			editor.setData(data.content ? data.content : '<p></p>');
		}
	}

	initCKEditor = (shouldSetAutolinking) => {
		const { data, editorConfig } = this.props;
		const placeholderName = data.placeholderName ? data.placeholderName : 'ckeditor-fresh-init';
		this.destroyEditor(() => {
			InlineEditor.create(document.querySelector(`#${placeholderName}`), editorConfig)
				.then((editor) => {
					EditorHelper.updateEditor(editor, placeholderName);
					editor.setData(data.content ? data.content : '<p></p>');

					if (shouldSetAutolinking) {
						this.automatedLinking(editor, data);
					}
					this.initGlobalTagListener(editor);
					this.focusEditorIfNeeded(editor);
					this.initEditorKeyListener(editor);
					this.initEditorDataChangeListener(editor);
					this.updateEditorsListInWindow(editor, data.type, data.placeholderName);
					this.updateInputTypeWithEditorType(editor, data);
				})
				.catch((err) => {
					console.error(err);
				});
		});
	};

	initEditorDataChangeListener = (editor) => {
		editor.model.document.on('change:data', () => {
			if (this.state.tabIndex && this.state.tabIndex.length > 0) {
				editor.sourceElement.firstChild.setAttribute('tabindex', this.state.tabIndex);
			}
			if (this.state.id && this.state.id.length > 0) {
				editor.sourceElement.firstChild.setAttribute('id', this.state.id);
			}
			this.onEditorInputChange(editor.getData());
		});
	};

	destroyEditor = (callback) => {
		const { data } = this.props;
		const editor = EditorHelper.getEditor(data.placeholderName);

		if (editor) {
			editor.destroy().then(() => {
				callback();
			});
		} else {
			callback();
		}
	};

	handleTabindexChange = (e) => {
		this.setState({ tabIndex: e.target.value });
	};

	handleIdChange = (e) => {
		this.setState({ id: e.target.value });
	};

	// Start AUTO-TAGGING Logic
	initGlobalTagListener = (editor) => {
		if (this.globalTagsServiceSubscription && !this.globalTagsServiceSubscription.closed) {
			this.globalTagsServiceSubscription.unsubscribe();
		}
		this.globalTagsServiceSubscription = autoTagService.autoTagSubject.subscribe((action) => {
			const { data } = this.props;

			const editorHelper = new EditorHelper();

			const tags = data.tags
				? data.tags.map((tag) => {
						tag['firstOnly'] = action;

						return tag;
				  })
				: [];

			if (editor && editorHelper.hasAutoTagLinkOptionsConfig() && tags.length > 0) {
				this.state.linkingService.addLinksToTextMultiTags(tags, editor.getData(), data.tags, (obj) => {
					this.onEditorInputWithTagsChange(obj.text, obj.updatedTags);
				});
			}
		});
	};

	storeSuggestedEntitiesInRedux = (paragraphIdentificator, tags) => {
		if (tags && paragraphIdentificator) {
			// store suggested entities in redux
			try {
				tags.forEach((tag) => {
					const tagAsFootballConnection = modelFootballConnectionToFootballConnectionResponse(tag);
					const result = generateFootballConnectionWithEntityType(
						paragraphIdentificator,
						tagAsFootballConnection.entity_type,
						tagAsFootballConnection,
					);
					this.props.addSuggestedEntity(result);
				});
			} catch (error) {}
		}
	};

	automatedLinking = (editor, data) => {
		let editorData = editor.getData();
		const { linkingConfig } = this.props;

		if (data.content === null || data.content === undefined || data.content.length < 1) {
			return;
		}

		if (data.type === EditorTypes.paragraph && linkingConfig.isLinkingEnabled) {
			this.setIsLoadingAutoTagState(true);
			this.state.linkingService
				.link(editorData, data.tags, linkingConfig.config, data.placeholderName)
				.then((linkedTextAndTags) => {
					this.storeSuggestedEntitiesInRedux(data.placeholderName, linkedTextAndTags.tags);
					this.onEditorInputWithTagsChange(linkedTextAndTags.text, linkedTextAndTags.tags);
					this.setIsLoadingAutoTagState(false);
				})
				.catch(() => {
					this.setIsLoadingAutoTagState(false);
				});
		}
	};

	onTagsRequest = () => {
		this.setIsLoadingAutoTagState(true);
		const { data } = this.props;
		const text = EditorHelper.getEditor(data.placeholderName).getData();

		this.state.linkingService
			.requestTags(text, data.placeholderName)
			.then((obj) => {
				this.storeSuggestedEntitiesInRedux(data.placeholderName, obj.tags);
				this.onEditorInputWithTagsChange(obj.text, obj.tags);
			})
			.finally(() => this.setIsLoadingAutoTagState(false));
	};

	onSingleTagInsert = (tag) => {
		const { data } = this.props;
		const editor = EditorHelper.getEditor(data.placeholderName);

		this.state.linkingService.addLinksToText(tag, editor.getData(), data.tags, (obj) => {
			this.onEditorInputWithTagsChange(obj.text, obj.updatedTags);
		});
	};

	onMultipleTagsInsert = (tags) => {
		const { data } = this.props;
		const editor = EditorHelper.getEditor(data.placeholderName);

		this.state.linkingService.addLinksToTextMultiTags(tags, editor.getData(), data.tags, (obj) => {
			this.onEditorInputWithTagsChange(obj.text, obj.updatedTags);
		});
	};

	shouldShowAutoTagLoader = (id) => {
		return true;
	};

	// END AUTO-TAGGING LOGIC

	render() {
		const { data, linkingConfig, t } = this.props;
		const { isLoadingAutoTag } = this.state;
		const placeholderName = data.placeholderName ? data.placeholderName : 'ckeditor-fresh-init';

		return (
			<>
				<Row className='mb-1'>
					<Col>
						<div id={placeholderName}></div>
					</Col>
				</Row>
				{data.type === EditorTypes.paragraph && linkingConfig.isLinkingEnabled && linkingConfig.isManual && (
					<ManualLinkingControls
						onMultipleTagsInsert={this.onMultipleTagsInsert}
						isLoading={isLoadingAutoTag}
						onSingleTagInsert={this.onSingleTagInsert}
						tags={data.tags}
						requestTags={this.onTagsRequest}
						t={t}
						id={placeholderName}
					/>
				)}
				{data.type === EditorTypes.paragraph && linkingConfig.isLinkingEnabled && !linkingConfig.isManual && (
					<AutoLinkingLoader id={data.placeholderName} t={t} />
				)}
				{data.type === EditorTypes.heading && (
					<Row>
						<Col>
							<Input
								id='heading-tabindex-prop'
								value={this.state.tabIndex}
								type='number'
								size='2'
								placeholder='TabIndex'
								autoComplete='off'
								autoCorrect='off'
								autoCapitalize='off'
								spellCheck='false'
								onChange={this.handleTabindexChange}
							/>
						</Col>
						<Col>
							<Input
								id='heading-id-prop'
								value={this.state.id}
								onChange={this.handleIdChange}
								size='2'
								placeholder='ID'
								autoComplete='off'
								autoCorrect='off'
								autoCapitalize='off'
								spellCheck='false'
							/>
						</Col>
					</Row>
				)}
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		blockPlaceholder: state.blocky.blockPlaceholder,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		addSuggestedEntity: (suggested) => dispatch(addSuggestedEntity(suggested)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorEditBlock);

import GalleryBlockModel from './gallery-block.model';
import { GalleryBlockJson } from './gallery-block.json';

export default class GalleryBlockBuilder {
	private json: GalleryBlockJson;

	constructor(gallery?: GalleryBlockModel | GalleryBlockJson) {
		if (gallery && gallery instanceof GalleryBlockModel) {
			this.json = gallery.toJson();
		} else if (gallery) {
			this.json = gallery;
		} else {
			this.json = {} as GalleryBlockJson;
		}
	}

	withId(id: string): GalleryBlockBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): GalleryBlockBuilder {
		this.json.title = title;

		return this;
	}

	withImage(image: string): GalleryBlockBuilder {
		this.json.image = image;

		return this;
	}

	withSubtitile(subtitle: string): GalleryBlockBuilder {
		this.json.subtitle = subtitle;

		return this;
	}

	withPublishedAt(publishedAt: string): GalleryBlockBuilder {
		this.json.publishedAt = publishedAt;

		return this;
	}

	withItems(items: []): GalleryBlockBuilder {
		this.json.items = items;

		return this;
	}

	withSelectedItemId(selectedItemId: string): GalleryBlockBuilder {
		this.json.selectedItemId = selectedItemId;

		return this;
	}

	withCategory(category: any): GalleryBlockBuilder {
		this.json.category = category;

		return this;
	}

	withStatus(status: any): GalleryBlockBuilder {
		this.json.status = status;

		return this;
	}

	withAdditionalCategories(additionalCategories: any[]): GalleryBlockBuilder {
		this.json.additionalCategories = additionalCategories;

		return this;
	}

	withMainImage(mainImage: any): GalleryBlockBuilder {
		this.json.mainImage = mainImage;

		return this;
	}

	withWidth(width: string): GalleryBlockBuilder {
		this.json.width = width;

		return this;
	}

	withAlignment(alignment: string): GalleryBlockBuilder {
		this.json.alignment = alignment;

		return this;
	}

	withLanguage(language: string): GalleryBlockBuilder {
		this.json.language = language;

		return this;
	}

	build(): GalleryBlockModel {
		return GalleryBlockModel.fromJson(this.json);
	}
}

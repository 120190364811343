import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LiveBlogEditorialAdminErrorFallback: FC = () => {
	const [t] = useTranslation();
	const [countdown, setCountdown] = useState(
		window.location.origin.includes('localhost') || window.location.origin.includes('.integration.sportal365') ? 30 : 5,
	);

	useEffect(() => {
		const countdownInterval = setInterval(() => {
			setCountdown((prev) => prev - 1);
		}, 1000);

		return () => {
			clearInterval(countdownInterval);
		};
	}, []);

	useEffect(() => {
		if (countdown === 1) {
			window.location.reload();
		}
	}, [countdown]);

	return <div className='live-blog-editorial-admin-error-fallback'>{t('fatal_error', { countdown })}</div>;
};

export default LiveBlogEditorialAdminErrorFallback;

import React from 'react';
import { CardHeader, Col, Label, Row, UncontrolledCollapse } from 'reactstrap';
import { Properties, State } from './properties/image-upload.properties';
import Image from '../../../../../../../models/image/Image';
import ImageBlockModel from '../models/image-block.model';
import { ImageProperties } from '../../../../partials/image-properties/image-properties.component';
import ImageUploadContainer from '../../../../partials/image-upload/image-upload-container';
import './image-select.component.scss';

class ImageUpload extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);

		this.state = {
			copySportsRelated: true,
			isImageProcessing: false,
			isOpen: false,
			imageUploaded: false,
		};
	}

	updateCopySportsRelatedState = (checked: boolean) => this.setState({ ...this.state, copySportsRelated: checked });

	setImageProcessingState = (isProcessing: boolean) =>
		this.setState({
			...this.state,
			isImageProcessing: isProcessing,
		});

	onChange = (image: Image) => {
		this.props.onChange(
			ImageBlockModel.builder(this.props.blockData)
				.withAlignment('center')
				.withWidth('100')
				.withImage(image)
				.withDescription(image.description)
				.withAlt(image.alt)
				.withCaption(image.caption)
				.withRemoveWatermarkChecked(false)
				.withAddWatermarkChecked(false)
				.build(),
		);
		this.setState({ isOpen: true, imageUploaded: true });
	};

	toggle = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};

	render() {
		return (
			<>
				<Row>
					<Col>
						<Label>{this.props.t('image_upload')}</Label>
					</Col>
				</Row>
				<Row>
					<Col>
						<ImageUploadContainer
							blockId={this.props.blockId}
							t={this.props.t}
							display
							imageUploadButtonId={`blocky-gallery-items-upload-button-${this.props.blockId}`}
							copySportsRelated={this.state.copySportsRelated}
							isSingleUpload
							onImageProcessing={this.setImageProcessingState}
							sportsRelatedContent={this.props.contentData.related}
							onCopySportsRelated={this.updateCopySportsRelatedState}
							onImageUploadSuccess={this.onChange}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<CardHeader className='cursor-pointer d-flex align-items-center' id='togglerImageProperties' onClick={this.toggle}>
							<strong>{this.props.t('image_properties')}</strong>
							<div className='image-properties-toggler card-header-actions'>
								{this.state.isOpen ? <i className='fa fa-toggle-on' /> : <i className='fa fa-toggle-off' />}
							</div>
						</CardHeader>
					</Col>
				</Row>
				<UncontrolledCollapse isOpen={this.state.isOpen} toggler='#togglerImageProperties' className='p-3'>
					<ImageProperties
						isImageBlock
						t={this.props.t}
						blockId={this.props.blockId}
						blockData={this.props.blockData}
						onChange={this.props.onChange}
						contentType='image'
						authors={this.props.authors}
						displayDescriptionCheckbox={true}
						hideWatermarkCheckbox={this.props.blockData.image && this.props.blockData.image.id && this.state.imageUploaded ? false : true}
						owners={this.props.owners}
					/>
				</UncontrolledCollapse>
			</>
		);
	}
}

export default ImageUpload;

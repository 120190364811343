import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class BookmakerModel {
	@jsonMember(Number)
	public id: number = 0;

	@jsonMember(String)
	public name: string = '';

	@jsonMember(String, { name: 'url_logo' })
	public logo: string = '';
}

import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';

export const getEntityMedia = (tag: any) => {
	const footballConnectionTypes = {
		player: 'url_thumb',
		team: 'url_logo',
		coach: 'url_image',
		venue: 'url_image',
		tournament: 'url_logo',
	};

	if (tag) {
		let media = tag.entity[footballConnectionTypes[tag.subtype]]
			? tag.entity[footballConnectionTypes[tag.subtype]]
			: assetsPlaceholder[tag.subtype];
		return media;
	}
	return null;
};

export const remapAdditionalTags = (tag: any, tagParent: any) => {
	if (tag && tag.entity && tagParent && tagParent.id) {
		return {
			id: tag.entity.id,
			parent: tagParent.id,
			source: tag.source,
			entity_type: tag.subtype,
			subtype: tag.subtype,
			text: tag.entity.origin,
		};
	}
	return {};
};

import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { teamPlaceholder } from '../../../../../../constants/resource-constants';
import Translation from './translation.model';

class Asset {
	@jsonMember(String)
	public url: string = teamPlaceholder;
}

@jsonObject
export default class SportTeamModel {
	@jsonMember(String)
	public id: string = '';

	@jsonMember(String)
	public name: string = '';

	@jsonMember(String)
	public sport: string = '';

	@jsonMember(String)
	public type: string = '';

	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';

	@jsonMember(String, { name: 'country_id' })
	public countryId: string = '';

	@jsonMember(String, { name: 'competition_ids' })
	public competitionIds: string = '';

	@jsonMember(String)
	public gender: string = '';

	@jsonMember(Asset)
	public displayAsset: Asset = new Asset();

	@jsonArrayMember(Translation)
	public translations: Translation[] = [];
}

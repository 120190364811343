import { Editor } from '@tiptap/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonToolbar, Input, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import TableConfig from './table-config';

import '../../../style/collaborative-editor.scss';

interface EditorToolbarProps {
	editor: Editor | null;
	linkClicked: { href: string } | null;
}

const EditorToolbar: React.FC<EditorToolbarProps> = ({ editor, linkClicked }) => {
	const [t] = useTranslation();
	const [isLinkPopoverOpened, setIsLinkPopoverOpened] = useState(false);
	const [href, setHref] = useState<string>('');
	const [prevHref, setPrevHref] = useState<string>('');
	const [isTablePopoverOpened, setIsTablePopoverOpened] = useState(false);

	const onLinkChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		setHref(event.target.value);
	};

	useEffect(() => {
		if (!linkClicked) {
			return;
		}

		setPrevHref(linkClicked.href);
		setHref(linkClicked.href);
		setIsLinkPopoverOpened(true);
	}, [linkClicked]);

	useEffect(() => {
		if (!isLinkPopoverOpened) {
			setPrevHref('');
			setHref('');
		} else if (!href) {
			const implicitHref = (editor && editor.getAttributes('link').href) || '';

			setHref(implicitHref);
		}
	}, [isLinkPopoverOpened, href, editor]);

	if (!editor) {
		return null;
	}

	const applyLink = () => {
		editor.chain().focus().extendMarkRange('link').setLink({ href, target: '_blank' }).run();

		setIsLinkPopoverOpened(false);
	};

	const removeLink = () => {
		editor.chain().focus().extendMarkRange('link').unsetLink().run();
		setIsLinkPopoverOpened(false);
	};

	const onTableConfigApply = (rows: number, cols: number) => {
		editor.chain().focus().insertTable({ rows, cols, withHeaderRow: false }).run();
		setIsTablePopoverOpened(false);
	};

	return (
		<div className='collaborative-editor-toolbar'>
			<ButtonToolbar>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive('bold') })}
					value='bold'
					aria-label='Bold'
					onClick={() => editor.chain().focus().toggleBold().run()}
					disabled={!editor.can().chain().focus().toggleBold().run()}
					aria-pressed={editor.isActive('bold')}
					title='Bold'
				>
					<i className='fa-bold fa' />
				</Button>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive('italic') })}
					value='italic'
					aria-label='Italic'
					onClick={() => editor.chain().focus().toggleItalic().run()}
					disabled={!editor.can().chain().focus().toggleItalic().run()}
					aria-pressed={editor.isActive('italic')}
					title='Italic'
				>
					<i className='fa-italic fa' />
				</Button>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive('underline') })}
					value='underline'
					aria-label='Underline'
					onClick={() => editor.chain().focus().toggleUnderline().run()}
					disabled={!editor.can().chain().focus().toggleUnderline().run()}
					aria-pressed={editor.isActive('underline')}
					title='Underline'
				>
					<i className='fa-underline fa' />
				</Button>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive('strike') })}
					value='strikethrough'
					aria-label='Strikethrough'
					onClick={() => editor.chain().focus().toggleStrike().run()}
					disabled={!editor.can().chain().focus().toggleStrike().run()}
					aria-pressed={editor.isActive('strike')}
					title='Strikethrough'
				>
					<i className='fa-strikethrough fa' />
				</Button>

				<div className='toolbar-separator' />

				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive({ textAlign: 'left' }) })}
					value='left'
					aria-label='Align left'
					onClick={() => editor.chain().focus().setTextAlign('left').run()}
					disabled={!editor.can().chain().focus().setTextAlign('left').run()}
					aria-pressed={editor.isActive({ textAlign: 'left' })}
					title='Align left'
				>
					<i className='fa-align-left fa' />
				</Button>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive({ textAlign: 'center' }) })}
					value='center'
					aria-label='Align center'
					onClick={() => editor.chain().focus().setTextAlign('center').run()}
					disabled={!editor.can().chain().focus().setTextAlign('center').run()}
					aria-pressed={editor.isActive({ textAlign: 'center' })}
					title='Align center'
				>
					<i className='fa-align-center fa' />
				</Button>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive({ textAlign: 'right' }) })}
					value='right'
					aria-label='Align right'
					onClick={() => editor.chain().focus().setTextAlign('right').run()}
					disabled={!editor.can().chain().focus().setTextAlign('right').run()}
					aria-pressed={editor.isActive({ textAlign: 'right' })}
					title='Align right'
				>
					<i className='fa-align-right fa' />
				</Button>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive({ textAlign: 'justify' }) })}
					value='justify'
					aria-label='Justify'
					onClick={() => editor.chain().focus().setTextAlign('justify').run()}
					disabled={!editor.can().chain().focus().setTextAlign('justify').run()}
					aria-pressed={editor.isActive({ textAlign: 'justify' })}
					title='Justify'
				>
					<i className='fa-align-justify fa' />
				</Button>

				<div className='toolbar-separator' />

				<Button
					id='toggleLinkButton'
					className={classNames('toolbar-toggle-item', { active: editor.isActive('link') })}
					value='link'
					aria-label='Link'
					disabled={!editor.can().chain().focus().toggleLink({ href: '' }).run()}
					title='Link'
					aria-pressed={isLinkPopoverOpened || editor.isActive('link')}
				>
					<i className='fa-link fa' />
				</Button>
				<Popover
					className='collaborative-editor-toolbar-popover'
					placement='bottom'
					isOpen={isLinkPopoverOpened}
					target='toggleLinkButton'
					toggle={() => {
						if (isLinkPopoverOpened) {
							setIsLinkPopoverOpened(false);
						} else if (!editor.isActive('link')) {
							setIsLinkPopoverOpened(!isLinkPopoverOpened);
						} else if (editor.isActive('link')) {
							editor.chain().focus().unsetLink().run();
						}
					}}
					trigger='legacy'
				>
					<PopoverHeader>{t('link')}</PopoverHeader>
					<PopoverBody>
						<fieldset className='Fieldset'>
							<label className='Label' htmlFor='linkUrlInput'>
								{t('enter_link')}
							</label>
							<Input onChange={onLinkChange} type='text' className='Input' id='linkUrlInput' value={href} />
						</fieldset>
						<Button
							className='link-button'
							color='primary'
							onClick={applyLink}
							disabled={!editor.can().chain().focus().setLink({ href: '' }).run() || !href}
						>
							{t('apply')}
						</Button>
						<Button className='link-button' onClick={removeLink} disabled={!editor.isActive('link') || !prevHref}>
							{t('remove')}
						</Button>
					</PopoverBody>
				</Popover>
				<Button
					id='toggleTableButton'
					className={classNames('toolbar-toggle-item', { active: editor.isActive('table') })}
					value='table'
					aria-label='Table'
					disabled={!editor.can().chain().focus().insertTable().run() || editor.isActive('table')}
					title='Table'
					aria-pressed={isTablePopoverOpened || editor.isActive('table')}
				>
					<i className='fa-table fa' />
				</Button>
				<Popover
					className='collaborative-editor-toolbar-popover'
					placement='bottom'
					isOpen={isTablePopoverOpened}
					target='toggleTableButton'
					toggle={() => setIsTablePopoverOpened(!isTablePopoverOpened)}
					trigger='legacy'
				>
					<PopoverHeader>{t('table')}</PopoverHeader>
					<PopoverBody>
						<TableConfig onSelect={onTableConfigApply} />
					</PopoverBody>
				</Popover>

				<div className='toolbar-separator' />

				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive('paragraph') })}
					value='paragraph'
					aria-label='Paragraph'
					onClick={() => editor.chain().focus().setParagraph().run()}
					aria-pressed={editor.isActive('paragraph')}
					title='Paragraph'
				>
					<i className='fa-paragraph fa' />
				</Button>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive('heading', { level: 3 }) })}
					value='heading'
					aria-label='Heading'
					onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
					aria-pressed={editor.isActive('heading', { level: 3 })}
					title='Heading'
				>
					<i className='fa-heading fa' />
				</Button>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive('bulletList') })}
					value='unordered list'
					aria-label='Unordered list'
					onClick={() => editor.chain().focus().toggleBulletList().run()}
					aria-pressed={editor.isActive('bulletList')}
					title='Unordered list'
				>
					<i className='fa-list-ul fa' />
				</Button>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive('orderedList') })}
					value='ordered list'
					aria-label='Ordered list'
					onClick={() => editor.chain().focus().toggleOrderedList().run()}
					aria-pressed={editor.isActive('orderedList')}
					title='Ordered list'
				>
					<i className='fa-list-ol fa' />
				</Button>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive('blockquote') })}
					value='blockquote'
					aria-label='Blockquote'
					onClick={() => editor.chain().focus().toggleBlockquote().run()}
					aria-pressed={editor.isActive('blockquote')}
					title='Blockquote'
				>
					<i className='fa-quote-left fa' />
				</Button>
				<Button
					className={classNames('toolbar-toggle-item', { active: editor.isActive('codeBlock') })}
					value='codeblock'
					aria-label='Codeblock'
					onClick={() => editor.chain().focus().toggleCodeBlock().run()}
					aria-pressed={editor.isActive('codeBlock')}
					title='Codeblock'
				>
					<i className='fa-code fa' />
				</Button>

				<div className='toolbar-separator' />

				<Button
					className='toolbar-toggle-item'
					value='undo'
					aria-label='Undo'
					onClick={() => editor.chain().focus().undo().run()}
					disabled={!editor.can().chain().focus().undo().run()}
					title='Undo'
				>
					<i className='fa-undo fa' />
				</Button>
				<Button
					className='toolbar-toggle-item'
					value='redo'
					aria-label='Redo'
					onClick={() => editor.chain().focus().redo().run()}
					disabled={!editor.can().chain().focus().redo().run()}
				>
					<i className='fa-redo fa' />
				</Button>
			</ButtonToolbar>
		</div>
	);
};

export default EditorToolbar;

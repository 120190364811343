import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';
import Image from '../../models/image/Image';
import {
	IMAGES_RECEIVED,
	PLAYING_SURFACE_IMAGES_RECEIVED,
	IMAGO_IMAGES_RECEIVED,
	IMAGE_PAGINATION_UPDATE,
	IMAGE_FILTERS_UPDATE,
	PLAYING_SURFACE_FILTERS_UPDATE,
	IMAGO_FILTERS_UPDATE,
} from '../action-creators/ImageActionCreator';
import AdvancedFilterModel from '../../views/Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';

interface InitialState {
	images: Image[];
	pagination: PaginationMeta;
	filters: AdvancedFilterModel;
	playingSurfaceFilters: AdvancedFilterModel;
	imagoFilters: AdvancedFilterModel;
}

const initialState: InitialState = {
	images: [],
	pagination: PaginationMeta.builder().build(),
	filters: AdvancedFilterModel.builder().build(),
	playingSurfaceFilters: AdvancedFilterModel.builder().build(),
	imagoFilters: AdvancedFilterModel.builder().build(),
};

function imageReducer(state: any = initialState, action: any) {
	if (action.type === IMAGES_RECEIVED) {
		return Object.assign({}, state, {
			images: action.payload.images,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === IMAGE_PAGINATION_UPDATE) {
		return Object.assign({}, state, {
			pagination: action.payload.pagination,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	if (action.type === PLAYING_SURFACE_IMAGES_RECEIVED) {
		return Object.assign({}, state, {
			playingSurfaceImages: action.payload.playingSurfaceImages,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === IMAGO_IMAGES_RECEIVED) {
		return Object.assign({}, state, {
			imagoImages: action.payload.imagoImages,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === IMAGE_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			filters: action.payload.filters,
		});
	}

	if (action.type === PLAYING_SURFACE_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			playingSurfaceFilters: action.payload.filters,
		});
	}

	if (action.type === IMAGO_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			imagoFilters: action.payload.filters,
		});
	}

	return state;
}

export default imageReducer;

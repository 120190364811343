import React from 'react';
import { Properties, State } from './properties/player-listing.properties';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Subscription } from 'rxjs';
import { actionService } from '../../../../../App';
import Project from '../../../../../models/project/Project';
import {
	returnObjectForPlayerListingRequest,
	returnObjectForPlayerSearch,
} from '../../../../../store/action-creators/player-action-creator';
import ContentTitleContainer from '../../../../Partials/BaseComponents/ListingComponent/subcomponents/ContentTitle/ContentTitleContainer';
import ListingComponent from '../../listing-container.component';
import { ContentTypes } from '../../../../../constants/content-types';
import * as uiActions from '../../../../../store/action-creators/UiActionCreator';
import PaginationMeta from '../../../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../../../store/action-creators/ImageActionCreator';
import { remmapContentFilters } from '../../../Articles/Helpers/ArticleHelper';
import { returnObjectForContentFiltersUpdate } from '../../../../../store/action-creators/content-resources';

class PlayerListingComponent extends React.Component<Properties, State> {
	playersSubscription: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			toggleModal: false,
			currentPage: '1',
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'PlayerListing');
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {});
		this.props.updateContentFilters(filters);
	}

	setCurrentPage = (page: string) => {
		this.props.analytics.sendPageView(
			this.props.location.pathname,
			`${parseInt(page) > 1 ? 'PlayerListing - Page ' + page : 'PlayerListing'}`,
		);
		this.setState({ ...this.state, currentPage: page });
	};

	initPageTitle() {
		document.title = this.props.t('players');
	}

	render() {
		const { t, analytics, toggleLoadingState, players } = this.props;

		return (
			<div className='animated fadeIn'>
				<div className='card'>
					<ContentTitleContainer contentType={ContentTypes.PLAYER} t={t} />
					<div className='card-body'>
						<ListingComponent
							t={t}
							content={players}
							contentType={ContentTypes.PLAYER}
							analytics={analytics}
							toggleLoadingState={toggleLoadingState}
							history={this.props.history}
							getContent={this.props.getPlayers}
							onPageChange={this.setCurrentPage}
							currentProject={this.props.currentProject}
							contentPagination={this.props.pagination}
							searchContent={this.props.searchPlayers}
							lang={this.props.lang}
							showSearchBar={true}
						/>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		players: state.player.players,
		currentProject: state.project.currentProject,
		pagination: state.player.pagination,
		isToggledModal: state.ui.isToggleModal,
		toggleLoadingState: state.ui.toggleLoadingState,
		lang: state.project.currentProject.language,
		searchText: state.player.searchFilter,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		dispatch: (action: any) => dispatch(action),
		getPlayers: (page: string, project: Project) => dispatch(returnObjectForPlayerListingRequest(page, project)),
		searchPlayers: (text: string, lang: string, type: string) => dispatch(returnObjectForPlayerSearch(text, lang, type)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PlayerListingComponent) as React.ComponentType;

export const CHARACTER_LIMIT = 500;

export const setInputBoxBorder = (summary: string | undefined) => {
	if (summary && summary.length > 500) {
		return 'border-danger';
	}
};

export const displayCharacterLimit = (summary: string | undefined) => {
	if (summary && summary.length > 0) {
		return `${summary.length}/${CHARACTER_LIMIT}`;
	} else {
		return `0/${CHARACTER_LIMIT}`;
	}
};

import React, { useEffect, useState } from 'react';
import BookmakerModel from './models/bookmaker.model';
import HttpService from '../../../../../../services/rest/HttpService';
import * as helpers from './helpers/bookmaker-select.helper';
import { Label } from 'reactstrap';
import Select from 'react-select';
import { customOption } from '../../../partials/shared/custom-select-option';
import { remapResponseToBookmakerModel } from './helpers/bookmaker-select.helper';
import ErrorHandler from '../error-handler/error-handler';

type Properties = {
	eventId: string | number | null;
	isMulti: boolean;
	showAllBookmakers: boolean;
	bookmakerSelectionEnabled: boolean;
	value?: any;
	valueList?: any;
	onSelect: any;
	t: any;
	isValid: boolean;
	matchContentData?: boolean;
	forFansUnited?: boolean;
	id: string;
};

export const BookmakerSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [bookmakerList, setBookmakerList] = useState<BookmakerModel[]>([]);
	const [initialEventId, setInitialEventId] = useState<any>(props.eventId ? props.eventId : null);

	useEffect(() => {
		if (props.eventId) {
			const shouldPreselectSingleSelect = !((props.value === null || props.value) && !props.matchContentData);
			const preselectBookmaker = props.valueList ? false : shouldPreselectSingleSelect;

			getOddsByMatchId(props.eventId, preselectBookmaker);
		}

		if (props.showAllBookmakers) {
			const preselectBookmaker = !props.value;

			getAllBookmakers(preselectBookmaker);
		}

		return () => setBookmakerList([]);
	}, []);

	useEffect(() => {
		if (props.eventId && props.eventId !== initialEventId) {
			resetSelectedBookmakerState();
			getOddsByMatchId(props.eventId, true);
		}
	}, [props.eventId]);

	const resetSelectedBookmakerState = () => {
		props.onSelect([]);
	};

	const getOddsByMatchId = (eventId: any, preselectBookmakerFlag: boolean) => {
		if (eventId) {
			HttpService.getEventOdds(eventId)
				.then((response: any) => {
					if (response && response.data) {
						const bookmakerList = remapResponseToBookmakerModel(response, true);

						preselectBookmakerFlag && preselectBookmakers(props.isMulti ? bookmakerList : bookmakerList[0]);
						updateBookmakerListState(bookmakerList);
					}
				})
				.catch((e: any) => e);
		}
	};

	const getAllBookmakers = (preselectBookmaker: boolean) => {
		HttpService.getAllBookmakers()
			.then((response: any) => {
				if (response) {
					const bookmakerList = remapResponseToBookmakerModel(response);

					preselectBookmaker && preselectBookmakers(props.isMulti ? bookmakerList : bookmakerList[0]);
					updateBookmakerListState(bookmakerList);
				}
			})
			.catch((e: any) => e);
	};

	const preselectBookmakers = (bookmakerList: BookmakerModel[]) => {
		props.onSelect(bookmakerList);
	};

	const updateBookmakerListState = (bookmakerList: BookmakerModel[]) => {
		setBookmakerList(bookmakerList);
	};

	const onSingleSelectChange = (selection: any) => {
		props.onSelect(selection && selection.data ? selection.data : null);
	};

	const onMultiSelectChange = (selections: any) => {
		props.onSelect(helpers.optionListToBookmakerList(selections));
	};

	const noOptions = (inputValue: any) => inputValue && props.t('no_options');

	if (!props.eventId && !props.bookmakerSelectionEnabled) {
		return null;
	}

	if (props.isMulti) {
		return (
			<>
				<div data-qa={props.id}>
					<Label htmlFor={props.id}>{props.t('select_bookmaker')}:</Label>
					<Select
						options={helpers.bookmakerListToOptionList(bookmakerList)}
						isMulti
						className='mb-2'
						placeholder={props.t('select')}
						noOptionsMessage={noOptions}
						value={helpers.bookmakerListToOptionList(props.valueList)}
						formatOptionLabel={customOption}
						onChange={onMultiSelectChange}
						id={props.id}
					/>
					{
						<ErrorHandler
							isValueSelected={!props.valueList || (props.valueList && props.valueList.length === 0)}
							arePropsValid={props.isValid}
							t={props.t}
							errorMessage='field_is_required'
						/>
					}
				</div>
			</>
		);
	}

	return (
		<>
			<div data-qa='main-bookmaker-select'>
				<Label>{props.t('select_main_bookmaker')}:</Label>
				<Select
					isClearable={props.value && props.value.id && props.value.id > 0}
					className='mb-2'
					options={helpers.bookmakerListToOptionList(bookmakerList)}
					value={props.value ? helpers.bookmakerToOption(props.value) : []}
					placeholder={props.t('select')}
					formatOptionLabel={customOption}
					noOptionsMessage={noOptions}
					onChange={onSingleSelectChange}
				/>
			</div>
		</>
	);
};

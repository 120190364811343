import React from 'react';
import DangerouslySetInner from '../../../../social-feed-selection-sidebar/subcomponents/dangerously-set-inner.component';
import { noPreviewImage } from '../../../../../../constants/resource-constants';
import ImagoImageModel from './models/imago-image.model';

type Properties = {
	t: any;
	isLoading: boolean;
	imagoImage: ImagoImageModel;
};

const ImagoImageView: React.FunctionComponent<Properties> = ({ isLoading, t, imagoImage }) => {
	if (imagoImage && imagoImage.image) {
		return (
			<div className={`${isLoading ? 'loading-overlay' : ''}`}>
				<div className='block-content'>
					<div className={`d-flex content-container`}>
						{imagoImage.image ? (
							<img className='content-image img-fluid mb-2 mb-md-0 rounded' alt={imagoImage.description} src={imagoImage.image} />
						) : (
							<img alt='' src={noPreviewImage} className='content-image img-fluid mb-2 mb-md-0 rounded' />
						)}
						{imagoImage.description && (
							<div className='gallery-title text-left'>
								<h6 className='m-0 p-0 col-lg-8 col-md-7 col-sm-6'>
									<DangerouslySetInner html={imagoImage.description} />
								</h6>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='position-relative text-center'>
			<img alt='' src={noPreviewImage} className='no-preview-image img-fluid mb-2 mb-md-0 rounded' />
		</div>
	);
};

export default ImagoImageView;

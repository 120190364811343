import React from 'react';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import ListingComponent from '../../Partials/BaseComponents/ListingComponent/ListingComponent';
import { Properties, State } from './Properties/BannerListingProperties';
import {
	BANNER_ENTITY_DELETE_SUCCESS,
	returnObjectForBannerEntityDelete,
	returnObjectForBannerListingRequest,
	returnObjectForBannerSearch,
} from '../../../store/action-creators/BannerActionCreators';
import { ContentTypes } from '../../../constants/content-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import ConditionalRenderWrapper from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { Subscription } from 'rxjs';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../store/action-creators/ImageActionCreator';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { returnObjectForWikiPaginationUpdate } from '../../../store/action-creators/WikiActionCreator';

class BannerListing extends React.Component<Properties, State> {
	bannerSubscription: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			bannerSearchInput: '',
			toggleModal: false,
			currentPage: '1',
		};
		this.registerActionEventListener();
	}

	getBannersListener = () => this.props.getBanners(this.state.currentPage, this.props.currentProject);

	registerActionEventListener() {
		window.addEventListener(BANNER_ENTITY_DELETE_SUCCESS, this.getBannersListener);
	}

	setCurrentPage(page: string) {
		this.props.analytics.sendPageView(
			this.props.location.pathname,
			`${parseInt(page) > 1 ? 'BannerListing - Page ' + page : 'BannerListing'}`,
		);
		this.setState({
			...this.state,
			currentPage: page,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'BannerListing');
		this.props.getBanners(this.state.currentPage, this.props.currentProject);
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateWikiPagesPagination(PaginationMeta.builder().withCurrentPage(1).build());

		const filters = remmapContentFilters({}, {}, {}, {}, {});
		this.props.updateContentFilters(filters);
	}

	componentWillUnmount() {
		window.removeEventListener(BANNER_ENTITY_DELETE_SUCCESS, this.getBannersListener);
	}

	initPageTitle() {
		document.title = this.props.t('banners');
	}

	render() {
		return (
			<ConditionalRenderWrapper expectedPermissions={['read_banners', 'write_banners', 'delete_banners']}>
				<ListingComponent
					contentType={ContentTypes.BANNER}
					getContent={this.props.getBanners}
					analytics={this.props.analytics}
					searchContent={this.props.searchBanners}
					currentProject={this.props.currentProject}
					onPageChange={this.setCurrentPage.bind(this)}
					history={this.props.history}
					onDeleteContent={(id: string) => {
						this.props.deleteBanner(id, this.props.currentProject);
					}}
					content={this.props.banners}
					contentPagination={this.props.pagination}
				/>
			</ConditionalRenderWrapper>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		banners: state.banner.banners,
		bannerDeleted: state.banner.bannerDeleted,
		currentProject: state.project.currentProject,
		pagination: state.banner.pagination,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getBanners: (page: string, project: Project, text: string) => {
			dispatch(returnObjectForBannerListingRequest(page, project, text));
		},
		searchBanners: (text: string, project: Project) => dispatch(returnObjectForBannerSearch(text, project)),
		deleteBanner: (id: string, project: Project) => dispatch(returnObjectForBannerEntityDelete(id, project)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateWikiPagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForWikiPaginationUpdate(pagination)),

		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(BannerListing) as React.ComponentType;

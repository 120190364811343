import { featuresService } from '../../../../../../App';
import EditorHelper from '../helpers/editor-block-edit.helper';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import AutoTagHttp from '../../../../../../services/rest/AutoTagHttp';
import EntityLinkingServiceHelper from './entity-linking-service.helper';

export default class EntityLinkingService {
	CHARS_TO_REPLACE_ENTITY_KEY = '<---->';

	httpTags = new AutoTagHttp();
	entityLinkingServiceHelper = new EntityLinkingServiceHelper();

	cancelAutoTagRequests = () => {
		this.httpTags.cancel();
	};

	addLinksToText = (tagToLink, text, existingTags, callback) => {
		text = this.addEntityLinkPerTag(text, tagToLink);
		existingTags = this.markTagForRemoval(tagToLink, existingTags);
		callback({ text, updatedTags: existingTags });
	};

	addLinksToTextMultiTags = (tagsToLink, text, existingTags, callback) => {
		tagsToLink.forEach((tag) => {
			text = this.addEntityLinkPerTag(text, tag);
			existingTags = this.markTagForRemoval(tag, existingTags);
		});
		callback({ text, updatedTags: existingTags });
	};

	markTagForRemoval = (tag, existingTags) => {
		existingTags = existingTags.map((existingTag) => {
			if (existingTag.text === tag.text) {
				existingTag['removed'] = true;
			}

			return existingTag;
		});

		return existingTags;
	};

	requestTags = (text, id) => {
		return new Promise((resolve, reject) => {
			this.httpTags
				.getTags(text, id)
				.then((response) => {
					const extractedTags = this.extractEntityTags(text, response, []);
					resolve({ auto: false, text, tags: extractedTags });
				})
				.then((e) => {
					reject(e);
				});
		});
	};

	link = (text, existingTags, config, id) => {
		const onlyFirstOccurrence = config ? config.onlyFirstOccurrence : false;
		const isManual = config ? config.isManual : false;

		return new Promise((resolve, reject) => {
			this.httpTags
				.getTags(text, id)
				.then((response) => {
					if (isManual) {
						try {
							const extractedTags = this.extractEntityTags(text, response, existingTags);
							resolve({ auto: !isManual, text, tags: extractedTags });
						} catch (e) {}
					} else {
						const linkedTextAndTags = this.addEntityLinksAndTags(text, response, existingTags, onlyFirstOccurrence);
						resolve(linkedTextAndTags);
					}
				})
				.catch((e) => {
					reject(e);
				});
		});
	};

	addEntityLinksAndTags = (text, entities, existingTags, firstOnly) => {
		Object.keys(entities).forEach((key) => {
			const entity = entities[key][0];
			existingTags = this.extractEntityTagsFromText(text, existingTags, entity, key);
		});
		text = this.addEntityLink(text, existingTags, firstOnly);

		return { auto: true, text, tags: existingTags };
	};

	extractEntityTags = (text, entities, existingTags) => {
		const entitiesCopy = JSON.parse(JSON.stringify(entities ? entities : []));
		let existingTagsCopy = JSON.parse(JSON.stringify(existingTags ? existingTags : []));
		Object.keys(entitiesCopy).forEach((key) => {
			const entity = entitiesCopy[key][0];
			existingTagsCopy = this.extractEntityTagsFromText(text, existingTagsCopy, entity, key);
		});

		return existingTagsCopy;
	};

	extractEntityTagsFromText = (text, existingTags, entity, key) => {
		existingTags = existingTags ? existingTags : [];

		if (text.includes(key)) {
			let filtered = existingTags.filter((tag) => tag.text === key);
			if (filtered.length < 1) {
				entity.text = key;
				existingTags.push(entity);
			}
		} else {
			const indexExistingTags = existingTags.findIndex((tag) => tag.text === key);

			if (indexExistingTags > -1) {
				existingTags.splice(indexExistingTags, 1);
			}
		}

		return existingTags;
	};

	addEntityLink = (text, tags, firstOnly) => {
		tags.forEach((tag, index) => {
			const editorHelper = new EditorHelper();

			if (editorHelper.autoTagFeatureLinkOptionsHasLinkType(tag.subtype)) {
				const linkOptions = featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING).linkOptions;
				const url = this.constructPlaceholderUrl(linkOptions, tag.id, tag.subtype);
				text = this.removeLinkedEntitiesInText(text, tag, url);
				text = this.markForIgnore(text, tag);
				text = this.linkEntity(text, tag, firstOnly, url);
				text = this.normalizeEntityText(text, tag);
			}
		});

		return text;
	};

	addEntityLinkPerTag = (text, tag) => {
		const editorHelper = new EditorHelper();
		if (editorHelper.autoTagFeatureLinkOptionsHasLinkType(tag.subtype)) {
			const linkOptions = featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING).linkOptions;
			const url = this.constructPlaceholderUrl(linkOptions, tag.id, tag.subtype);
			text = this.removeLinkedEntitiesInText(text, tag, url);
			text = this.markForIgnore(text, tag);
			text = this.linkEntityPerTag(text, tag, url);
			text = this.normalizeEntityText(text, tag);
		}

		return text;
	};

	constructPlaceholderUrl = (linkOptions, id, type) => {
		switch (type) {
			case 'player': {
				return linkOptions.player.replace(linkOptions.placeholder, id);
			}
			case 'team': {
				return linkOptions.team.replace(linkOptions.placeholder, id);
			}
			case 'coach': {
				return linkOptions.coach.replace(linkOptions.placeholder, id);
			}
			case 'venue': {
				return linkOptions.venue.replace(linkOptions.placeholder, id);
			}
			case 'tournament': {
				return linkOptions.tournament.replace(linkOptions.placeholder, id);
			}
		}
	};

	/**
	 * Normalizes the text by replacing previously linked entities in the text with the normal entity key.
	 *
	 * This function is normally used for cases where we want to remove previously linked entities in the text.
	 * This helps to avoid the creation of nested anchors (<a><a>Cristiano Ronaldo</a></a>) where entities were already linked
	 *
	 * Example: key = Cristiano Ronaldo
	 *          text = <a class="autolink-enabled" href="http://some-place-holder.com/3400">Cristiano Ronaldo</a>
	 *          <a class="autolink-enabled" href="http://some-place-holder.com/3400">Cristiano Ronaldo</a> will become
	 *          Cristiano Ronaldo
	 *
	 * @param text
	 * @param tags
	 * @returns {*}
	 */
	removeLinkedEntitiesInText = (text, tag, url) => {
		const regExp = `<a?[^>]+class="autolink-enabled" ?[^>]+>${tag.text}<\\/a>`;
		const replaceText = this.alterEntityText(tag.text);
		const attributes = this.extractAttributes(text, tag.text, false);
		const replaceLink = `<a class="autolink-enabled" href="${url}" data-source="${tag.source}" data-resource-type="${tag.entity_type}" ${
			attributes.target && attributes.target.length > 1 ? attributes.target : ''
		}>${replaceText}</a>`;

		if (new RegExp(regExp).test(text)) {
			text = text.replace(new RegExp(regExp, 'g'), replaceLink);
		}

		return text;
	};

	/**
	 * If the given linked entity in the text has a class 'autolink-disabled' this replaces the content of the <a> tag
	 * with CHARS_TO_REPLACE_ENTITY_KEY so that it cannot be linked again automatically.
	 *
	 * This function is normally used when the user has disabled automatic linking from the CKEditor and wants to add a custom link.
	 * When the automatic linking is disabled through the CKEditor this adds 'autolink-disabled' class to the given linked entity.
	 *
	 * @param text
	 * @param tags
	 * @returns {*}
	 */
	markForIgnore = (text, tag) => {
		const regExp = `<a?[^>]+class="?[^>]+autolink-disabled?[^>]+"?[^>]+>${tag.text}?[^>]+<\\/a>`;
		const replaceText = this.alterEntityText(tag.text);
		const attributes = this.extractAttributes(text, tag.text, true);
		const replaceLink = `<a class="autolink-disabled" href="${attributes.url}">${replaceText}</a>`;

		if (new RegExp(regExp).test(text)) {
			text = text.replace(new RegExp(regExp, 'g'), replaceLink);
		}

		return text;
	};

	linkEntity = (text, tag, firstOnly, url) => {
		let replaceText = '';

		if (firstOnly) {
			const linkedEntityRegEx = new RegExp(`<a?[^>]+class="autolink-enabled" ?[^>]+>${tag.text}<\\/a>`);
			const tempText = text.replace(new RegExp(this.CHARS_TO_REPLACE_ENTITY_KEY, 'g'), '');
			const textContainsLinkedEntity = linkedEntityRegEx.test(tempText);

			if (!textContainsLinkedEntity && isNaN(tag.text)) {
				const regex = new RegExp(this.regExpEscape(tag.text));
				replaceText = `<a class="autolink-enabled" data-resource-id="${tag.id}" data-resource-type="${tag.entity_type}" data-source="${tag.source}" href="${url}">${tag.text}</a>`;
				text = text.replace(regex, replaceText);
			}
		} else {
			if (isNaN(tag.text)) {
				const regex = new RegExp(this.regExpEscape(tag.text), 'g');
				replaceText = `<a class="autolink-enabled" data-resource-id="${tag.id}" data-resource-type="${tag.entity_type}" data-source="${tag.source}" href="${url}">${tag.text}</a>`;

				text = text.replace(regex, replaceText);
			}
		}
		return text;
	};

	linkEntityPerTag = (text, tag, url) => {
		let replaceText = '';

		if (tag.firstOnly) {
			const linkedEntityRegEx = new RegExp(`<a?[^>]+class="autolink-enabled" ?[^>]+>${tag.text}<\\/a>`);
			const tempText = text.replace(new RegExp(this.CHARS_TO_REPLACE_ENTITY_KEY, 'g'), '');
			const textContainsLinkedEntity = linkedEntityRegEx.test(tempText);

			if (!textContainsLinkedEntity && isNaN(tag.text)) {
				const regex = new RegExp(this.regExpEscape(tag.text));
				replaceText = `<a class="autolink-enabled" data-resource-id="${tag.id}" data-resource-type="${tag.entity_type}" data-source="${tag.source}" href="${url}">${tag.text}</a>`;
				text = text.replace(regex, replaceText);
			}
		} else {
			const regex = new RegExp(this.regExpEscape(tag.text), 'g');
			replaceText = `<a class="autolink-enabled" data-resource-id="${tag.id}" data-resource-type="${tag.entity_type}" data-source="${tag.source}" href="${url}">${tag.text}</a>`;
			text = text.replace(regex, replaceText);
		}

		return text;
	};

	regExpEscape = (text) => {
		return text.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
	};

	alterEntityText = (text) => {
		const textChars = text.split('').map((char) => {
			return char + this.CHARS_TO_REPLACE_ENTITY_KEY;
		});

		text = textChars.join('');

		return text;
	};

	normalizeEntityText = (text) => {
		if (text.includes(this.CHARS_TO_REPLACE_ENTITY_KEY)) {
			text = text.replace(new RegExp(this.CHARS_TO_REPLACE_ENTITY_KEY, 'g'), '');
		}

		return text;
	};

	extractAttributes = (text, entityName) => {
		let url = '';
		let target = '';
		let attrClass = '';
		const regExp = `<a?[^>]+class="([^>]+)" href="([^"]+)"?([^>]+)>${entityName}?[^>]+<\\/a>`;
		text.replace(new RegExp(regExp), function () {
			attrClass = arguments[1];
			url = arguments[2];
			target = arguments[3];
		});

		return { url, target, attrClass };
	};
}

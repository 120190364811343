import Project from '../../models/project/Project';
import ContentAttributes from '../../models/content-attributes/content-attributes-model';
import MainMedia from '../../models/mainmedia/MainMedia';
import PaginationMeta from '../../models/pagination/PaginationMeta';

export const IMAGES_REQUEST = '[ACTION] IMAGES_REQUEST';
export const IMAGES_RECEIVED = '[ACTION] IMAGES_RECEIVED';
export const IMAGES_REQUEST_FAILED = '[ACTION] IMAGES_REQUEST_FAILED';

export const IMAGE_SEARCH_REQUEST = '[ACTION] IMAGE_SEARCH_REQUEST';

export const PLAYING_SURFACE_IMAGES_REQUEST = '[ACTION] PLAYING_SURFACE_IMAGES_REQUEST';
export const PLAYING_SURFACE_IMAGES_RECEIVED = '[ACTION] PLAYING_SURFACE_IMAGES_RECEIVED';

export const PLAYING_SURFACE_IMAGE_SEARCH_REQUEST = '[ACTION] PLAYING_SURFACE_IMAGE_SEARCH_REQUEST';

export const IMAGO_IMAGES_REQUEST = '[ACTION] IMAGO_IMAGES_REQUEST';
export const IMAGO_IMAGES_RECEIVED = '[ACTION] IMAGO_IMAGES_RECEIVED';

export const IMAGO_IMAGE_SEARCH_REQUEST = '[ACTION] IMAGO_IMAGE_SEARCH_REQUEST';

export const PATCH_IMAGE_DESCRIPTION = '[ACTION] PATCH_IMAGE_DESCRIPTION';

export const IMAGE_UPLOAD_REQUEST = '[ACTION] IMAGE_UPLOAD_REQUEST';
export const IMAGE_UPLOAD_SUCCESS = '[ACTION] IMAGE_UPLOAD_SUCCESS';
export const IMAGE_UPLOAD_FAILED = '[ACTION] IMAGE_UPLOAD_FAILED';

export const IMAGE_PAGINATION_UPDATE = '[ACTION] IMAGE_PAGINATION_UPDATE';

export const IMAGE_FILTERS_UPDATE = '[ACTION] IMAGE_FILTERS_UPDATE';
export const PLAYING_SURFACE_FILTERS_UPDATE = '[ACTION] PLAYING_SURFACE_FILTERS_UPDATE';
export const IMAGO_FILTERS_UPDATE = '[ACTION] IMAGO_FILTERS_UPDATE';

export const IMAGE_MEDIA_ADD = '[ACTION] IMAGE_MEDIA_ADD';

export function imagesRequest(page: string, project: Project, text: string) {
	return {
		type: IMAGES_REQUEST,
		payload: { page, project, text },
	};
}

export function returnObjectForImagesPaginationUpdate(pagination: PaginationMeta) {
	return {
		type: IMAGE_PAGINATION_UPDATE,
		payload: { pagination },
	};
}

export function imagesReceived(payload: any) {
	return {
		type: IMAGES_RECEIVED,
		payload,
	};
}

export function imageSearchRequest(text: string, project: Project) {
	return {
		type: IMAGE_SEARCH_REQUEST,
		payload: { text, project },
	};
}

export function playingSurfaceImageRequest(page: string, text: string, origin: ContentAttributes[], project: Project) {
	return {
		type: PLAYING_SURFACE_IMAGES_REQUEST,
		payload: { page, text, origin, project },
	};
}

export function imagoImageRequest(page: string, text: string, origin: ContentAttributes[], project: Project) {
	return {
		type: IMAGO_IMAGES_REQUEST,
		payload: { page, text, origin, project },
	};
}

export function playingSurfaceImagesReceived(payload: any) {
	return {
		type: PLAYING_SURFACE_IMAGES_RECEIVED,
		payload,
	};
}

export function imagoImagesReceived(payload: any) {
	return {
		type: IMAGO_IMAGES_RECEIVED,
		payload,
	};
}

export function playingSurfaceImageSearchRequest(text: string, project: Project) {
	return {
		type: PLAYING_SURFACE_IMAGE_SEARCH_REQUEST,
		payload: { text, project },
	};
}

export function imagoImageSearchRequest(text: string, project: Project) {
	return {
		type: IMAGO_IMAGE_SEARCH_REQUEST,
		payload: { text, project },
	};
}

export function updateEditContentTempMediaDescription(media: MainMedia, project: Project) {
	return {
		type: PATCH_IMAGE_DESCRIPTION,
		payload: { media, project },
	};
}

export function returnObjectForImageUploadRequest() {
	return {
		type: IMAGE_UPLOAD_REQUEST,
	};
}

export function returnObjectForImageUploadSuccess() {
	return {
		type: IMAGE_UPLOAD_SUCCESS,
	};
}

export function returnObjectForImageUploadFailed() {
	return {
		type: IMAGE_UPLOAD_FAILED,
	};
}

export function returnObjectForImageFiltersUpdate(filters: any) {
	return {
		type: IMAGE_FILTERS_UPDATE,
		payload: { filters },
	};
}

export function returnObjectForPlayingSurfaceFiltersUpdate(filters: any) {
	return {
		type: PLAYING_SURFACE_FILTERS_UPDATE,
		payload: { filters },
	};
}

export function returnObjectForImagoImagesFiltersUpdate(filters: any) {
	return {
		type: IMAGO_FILTERS_UPDATE,
		payload: { filters },
	};
}

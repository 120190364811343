import React, { ChangeEvent } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import SortDirectionSelectComponent from '../../../partials/sort-direction-select/sort-direction-select.component';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import SportBookmakerSelect from '../../../../partials/sport-bookmaker-select/sport-bookmaker-select.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import CompetitionSelectComponent from '../../../../partials/competition-select/competition-select.component';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import BasketballTournamentProgrammeWidgetModel from '../models/basketball-tournament-programme.model';
import BasketballSeasonSelectComponent from '../../../../partials/season-select/basketball-season-select.component';
import BasketballSeasonModel from '../../../../../../../models/v2/basketball-season/entity/basketball-season.model';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import BasketballStageSelectComponent from '../../../../partials/stage-select/basketball-stage-select.component';
import BasketballRoundSelectComponent from '../../../partials/round-select/basketball-round-select.component';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';
import DisplayOddsComponent from '../../../../partials/bookmaker-select/display-odds.component';
import SortDirection from '../../../../../../../models/v2/sort-direction/sort-direction.model';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: BasketballTournamentProgrammeWidgetModel;
	isValid: boolean;
};

export default class BasketballTournamentProgrammeEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetBasketballTournamentProgramme,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;
		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const tournamentProgrammeModel = BasketballTournamentProgrammeWidgetModel.builder(this.state.widgetModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.widgetModel.bookmakers : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: tournamentProgrammeModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: BasketballTournamentProgrammeWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onCompetitionSelect = (competition: CompetitionModel) => {
		this.updateWidgetModelState(
			BasketballTournamentProgrammeWidgetModel.builder(this.state.widgetModel)
				.withComeptition(competition)
				.withSeason({} as BasketballSeasonModel)
				.withStage({} as BasketballStageModel)
				.withRounds([])
				.build(),
		);
	};

	onSeasonSelect = (season: BasketballSeasonModel) => {
		this.updateWidgetModelState(
			BasketballTournamentProgrammeWidgetModel.builder(this.state.widgetModel)
				.withSeason(season)
				.withStage({} as BasketballStageModel)
				.withRounds([])
				.build(),
		);
	};

	onStageSelect = (stage: BasketballStageModel) => {
		this.updateWidgetModelState(
			BasketballTournamentProgrammeWidgetModel.builder(this.state.widgetModel).withStage(stage).withRounds([]).build(),
		);
	};

	onRoundSelect = (rounds: BasketballRoundModel[]) => {
		this.updateWidgetModelState(BasketballTournamentProgrammeWidgetModel.builder(this.state.widgetModel).withRounds(rounds).build());
	};

	onSortDirectionSelect = (sortDirection: SortDirection) => {
		this.updateWidgetModelState(
			BasketballTournamentProgrammeWidgetModel.builder(this.state.widgetModel)
				.withSortDIrection(sortDirection ? sortDirection.value : '')
				.build(),
		);
	};

	onDisplayOddsChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(
			BasketballTournamentProgrammeWidgetModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build(),
		);
	};

	onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		this.updateWidgetModelState(BasketballTournamentProgrammeWidgetModel.builder(this.state.widgetModel).withBookmakers(bookmakers).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(
			refreshTimeData,
			BasketballTournamentProgrammeWidgetModel,
			this.state.widgetModel,
			this.updateWidgetModelState,
		);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t, contentLanguage } = this.props;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);

		return (
			<>
				<Row data-qa='basketball-tournament-programme-edit'>
					<Col>
						<FormGroup>
							<CompetitionSelectComponent
								onCompetitionSelect={this.onCompetitionSelect}
								t={t}
								selectedCompetition={
									widgetModel.competition && widgetModel.competition.id && widgetModel.competition.id.length > 0
										? widgetModel.competition
										: ({} as CompetitionModel)
								}
								isValid={isValid}
								language={contentLanguage}
								isRequired
								isClearable
								isBlocky
								sport={SportTypes.BASKETBALL}
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.COMPETITION_SELECT}`}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<BasketballSeasonSelectComponent
								onSeasonSelect={this.onSeasonSelect}
								t={t}
								selectedSeason={
									widgetModel.season && widgetModel.season.id && widgetModel.season.id.length > 0
										? widgetModel.season
										: ({} as BasketballSeasonModel)
								}
								isValid={isValid}
								blockData={widgetModel}
								language={contentLanguage}
								isClearable
								isBlocky
								sport={SportTypes.BASKETBALL}
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
								preselectSeason
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<BasketballStageSelectComponent
								onStageSelect={this.onStageSelect}
								t={t}
								selectedStage={
									widgetModel.stage && widgetModel.stage.id && widgetModel.stage.id.length > 0 ? widgetModel.stage : ({} as BasketballStageModel)
								}
								isValid={isValid}
								blockData={widgetModel}
								language={contentLanguage}
								isClearable
								isBlocky
								sport={SportTypes.BASKETBALL}
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.STAGE_SELECT}`}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<BasketballRoundSelectComponent
								isMulti={true}
								isBlocky
								isValid={isValid}
								preselectRound={false}
								selectedRounds={widgetModel.rounds}
								stageRounds={widgetModel.stage ? widgetModel.stage.rounds : []}
								onRoundSelect={(round: BasketballRoundModel[]) => this.onRoundSelect(round)}
								t={this.props.t}
								isClearable
								isRequired={false}
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.ROUND_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<hr />
				<Row>
					<Col>
						<Label>
							<strong>{t('filters')}</strong>
						</Label>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<SortDirectionSelectComponent
								selectedSortDirection={widgetModel.sortDirection ? widgetModel.sortDirection : ''}
								t={t}
								onSortDirectionSelect={this.onSortDirectionSelect}
								withLabel
								isClearable
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.SORT_DIRECTION_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<DisplayOddsComponent
					t={t}
					block={this.props.block}
					displayOddsChecked={widgetModel.displayOdds ? widgetModel.displayOdds : false}
					onDisplayOddsChange={this.onDisplayOddsChange}
					id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
				/>
				{widgetModel && widgetModel.displayOdds && bookmakerSelectionEnabled && (
					<Row className='mb-2'>
						<Col>
							<SportBookmakerSelect
								isValid={this.state.isValid}
								showAllBookmakers={false}
								bookmakerSelectionEnabled={bookmakerSelectionEnabled}
								t={this.props.t}
								valueList={widgetModel.bookmakers ? widgetModel.bookmakers : null}
								onSelect={this.onBookmakerChange}
								contentLanguage={this.props.contentLanguage}
								sportType={SportTypes.BASKETBALL}
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
							/>
						</Col>
					</Row>
				)}
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</>
		);
	}
}

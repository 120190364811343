import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert, Button, Col, Label, Row } from 'reactstrap';
import { Properties, State } from './properties/ListsContentProps';
import { SearchContentModel } from '../../../Partials/Shared/search-content-by-type/models/search-content.model';
import {
	LIST_ENTITY_RECEIVED,
	LIST_ENTITY_REQUEST,
	LIST_ENTITY_UPDATE,
	LIST_ENTITY_UPDATE_FAILED,
	LIST_ENTITY_UPDATE_SUCCESS,
	returnObjectForListEntityRequest,
	returnObjectForListEntityUpdate,
} from '../../../../store/action-creators/ListActionCreators';
import Project from '../../../../models/project/Project';
import List from '../../../../models/list/List';
import ListContentSortableList from './subcomponents/ListContentSortableList';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { SortEnd } from 'react-sortable-hoc';
import { actionService } from '../../../../App';
import { Subscription } from 'rxjs';
import ListItem from '../../../../models/list/list-item/ListItem';
import ListItemMeta from '../../../../models/list/list-item/list-item-meta/ListItemMeta';
import BlockableButtonWrapper from '../../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import {
	addToListIfNotExists,
	extractSearchOptionId,
	isNumberofItemsValid,
	lockPositionsAlert,
	searchContentModelToListItem,
	validateLockPositions,
} from './list-content.helper';
import SearchContentByTypeContainer from '../../../Partials/Shared/search-content-by-type/search-content-by-type-container';
import { moveArrayItemWithSkips } from '../helpers/list.helper';
import HttpService from '../../../../services/rest/HttpService';
import SearchContentByTagsOrSportsConnectionsContainer from '../../../Partials/Shared/search-content-by-type/search-content-by-tags-or-sports-connections.container';
import { ContentTypes, ListContentTypes } from '../../../../constants/content-types';

const SortableList = ReactSortableHOC.SortableContainer(ListContentSortableList);

class ListsContent extends React.Component<Properties, State> {
	listEntitySubscription: Subscription = {} as Subscription;
	actionServiceSubscription: Subscription = {} as Subscription;
	private interval: number = -1;

	constructor(props: Properties) {
		super(props);
		this.state = {
			list: {} as List,
			isContentLoading: false,
			toggleErrorMessage: false,
		};

		this.registerActionEventListener();
	}

	registerActionEventListener() {
		this.actionServiceSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === LIST_ENTITY_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === LIST_ENTITY_RECEIVED) {
				this.toggleContentLoadingState(false);
			}
		});
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	componentWillUnmount(): void {
		this.listEntitySubscription.unsubscribe();
		this.actionServiceSubscription.unsubscribe();
		window.clearInterval(this.interval);
	}

	componentDidMount() {
		this.listEntitySubscription = actionService.onListEntityReceived().subscribe((list: List) => {
			this.updateList(list);
		});
		this.props.getList(this.props.match.params.id, this.props.project);
	}

	componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.toggleErrorMessage !== this.state.toggleErrorMessage) {
			if (this.interval > -1) {
				window.clearInterval(this.interval);
			}
			this.interval = window.setInterval(() => this.toggleFirstLockPositionError(false), 10000);
		}
	}

	onListItemSortEnd = (sortEnd: SortEnd) => {
		const { oldIndex, newIndex } = sortEnd;
		const { list } = this.state;
		const remappedItems = moveArrayItemWithSkips([...list.items], list.lockedPositions, oldIndex, newIndex);
		this.updateList(List.builder(list).withItems(remappedItems).build());
	};

	async getContentItemData(id: string, type: string) {
		switch (type) {
			case ContentTypes.ARTICLE:
				return HttpService.getArticle(id)
					.then((response: any) => {
						return response.data.data.authors;
					})
					.catch((e: any) => console.error(e));
			case ContentTypes.VIDEO:
				return HttpService.getVideo(id)
					.then((response: any) => {
						return response.data.data.authors;
					})
					.catch((e: any) => console.error(e));
			case ContentTypes.GALLERY:
				return HttpService.getGallery(id)
					.then((response: any) => {
						return response.data.data.authors;
					})
					.catch((e: any) => console.error(e));
			case ContentTypes.LIVE_BLOG:
				return HttpService.getLiveBlogById(id)
					.then((response: any) => {
						return response.data && response.data.authors && response.data.authors;
					})
					.catch((e: any) => console.error(e));
		}
	}

	async onListItemAdd(model: SearchContentModel, addItemToTop?: boolean) {
		let items = this.state.list.items;
		let itemId = extractSearchOptionId(model);
		if (!window.location.hash.includes('/smp/tags-and-sports-connections-lists/edit-content/')) {
			model.data['authors'] = await this.getContentItemData(itemId, model.type);
		}
		items = addToListIfNotExists(searchContentModelToListItem(model), items, addItemToTop);
		const list = List.builder(this.state.list).withItems(items).build();
		this.updateList(list);
	}

	onListItemRemove(itemIndex: number) {
		let items = this.state.list.items;
		items.splice(itemIndex, 1);
		const list = List.builder(this.state.list).withItems(items).build();
		this.updateList(list);
	}

	onListItemMetaChange(item: ListItemMeta, index: number) {
		let items = this.state.list.items;

		const listItem = ListItem.builder(items[index]).withMeta(item).build();
		items.splice(index, 1, listItem);

		const list = List.builder(this.state.list).withItems(items).build();
		this.updateList(list);
	}

	updateList(list: List) {
		this.setState({ ...this.state, list });
	}

	onSubmit = () => {
		this.props.updateList(this.state.list, this.props.project);
	};

	saveButton = (place: string, isNumberOfItemsValid: boolean, areValidLockPositions: boolean) => {
		return (
			<BlockableButtonWrapper
				blockOnActions={[LIST_ENTITY_UPDATE]}
				releaseOnActions={[LIST_ENTITY_UPDATE_FAILED, LIST_ENTITY_UPDATE_SUCCESS]}
				blockOverride={!isNumberOfItemsValid || areValidLockPositions}
			>
				<Button
					size={place === 'top' ? 'sm' : 'md'}
					color={'primary'}
					className={'text-uppercase font-weight-bold mr-2'}
					disabled={!isNumberOfItemsValid || areValidLockPositions}
					onClick={this.onSubmit}
				>
					<i className='fa fa-floppy-o' /> {this.props.t('save_list')}
				</Button>
			</BlockableButtonWrapper>
		);
	};

	toggleFirstLockPositionError = (toggle: boolean) => {
		this.setState({ ...this.state, toggleErrorMessage: toggle });
	};

	onListItemAddAtLastPosition = (model: SearchContentModel, addItemToTop?: boolean) => {
		this.onListItemAdd(model, addItemToTop);
		this.toggleFirstLockPositionError(false);
	};

	render() {
		const { project, t, sports } = this.props;
		const { list, toggleErrorMessage } = this.state;
		const isNumberOfItemsValid = isNumberofItemsValid(list);
		const areValidLockPositions = validateLockPositions(list);
		const minItems = list.configuration ? list.configuration.minItems : null;
		const maxItems = list.configuration ? list.configuration.maxItems : null;
		return (
			<div className='animated fadeIn'>
				<div className='card'>
					<div className='card-header d-flex align-items-center'>
						<div className='mr-auto'>
							{t('list_content')}
							{list && list.title ? `: ${list.title}` : ''}
						</div>
						{this.saveButton('top', isNumberOfItemsValid, areValidLockPositions)}
					</div>
					<div className='card-body'>
						<div className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
							<Row>
								<Col>
									<Label className={'font-weight-bold'}>{t('selected_content')}:</Label>
									<SortableList
										list={list}
										t={t}
										useDragHandle
										onMetaChange={(meta: ListItemMeta, index: number) => {
											this.onListItemMetaChange(meta, index);
										}}
										project={project}
										onDelete={this.onListItemRemove.bind(this)}
										onSortEnd={this.onListItemSortEnd.bind(this)}
										rightSidebarList={false}
									/>
									<Alert color={'danger'} isOpen={!isNumberOfItemsValid}>
										{t('min_max_list_items_error', {
											minItems: minItems ? minItems : t('no_limit'),
											maxItems: maxItems ? maxItems : t('no_limit'),
										})}
									</Alert>
									<Alert color={'danger'} isOpen={areValidLockPositions}>
										{t('locked_list_items_error', {
											lockPosition: lockPositionsAlert(list),
										})}
									</Alert>
									<Alert color={'danger'} isOpen={toggleErrorMessage}>
										{t('first_item_is_locked')}
									</Alert>
								</Col>
							</Row>
							{list && list.items && list.items.length > 0 && (
								<Row className='mb-4'>
									<Col>{this.saveButton('bottom', isNumberOfItemsValid, areValidLockPositions)}</Col>
								</Row>
							)}
							<Row>
								<Col>
									<Label className={'font-weight-bold'}>{t('available_content')}:</Label>
									{list.id ? (
										list.contentType === ListContentTypes.EDITORIAL || list.contentType === ListContentTypes.SCHEDULED ? (
											<SearchContentByTypeContainer
												list={list}
												listContent
												t={t}
												currentProject={project}
												contentLanguage={list.language}
												categoryIdFilter={
													list.configuration && list.configuration.category && list.configuration.category.id ? list.configuration.category.id : ''
												}
												sportsFilter={list.configuration.sports ? list.configuration.sports : []}
												onRelatedContentSelect={(model: any, addItemToTop?: boolean) => {
													this.onListItemAddAtLastPosition(model, addItemToTop);
												}}
												toggleFirstLockPositionError={this.toggleFirstLockPositionError}
												showAddToTopToggle={true}
												sports={sports}
											/>
										) : (
											<SearchContentByTagsOrSportsConnectionsContainer
												list={list}
												listContent
												t={t}
												currentProject={project}
												onRelatedContentSelect={(model: any, addItemToTop?: boolean) => {
													this.onListItemAddAtLastPosition(model, addItemToTop);
												}}
												toggleFirstLockPositionError={this.toggleFirstLockPositionError}
												showAddToTopToggle={true}
												contentLanguage={list.language}
												sports={sports}
											/>
										)
									) : null}
								</Col>
							</Row>
							<Row className='my-2'>
								<Col>{this.saveButton('bottom', isNumberOfItemsValid, areValidLockPositions)}</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		project: state.project.currentProject,
		list: state.list.list,
		sports: state.sports.sportsTypes,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getList: (id: string, project: Project) => dispatch(returnObjectForListEntityRequest(id, project)),
		updateList: (list: List, project: Project) => dispatch(returnObjectForListEntityUpdate(list, project)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withRouter)(ListsContent) as React.ComponentType;

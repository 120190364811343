import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import ErrorHandler from '../error/error-handler-component';
import { customOption } from '../shared/custom-select-option';
import * as helpers from './helpers/sport-bookmakers-select.helper';
import { TypedJSON } from 'typedjson';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import SportsHttpService from '../../../../../services/rest/sports-http-service';
import SportBookmakerModel from '../../../../../models/sport-odds/sport-bookmaker.model';
import OddsModel from '../../../../../models/sport-odds/odds.model';

type Properties = {
	eventId: string | null;
	isMulti?: boolean;
	showAllBookmakers: boolean;
	bookmakerSelectionEnabled: boolean;
	value?: any;
	valueList?: any;
	onSelect: any;
	t: any;
	isValid: boolean;
	matchContentData?: boolean;
	onOddsListUpdate: (oddsList: OddsModel[]) => any;
	contentLanguage: string;
	sport: string;
	id: string;
};

type State = {
	bookmakerList: SportBookmakerModel[];
};

class SportBookmakerSelectByEventId extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			bookmakerList: [],
		};
	}

	componentDidMount() {
		if (this.props.eventId) {
			const preselectBookmaker = this.props.valueList ? false : true;
			this.getOddsByMatchId(this.props.eventId, preselectBookmaker);
		}
	}

	componentDidUpdate(prevProps: any) {
		if (this.props.eventId !== prevProps.eventId) {
			this.resetSelectedBookmakerState();
			this.getOddsByMatchId(this.props.eventId, true);
		}
	}

	resetSelectedBookmakerState = () => this.props.onSelect([]);

	getOddsByMatchId = (eventId: any, preselectBookmaker: boolean) => {
		const featureConfig = featuresService.getFeatureConfig(this.props.sport);
		if (eventId) {
			SportsHttpService.getEventById(eventId, this.props.contentLanguage, this.props.sport, featureConfig.oddClient)
				.then((response: any) => {
					if (response && response.data) {
						if (response.data.odds && response.data.odds.length > 0) {
							const bookmakerList = response.data.odds.map((odd: any) => {
								const serializer = new TypedJSON(SportBookmakerModel);
								return serializer.parse(odd.bookmaker) as SportBookmakerModel;
							});
							const oddsList = response.data.odds.map((odd: any) => {
								const serializer = new TypedJSON(OddsModel);
								return serializer.parse(odd) as OddsModel;
							});
							this.props.onOddsListUpdate(oddsList);
							preselectBookmaker && this.preselectBookmakers([bookmakerList[0]]);
							this.updateBookmakerListState(bookmakerList);
						} else {
							this.updateBookmakerListState([]);
						}
					}
				})
				.catch((e: any) => e);
		}
	};

	preselectBookmakers = (bookmakerList: SportBookmakerModel[]) => {
		this.props.onSelect(bookmakerList);
	};

	updateBookmakerListState = (bookmakerList: SportBookmakerModel[]) => {
		this.setState({ ...this.state, bookmakerList });
	};

	onSingleSelectChange = (selection: any) => {
		this.props.onSelect(selection && selection.data ? [selection.data] : null);
	};

	onMultiSelectChange = (selections: any) => {
		this.props.onSelect(helpers.optionListToBookmakerList(selections));
	};

	noOptions = (inputValue: any) => inputValue && this.props.t('no_options');

	render() {
		const { eventId, isMulti, valueList, bookmakerSelectionEnabled } = this.props;

		if (!eventId && !bookmakerSelectionEnabled) {
			return null;
		}

		if (isMulti) {
			return (
				<>
					<Label htmlFor={this.props.id}>{this.props.t('select_bookmaker')}:</Label>
					<Select
						id={this.props.id}
						options={helpers.bookmakerListToOptionList(this.state.bookmakerList)}
						isMulti
						className='mb-2'
						placeholder={this.props.t('select')}
						noOptionsMessage={this.noOptions}
						value={helpers.bookmakerListToOptionList(valueList)}
						formatOptionLabel={customOption}
						onChange={this.onMultiSelectChange}
					/>
					{!this.props.isValid && (!valueList || (valueList && valueList.length === 0)) && (
						<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
					)}
				</>
			);
		}

		return (
			<>
				<Label htmlFor={this.props.id}>{this.props.t('select_bookmaker')}:</Label>
				<Select
					id={this.props.id}
					isClearable={valueList && valueList.length === 0 ? false : true}
					className='mb-2'
					options={helpers.bookmakerListToOptionList(this.state.bookmakerList)}
					value={valueList ? helpers.bookmakerToOption(valueList[0]) : []}
					placeholder={this.props.t('select')}
					formatOptionLabel={customOption}
					noOptionsMessage={this.noOptions}
					onChange={this.onSingleSelectChange}
				/>
				{!this.props.isValid && (!valueList || (valueList && valueList.length === 0)) && (
					<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
				)}
			</>
		);
	}
}

export default SportBookmakerSelectByEventId;

import React from 'react';
import { connect } from 'react-redux';
import { Properties, State } from './properties/ImageEditModalProps';
import HttpService from '../../../../../services/rest/HttpService';
import Image from '../../../../../models/image/Image';
import ModelMapper from '../../../../../models/ModelMapper';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import EnumItem from '../../../../../models/enum/EnumItem';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { css } from '@emotion/core';
import FootballConnectionsContainer from '../../../../Partials/Sidebar/tags/subcomponents/football-connections/football-connections-container';
import Related from '../../../../../models/related/Related';
import ErrorAsync from '../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import { actionService, featuresService, multiLingualService, sportsConnectionsService } from '../../../../../App';
import {
	extractDefaultOrigin,
	extractImageAuthorsIds,
	extractNonDefaultOrigin,
	extractPathNameFromImage,
	imageOwnersToSelectOptions,
	ownerToSelectOption,
} from '../helpers/image-cells.helpers';
import TypesSelect from '../../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/type-select/type-select';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import AuthorSelectMultiContainer from '../../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/AuthorSelectMulti/AuthorSelectMultiContainer';
import Author from '../../../../../models/author/Author';
import WatermarkService from '../../../../../services/watermark-service/WatermarkService';
import GalleryItem from '../../../../../models/gallery/item/GalleryItem';
import Watermark from '../../../../../models/watermarks/Watermark';
import { DebounceInput } from 'react-debounce-input';
import { SportsTypeSelect } from '../../../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-type-select.component';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';
import { SportsConnectionsContainer } from '../../../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections-container';
import {
	extractSportsRelatedFromResponse,
	removeDuplicateItems,
} from '../../../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';

class ImageEditModal extends React.Component<Properties, State> {
	watermarkService = new WatermarkService();

	constructor(props: Properties) {
		super(props);

		this.state = {
			image: {} as Image,
			imageRelated: [],
			imageSportsRelated: [],
			isImageUpdating: false,
			removeWatermarkChecked: false,
			addWatermarkChecked: false,
			defaultWatermark: Watermark.builder().build(),
			ownersNotValidForWater: null,
			contentLanguage: multiLingualService.checkIfProjectIsMultiLingual(this.props.currentProject.languages)
				? this.props.currentProject.languages.defaultLanguageCode.languageCode
				: this.props.currentProject.language,
			sportsType: {} as SportsTypesModel,
		};
	}

	componentDidMount() {
		this.setState({
			...this.state,
			ownersNotValidForWater: extractNonDefaultOrigin(this.props.owners),
			sportsType: featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL, FeatureTypes.FOOTBALL_CONNECTIONS])
				? this.props.sports[0]
				: ({} as SportsTypesModel),
		});
	}

	toggleIsImageUpdatingState(updating: boolean) {
		this.setState({
			...this.state,
			isImageUpdating: updating,
		});
	}

	initWaterMarkService() {
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK)) {
			this.watermarkService = new WatermarkService();
			return true;
		} else return false;
	}

	imageGetRequest() {
		const { imageId, currentProject } = this.props;

		if (imageId && imageId.length > 0) {
			const headers = { Project: currentProject.domain };
			HttpService.get(`/images/${imageId}`, null, headers)
				.then((response: any) => {
					const image = ModelMapper.remapImageFromResponse(response.data.data);
					this.updateImageState(image);
					this.requestImageRelated(image.id);
				})
				.catch((error: any) => {});
		}
	}

	//TODO remove and unify related filtration logic when the football data is migrated to sports-search-api
	requestImageRelated(id: string) {
		const { currentProject, sports } = this.props;
		const headers = { Project: currentProject.domain };
		HttpService.get(`/v2/images/${id}/related`, null, headers)
			.then((response: any) => {
				let allRelated = [];
				const relatedFootball = ModelMapper.extractFootballRelatedFromResponse(response.data.data);
				allRelated.push(...relatedFootball);
				const relatedSports = extractSportsRelatedFromResponse(response.data.data, sports);
				allRelated.push(...relatedSports);
				this.updateImageRelatedState(relatedFootball);
				this.updateImageSportsRelatedState(relatedSports);
			})
			.catch((error: any) => {});
	}

	updateImageRelated(id: string) {
		const { currentProject } = this.props;
		const { imageRelated, imageSportsRelated } = this.state;
		const headers = { Project: currentProject.domain };

		const allRelated = imageRelated.concat(imageSportsRelated);

		HttpService.post(`/images/${id}/related`, allRelated, headers)
			.then((response: any) => {})
			.catch((error: any) => {
				actionService.emitError(error);
			});
	}

	//TODO: REFACTOR THE FUNCTION BELOW
	imagePatchRequest() {
		const { imageId, currentProject, t, onClose } = this.props;
		let { image, removeWatermarkChecked, addWatermarkChecked } = this.state;
		let imageRequestJson = {};

		if (image.description !== null && image.description !== undefined) {
			imageRequestJson['description'] = image.description;
		}

		if (image.alt !== null && image.alt !== undefined) {
			imageRequestJson['alt'] = image.alt;
		}

		if (image.caption !== null && image.caption !== undefined) {
			imageRequestJson['caption'] = image.caption;
		}

		if (image.authors !== null && image.authors !== undefined) {
			const imageAuthors = extractImageAuthorsIds(image.authors);

			imageRequestJson['authors'] = imageAuthors;
		}

		if (removeWatermarkChecked && image.generic && image.generic.watermark !== null && image.generic.watermark !== undefined) {
			let imagePath = extractPathNameFromImage(image);
			if (imagePath && imagePath.length > 0 && image.generic && image.generic.watermark) {
				this.watermarkService.deleteWatermarkSingleImage(image.path, image.id).then((resp: any) => {
					let newItems =
						this.props.items &&
						this.props.items.map((item: GalleryItem) => {
							if (item.id === image.id) {
								return GalleryItem.builder()
									.withType('image')
									.withComment(item.comment ? item.comment : '')
									.withDescription(item.description ? item.description : '')
									.withAddDescriptionToMedia(item.addDescriptionToMedia ? item.addDescriptionToMedia : false)
									.withId(resp.data.data.id)
									.withGeneric(resp.data.data.generic)
									.withData(resp.data.data)
									.build();
							} else {
								return item;
							}
						});
					this.props.items && this.props.onItemsChange(newItems);
					this.props.onClose(true, false, true);
				});
			}
		}

		if (
			addWatermarkChecked &&
			(!image.generic || image.generic === null || image.generic.watermark === undefined || !image.generic.watermark)
		) {
			let imagePath = extractPathNameFromImage(image);

			if (imagePath && imagePath.length > 0) {
				this.watermarkService.addWatermarkSingleImage(image.path, image.id, this.state.defaultWatermark.id).then((resp: any) => {
					let newItems =
						this.props.items &&
						this.props.items.map((item: GalleryItem) => {
							if (item.id === image.id) {
								return GalleryItem.builder()
									.withType('image')
									.withComment(item.comment ? item.comment : '')
									.withDescription(item.description ? item.description : '')
									.withAddDescriptionToMedia(item.addDescriptionToMedia ? item.addDescriptionToMedia : false)
									.withId(resp.data.data.id)
									.withGeneric(resp.data.data.generic)
									.withData(resp.data.data)
									.build();
							} else {
								return item;
							}
						});
					this.props.items && this.props.onItemsChange(newItems);
					this.props.onClose(true, true, false);
				});
			}
		}

		imageRequestJson['origin_id'] = image.owner.id;
		imageRequestJson['type'] = image.type;

		const headers = { Project: currentProject.domain };
		if (imageId) {
			HttpService.patch(`/images/${imageId}`, imageRequestJson, headers)
				.then((response: any) => {
					toast.success(t('image_update_success'));
					onClose(true);
					const image = ModelMapper.remapImageFromResponse(response.data.data);
					this.toggleIsImageUpdatingState(false);
					this.updateImageState(image);
					this.updateImageRelated(imageId);
					let newItems =
						this.props.items &&
						this.props.items.map((item: GalleryItem) => {
							if (item.id === image.id) {
								return GalleryItem.builder()
									.withType('image')
									.withComment(item.comment ? item.comment : '')
									.withDescription(item.description ? item.description : '')
									.withAddDescriptionToMedia(item.addDescriptionToMedia ? item.addDescriptionToMedia : false)
									.withId(response.data.data.id)
									.withGeneric(response.data.data.generic)
									.withData(response.data.data)
									.build();
							} else {
								return item;
							}
						});
					this.props.items && this.props.onItemsChange(newItems);
				})
				.catch((error: any) => {
					this.toggleIsImageUpdatingState(false);
					actionService.emitError(error);
					toast.error(t('image_update_failed'));
				});
		} else {
			this.toggleIsImageUpdatingState(false);
		}

		this.setState({ removeWatermarkChecked: false, addWatermarkChecked: false });
	}

	updateImageRelatedState = (related: Related[]) => {
		this.setState({
			...this.state,
			imageRelated: related,
		});
	};

	updateImageSportsRelatedState = (related: Related[]) => {
		let { imageSportsRelated, sportsType } = this.state;

		const tempData = imageSportsRelated.filter((element: Related) => element.data.sport !== sportsType.sport);

		const relatedData = related.concat(tempData);
		this.setState({
			...this.state,
			imageSportsRelated: removeDuplicateItems(relatedData),
		});
	};

	onDescriptionChange(event: any) {
		let { image } = this.state;
		const description = event.target.value;

		image = Image.builder(image).withDescription(description).build();
		this.updateImageState(image);
	}

	onAltChange(event: any) {
		let { image } = this.state;
		const alt = event.target.value;

		image = Image.builder(image).withAlt(alt).build();
		this.updateImageState(image);
	}

	onCaptionChange(event: any) {
		let { image } = this.state;
		const caption = event.target.value;

		image = Image.builder(image).withCaption(caption).build();
		this.updateImageState(image);
	}

	onOwnerChange(selection: any) {
		let { image } = this.state;
		const owner = EnumItem.builder()
			.withId(selection && selection.value)
			.withName(selection && selection.label)
			.build();
		image = Image.builder(image).withOwner(owner).build();
		this.updateImageState(image);
	}

	updateImageState(image: Image) {
		this.setState({
			image,
		});
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any): void {
		if (prevProps.open !== this.props.open) {
			this.imageGetRequest();
			this.setState({
				sportsType: featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL, FeatureTypes.FOOTBALL_CONNECTIONS])
					? this.props.sports[0]
					: ({} as SportsTypesModel),
			});
		}

		if (prevState.addWatermarkChecked !== this.state.addWatermarkChecked) {
			if (this.state.addWatermarkChecked) {
				this.initWaterMarkService() &&
					this.watermarkService.getDefaultWatermark().then((defaultWatermark) => {
						this.setState({ defaultWatermark: defaultWatermark });
					});
			}
		}

		if (prevState.image.id !== this.state.image.id) {
			this.setState({ imageSportsRelated: [] as Related[] });
		}
	}

	componentWillUnmount() {
		this.setState({ sportsType: new SportsTypesModel() });
	}

	onTypeChange = (type: string) => {
		this.setState({ ...this.state, image: Image.builder(this.state.image).withType(type).build() });
	};

	onAuthorChange = (authors: Author[]) => {
		let { image } = this.state;

		this.setState({ ...this.state, image: Image.builder(image).withAuthors(authors).build() });
	};

	onRemoveWatermarkChecked = (e: any) => {
		this.setState({ ...this.state, removeWatermarkChecked: e.target.checked });
	};

	onAddWatermarkChecked = (e: any) => {
		this.setState({ ...this.state, addWatermarkChecked: e.target.checked });
	};

	onSportsTypesSelect = (type: any) => {
		this.setState({ ...this.state, sportsType: type });
	};

	// Potential addition of 3rd party flag in the Content API for project origins to be able to get the images only eligable for watermark
	// TODO Refactor after Content API supports type for origins for a given project
	checkIfValidForWatermark = (image: Image) => {
		let { ownersNotValidForWater } = this.state;

		if (ownersNotValidForWater && ownersNotValidForWater.length > 0 && image.owner) {
			const result = ownersNotValidForWater.filter((item: any) => item.label === image.owner.name);

			if (result.length > 0) {
				return false;
			} else {
				return true;
			}
		}
	};

	render() {
		const { open, t, onClose, types, owners, isPlayingSurface, isImagoImage, authors, watermarkEnabled, sports } = this.props;
		const {
			image,
			isImageUpdating,
			imageRelated,
			removeWatermarkChecked,
			addWatermarkChecked,
			contentLanguage,
			sportsType,
			imageSportsRelated,
		} = this.state;

		return (
			<Modal isOpen={open}>
				<ModalHeader
					toggle={() => {
						onClose(false);
						this.setState({ sportsType: {} as SportsTypesModel });
					}}
				>
					{t('image_update')}
				</ModalHeader>
				<ModalBody>
					<Row className={'mb-3'}>
						<Col xs='12'>
							<Label>{t('image_description')}</Label>
							<Input
								type={'textarea'}
								rows={4}
								id='image-edit-description-input'
								placeholder={t('image_description')}
								value={image && image.description ? image.description : ''}
								onChange={this.onDescriptionChange.bind(this)}
							/>
							<ErrorAsync t={t} errorType='description' />
						</Col>
					</Row>
					{!isPlayingSurface && !isImagoImage ? (
						<Row className={'mb-2'}>
							<Col>
								<Label>{t('origin')}</Label>
								<Select
									value={image && image.owner && image && image.owner.id ? ownerToSelectOption(image.owner) : null}
									className={'mb-1'}
									inputId='playing-surface-origin-select'
									options={imageOwnersToSelectOptions(owners)}
									onChange={this.onOwnerChange.bind(this)}
									isClearable={true}
									placeholder={t('select')}
								/>
							</Col>
						</Row>
					) : (
						<Row className={'mb-2'}>
							<Col>
								<Label>{t('origin')}</Label>
								<Select
									value={image && image.owner && image.owner.id ? ownerToSelectOption(image.owner) : extractDefaultOrigin(owners)}
									className={'mb-1'}
									inputId='image-edit-origin-select'
									options={imageOwnersToSelectOptions(owners)}
									onChange={this.onOwnerChange.bind(this)}
									defaultValue={extractDefaultOrigin(owners)}
									isClearable={true}
									placeholder={t('select')}
								/>
							</Col>
						</Row>
					)}
					<Row className={'mb-2'}>
						<Col>
							<SportsTypeSelect sports={sports} t={t} selectedSportsType={sportsType} onSportsTypeSelect={this.onSportsTypesSelect} />
						</Col>
					</Row>
					{/*{//TODO remove is football selected check when the football data is migrated to sports-search-a}*/}
					{featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL, FeatureTypes.FOOTBALL_CONNECTIONS]) &&
					sportsType &&
					sportsConnectionsService.isFootballSelected(sportsType.sport) ? (
						<Row className={'mb-2'}>
							<Col>
								<Label>{t('football_connections')}</Label>
								<FootballConnectionsContainer
									related={imageRelated}
									inputId='image-edit-football-connections-select'
									footballApiUrl={this.props.currentProject.footballApiUrl}
									withSeason={false}
									onSelect={this.updateImageRelatedState}
									allowMoreFootballConnections={true}
									t={t}
									contentLanguage={contentLanguage}
								/>
							</Col>
						</Row>
					) : (
						sportsType &&
						sportsType.sport && (
							<Row className={'mb-2'}>
								<Col>
									<Label>{t('sports_connections')}</Label>
									<SportsConnectionsContainer
										related={imageSportsRelated}
										onSportsConnectionSelect={this.updateImageSportsRelatedState}
										t={t}
										contentLanguage={contentLanguage}
										sportType={sportsType.sport}
										sportsEntities={sportsType.entity_types}
										allowMoreEntityTypes={true}
									/>
								</Col>
							</Row>
						)
					)}
					<Row>
						{types && types.length > 0 && (
							<Col>
								<FormGroup key={'formgroup-types-key'}>
									<Label>{t('type')}</Label>
									<TypesSelect t={t} isClearable onTypeSelect={this.onTypeChange} types={types} selectedType={image.type} isMulti={false} />
								</FormGroup>
							</Col>
						)}
					</Row>
					<Row>
						<Col>
							<FormGroup key={'formgroup-select-author-key'}>
								<Label htmlFor='title'>{t('author')}</Label>
								<AuthorSelectMultiContainer
									authors={authors}
									selectedAuthors={image && image.authors ? image.authors : []}
									onAuthorSelect={this.onAuthorChange}
									t={t}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className={'mb-3'}>
						<Col xs='12'>
							<Label className='mr-2'>{t('image_alt')}</Label>
							<DebounceInput
								key={`input-image-${image.id}-alt`}
								type='text'
								debounceTimeout={300}
								className='form-control-lg form-control'
								id={`image-edit-alt-input-${image.id}`}
								value={image && image.alt ? image.alt : ''}
								placeholder={t('enter_image_alt')}
								onChange={this.onAltChange.bind(this)}
							/>
							{image.alt && image.alt.length > 255 && (
								<label className='text-danger' htmlFor={`image-alt-error-message-${image.id}`}>
									{t('alt_limit_exceed')}
								</label>
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							<Label className='mr-2'>{t('image_caption')}</Label>
							<DebounceInput
								key={`input-image-${image.id}-caption`}
								element={'textarea'}
								rows={3}
								debounceTimeout={300}
								className='form-control-lg form-control'
								id={`image-edit-caption-input-${image.id}`}
								value={image && image.caption ? image.caption : ''}
								placeholder={t('enter_image_caption')}
								onChange={this.onCaptionChange.bind(this)}
							/>
							{image.caption && image.caption.length > 255 && (
								<label className='text-danger' htmlFor={`image-caption-error-message-${image.id}`}>
									{t('caption_limit_exceed')}
								</label>
							)}
						</Col>
					</Row>
					{watermarkEnabled && image.generic && image.generic.watermark && (
						<Row>
							<Col>
								<FormGroup key={'formgroup-remove-watermark-key'}>
									<input
										type='checkbox'
										className='mx-2 mb-0'
										id='remove-watermark'
										style={{ marginTop: '15px' }}
										onChange={(event) => this.onRemoveWatermarkChecked(event)}
										checked={removeWatermarkChecked}
									/>
									<Label htmlFor='watermark'>{t('remove_watermark')}</Label>
								</FormGroup>
							</Col>
						</Row>
					)}
					{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK) &&
						this.checkIfValidForWatermark(image) &&
						(!image.generic || !image.generic.watermark) && (
							<Row>
								<Col>
									<FormGroup key={'formgroup-add-watermark-key'}>
										<input
											type='checkbox'
											className='mx-2 mb-0'
											id='add-watermark'
											style={{ marginTop: '15px' }}
											onChange={(event) => this.onAddWatermarkChecked(event)}
											checked={addWatermarkChecked}
										/>
										<Label htmlFor='watermark'>{t('add_watermark')}</Label>
									</FormGroup>
								</Col>
							</Row>
						)}
				</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						id='image-edit-save-button'
						disabled={isImageUpdating || (image.caption && image.caption.length > 255) || (image.alt && image.alt.length > 255)}
						className={'text-light'}
						onClick={() => {
							this.toggleIsImageUpdatingState(true);
							this.imagePatchRequest();
							this.setState({ sportsType: {} as SportsTypesModel });
						}}
					>
						{t('save')}
					</Button>
					<Button
						id='image-edit-cancel-button'
						color='danger'
						disabled={isImageUpdating}
						className={'text-light'}
						onClick={() => {
							this.props.onClose(false);
							this.setState({ sportsType: {} as SportsTypesModel });
						}}
					>
						{t('cancel')}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		owners: state.imageOwners.owners,
		types: state.types.imageTypes,
		authors: state.author.authors,
		sports: state.sports.sportsTypes,
	};
}

export default connect(mapStateToProps, null)(ImageEditModal) as React.ComponentType<any>;

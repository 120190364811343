import React from 'react';
import AsyncSelect from 'react-select//async';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import debounce from 'lodash.debounce';
import { playersToOptions, playerToOption, responseToPlayerModel } from '../helpers/player-select/player-select-component.helper';
import { customOption } from '../../../partials/shared/custom-select-option';
import PlayerModel from '../../../../../../models/v2/player/entity/player.model';
import ErrorHandler from '../error-handler/error-handler';

type Properties = {
	onPlayerSelect: any;
	selectedPlayer: PlayerModel | any;
	t: any;
	isValid: boolean;
	isMulti?: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	className?: string;
	id: string;
};

const PlayerSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const searchPlayer = (search: string, callback: any) => {
		HttpService.getPlayer(search)
			.then((response: any) => {
				const options = response.data.map((item: any) => responseToPlayerModel(item));
				callback(playersToOptions(options));
			})
			.catch((e: any) => e);
	};

	const { isRequired, t, onPlayerSelect, selectedPlayer, isValid, isClearable } = props;
	const playerSelected = props.selectedPlayer === undefined || props.selectedPlayer === null || !props.selectedPlayer.id;

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('player')}:</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<AsyncSelect
					id={props.id}
					className='mb-2'
					isClearable={isClearable}
					placeholder={t('search')}
					loadOptions={debounce(searchPlayer, 500)}
					value={selectedPlayer && selectedPlayer.id ? playerToOption(selectedPlayer) : []}
					formatOptionLabel={customOption}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => {
						selection ? onPlayerSelect(selection.data) : onPlayerSelect(null);
					}}
				/>
				{<ErrorHandler isValueSelected={playerSelected} arePropsValid={isValid} t={t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default PlayerSelectContainer as React.ComponentType<Properties>;

import { TypedJSON } from 'typedjson';
import FootballConnectionResponseModel from './response-football-connection.model';

export const modelFootballConnectionToFootballConnectionResponse = (response: any) => {
	const serializer = new TypedJSON(FootballConnectionResponseModel);
	const result = serializer.parse(response);

	if (result) {
		return result;
	}

	return new FootballConnectionResponseModel();
};

import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../global-helpers/global.helpers';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../services/content-models-service/ContentModelService';
import SeoReduxModel from '../../../../models/v2/seo/seo-redux.model';
import { ReduxURLsProps } from './constants/redux-seo.attributes';
import SeoDescriptionTextArea from './subcomponents/description/description';
import SeoSlugInput from './subcomponents/slug/slug';
import SeoTitleInput from './subcomponents/title/title';
import SeoJsonLDTextArea from './subcomponents/json-ld/json-ld';
import SeoIndexingCheckbox from './subcomponents/indexing/indexing';
import SeoFollowingCheckbox from './subcomponents/following/following';
import SeoCopyFromTitleCheckbox from './subcomponents/copy-from-title/copy-from-title';
import SeoGenerateFromSeoTitleCheckbox from './subcomponents/generate-from-seo-title/generate-from-seo-title';
import SeoKeywordsInput from './subcomponents/keywords/keywords';
import { DATA_QA_ATTRIBUTES } from './constants/data-qa.attributes';
import { store } from '../../../../store/store';

type Properties = {
	t: any;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const SidebarSEO: FunctionComponent<Properties> = ({ t, updateTempProperty }) => {
	const [isCopyFromTitleChecked, setIsCopyFromTitleChecked] = useState<boolean>(false);
	const [isGenerateFromSeoTitleChecked, setIsGenerateFromSeoTitleChecked] = useState<boolean>(false);

	const onFieldChange = (dataForRedux: string | string[] | boolean, urlsPropertyName: ReduxURLsProps) => {
		const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
		const seoRedux: SeoReduxModel = store.getState()[tempPropertyNaming][nestedTempPropertyNaming].seo || {};
		updateTempProperty({
			seo: {
				...seoRedux,
				[urlsPropertyName]: dataForRedux,
			},
		});
	};

	return (
		<div id={DATA_QA_ATTRIBUTES.SEO_WRAPPER}>
			<SeoTitleInput t={t} onSeoChange={onFieldChange} isDisabled={isCopyFromTitleChecked} />
			<SeoCopyFromTitleCheckbox t={t} isCopyFromTitleChecked={isCopyFromTitleChecked} setIsCopyFromTitleChecked={setIsCopyFromTitleChecked} />
			<SeoDescriptionTextArea t={t} onSeoChange={onFieldChange} />
			<SeoKeywordsInput t={t} onSeoChange={onFieldChange} />
			<SeoSlugInput t={t} onSeoChange={onFieldChange} isDisabled={isGenerateFromSeoTitleChecked} />
			<SeoGenerateFromSeoTitleCheckbox
				t={t}
				isGenerateFromSeoTitleChecked={isGenerateFromSeoTitleChecked}
				setIsGenerateFromSeoTitleChecked={setIsGenerateFromSeoTitleChecked}
			/>
			<SeoIndexingCheckbox t={t} onSeoChange={onFieldChange} />
			<SeoFollowingCheckbox t={t} onSeoChange={onFieldChange} />
			<SeoJsonLDTextArea t={t} onSeoChange={onFieldChange} />
		</div>
	);
};

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(null, mapDispatchToProps)(SidebarSEO);

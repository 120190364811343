import ModelMapper from '../../models/ModelMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import {
	AUTHOR_ENTITY_CREATE,
	AUTHOR_ENTITY_CREATE_FAILED,
	AUTHOR_ENTITY_CREATE_SUCCESS,
	AUTHOR_ENTITY_DELETE_SUCCESS,
	AUTHOR_ENTITY_RECEIVED,
	AUTHOR_ENTITY_UPDATE,
	AUTHOR_ENTITY_UPDATE_FAILED,
	AUTHOR_ENTITY_UPDATE_SUCCESS,
	AUTHOR_LISTING_RECEIVED,
	PROJECT_AUTHOR_LISTING_RECEIVED,
	PROJECT_DEFAULT_AUTHOR_RECEIVED,
} from '../action-creators/AuthorActionCreators';
import Author from '../../models/author/Author';
import { actionService } from '../../App';
import { SEARCH_IS_APPLIED } from '../action-creators/UiActionCreator';

/**
 * Remap data coming from the API to the model defined in the application
 */

export function authorMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === AUTHOR_LISTING_RECEIVED) {
					const paginationData = action.payload.data.meta.pagination;
					action.payload = ModelMapper.remapAuthorsFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(paginationData);
				}

				if (action.type === PROJECT_AUTHOR_LISTING_RECEIVED) {
					action.payload = ModelMapper.remapAuthorsFromResponse(action.payload);
				}

				if (action.type === PROJECT_DEFAULT_AUTHOR_RECEIVED) {
					action.payload = action.payload && Object.entries(action.payload).length > 0 && ModelMapper.remapAuthorFromResponse(action.payload);
				}

				if (action.type === AUTHOR_ENTITY_RECEIVED) {
					action.payload.author = ModelMapper.remapAuthorFromResponse(action.payload.data.data);
				}

				if (action.type === AUTHOR_ENTITY_CREATE) {
					actionService.emitGeneralAction(AUTHOR_ENTITY_CREATE);
					action.payload.author = Author.builder(action.payload.author).toRequestJson();
				}

				if (action.type === AUTHOR_ENTITY_CREATE_FAILED) {
					actionService.emitGeneralAction(AUTHOR_ENTITY_CREATE_FAILED);
					toast.error(i18n.t('author_create_failed'));
				}

				if (action.type === AUTHOR_ENTITY_CREATE_SUCCESS) {
					actionService.emitGeneralAction(AUTHOR_ENTITY_CREATE_SUCCESS);
					//TODO Replace window.dispatchEvent with actionService
					window.dispatchEvent(new CustomEvent(AUTHOR_ENTITY_CREATE_SUCCESS, { detail: action.payload }));
					toast.success(i18n.t('author_create_success'));
				}

				if (action.type === AUTHOR_ENTITY_DELETE_SUCCESS) {
					toast.success(i18n.t('author_deleted_success'));
					//TODO Replace window.dispatchEvent with actionService
					window.dispatchEvent(new Event(action.type));
				}

				if (action.type === AUTHOR_ENTITY_UPDATE) {
					actionService.emitGeneralAction(AUTHOR_ENTITY_UPDATE);
					action.payload.author = Author.builder(action.payload.author).toRequestJson();
				}

				if (action.type === AUTHOR_ENTITY_UPDATE_FAILED) {
					actionService.emitGeneralAction(AUTHOR_ENTITY_UPDATE_FAILED);
					toast.error(i18n.t('author_update_failed'));
				}

				if (action.type === AUTHOR_ENTITY_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(AUTHOR_ENTITY_UPDATE_SUCCESS);
					toast.success(i18n.t('author_update_success'));
				}

				if (action.type === SEARCH_IS_APPLIED) {
					actionService.emitGeneralAction(SEARCH_IS_APPLIED);
				}
			}

			next(action);
		};
	};
}

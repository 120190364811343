import { SELECT_CONVERT_OPTIONS, TournamentViews, TournamentsFiltersName } from '../constants/Contstants';
import Country from '../../../../models/v2/football/country/country.model';
import { blService } from '../TournamentContainer';
import TeamModel from '../../../../models/v2/Team/Entity/TeamModel';
import { toast } from 'react-toastify';
import { assetsPlaceholder } from '../../../../constants/assetsPlaceholder';
import TournamentModel from '../../../../models/v2/Tournament/Entity/TournamentModel';
import StageModel from '../../../../models/v2/Stage/Entity/StageModel';
import { dateStringAsTimeNumber } from '../../../../global-helpers/global.helpers';
import SeasonModel from '../../../../models/v2/Season/Entity/SeasonModel';

export default class TournamentUiService {
	/**
	 * Generates current view using the current URL path provided by React router
	 * @param path
	 */
	public generateCurrentView = (path: string): TournamentViews => {
		if (path.includes('edit')) {
			return TournamentViews.EDIT;
		} else {
			return TournamentViews.LIST;
		}
	};

	public onImageSelect = (
		evt: any,
		tournament: TournamentModel,
		setTournament: (arg: TournamentModel) => void,
		setImageLoading: React.Dispatch<React.SetStateAction<boolean>>,
		errorMessage: string,
	) => {
		setImageLoading(true);
		const files = evt.target.files;

		if (files) {
			blService
				.updateTournamentLogo(tournament, files[0])
				.then((tournamentData) => setTournament(Object.assign({}, tournamentData)))
				.catch(() => {
					toast.error(errorMessage);
					setImageLoading(false);
				});
		}
	};

	public onUploadTournamentImage = (
		tournament: TournamentModel,
		newTournamentImage: string,
		setTournament: (arg: TournamentModel) => void,
		setImageLoading: React.Dispatch<React.SetStateAction<boolean>>,
		errorMessage: string,
		successMessage: string,
	) => {
		setImageLoading(true);
		blService
			.updateTournamentImageResource(tournament.id, newTournamentImage.split('assets/')[1])
			.then((updateSuccess) => {
				if (updateSuccess) {
					const newTournament = Object.assign({}, tournament);
					newTournament.urlLogo = newTournamentImage;
					setTournament(newTournament);
					toast.success(successMessage);
				} else {
					toast.error(errorMessage);
				}
				setImageLoading(false);
			})
			.catch(() => {
				toast.error(errorMessage);
				setImageLoading(false);
			});
	};

	public checkAreThereDiffBetweenTeams = (defaultTeams: TeamModel[], currentTeams: TeamModel[]) => {
		const currentTeamsFiltered = [...currentTeams].filter((team) => team.id !== '');
		if (defaultTeams.length !== currentTeamsFiltered.length) {
			return true;
		} else {
			let teamDiffFlag = false;
			defaultTeams.forEach((defaultTeam) => {
				const defaultTeamId = defaultTeam.id;
				if (!teamDiffFlag && currentTeamsFiltered.findIndex((team) => team.id === defaultTeamId) < 0) {
					teamDiffFlag = true;
				}
			});
			return teamDiffFlag;
		}
	};

	public convertForReactSelect = (countries: any[] = [], convertOption: SELECT_CONVERT_OPTIONS) => {
		if (countries === undefined || countries === null) {
			return [];
		}

		if (convertOption === SELECT_CONVERT_OPTIONS.FROM) {
			return countries.map((country: any) => {
				return country.data;
			});
		}

		if (convertOption === SELECT_CONVERT_OPTIONS.TO) {
			countries = countries as Country[];
			return countries.map((country) => {
				return {
					label: country.name,
					value: country.id,
					data: country,
				};
			});
		}

		return [];
	};

	public clearAlreadyAddedTeamInSelect = (selectEl: any, setSearchedTeams: Function, displayMessage: string) => {
		setSearchedTeams(new Array<TeamModel>());
		selectEl.label = null;
		selectEl.logo = null;
		selectEl.data = null;
		selectEl.type = null;
		selectEl.value = null;
		toast.error(displayMessage);
	};

	public teamsToOptions = (searchedTeams: Array<TeamModel>) => {
		return [...searchedTeams].map((team) => {
			return {
				value: team.id,
				label: team.name,
				logo: team.urlLogo ? team.urlLogo : assetsPlaceholder.team,
				type: team.type,
				data: team,
			};
		});
	};

	public setFiltersInLocalStorage = (searchValue: string, countryFilter: Country[]) => {
		searchValue ? localStorage.setItem(TournamentsFiltersName.SEARCH, searchValue) : localStorage.removeItem(TournamentsFiltersName.SEARCH);

		countryFilter.length > 0
			? localStorage.setItem(TournamentsFiltersName.SELECTED_COUNTRIES, JSON.stringify(countryFilter))
			: localStorage.removeItem(TournamentsFiltersName.SELECTED_COUNTRIES);
	};

	public removeFiltersFromLocalStorage = () => {
		localStorage.removeItem(TournamentsFiltersName.SEARCH);
		localStorage.removeItem(TournamentsFiltersName.SELECTED_COUNTRIES);
	};

	public getSearchValueFromLocalStorage = () => {
		return localStorage.getItem(TournamentsFiltersName.SEARCH) || '';
	};

	public getCountryFilterFromLocalStorage = (): Country[] => {
		const jsonCountries = localStorage.getItem(TournamentsFiltersName.SELECTED_COUNTRIES) || '';
		if (jsonCountries) {
			const countries = JSON.parse(jsonCountries) as Country[];
			return countries;
		}
		return [];
	};

	// Will get the season id and all stages in the season
	public findSeasonWithAllStagesByStage = (detailedSeasonsInfo: Object, stage: StageModel) => {
		let seasonIdFound: string = '';
		let seasonStagesFound: StageModel[] = [];
		const detailedSeasonsInfoKeys = Object.keys(detailedSeasonsInfo);
		if (detailedSeasonsInfoKeys && detailedSeasonsInfoKeys.length > 0) {
			detailedSeasonsInfoKeys.forEach((key) => {
				if (detailedSeasonsInfo[key].includes(stage)) {
					seasonStagesFound = detailedSeasonsInfo[key];
					seasonIdFound = key;
				}
			});
		}

		return {
			id: seasonIdFound,
			stages: seasonStagesFound,
		};
	};

	// Will get the previous stage from current season
	public previousStageFromCurrentSeason = (seasonStages: StageModel[], stage: StageModel): StageModel | null => {
		let sortedSeasonStagesByDate = [...seasonStages].sort(
			(x: StageModel, y: StageModel) => dateStringAsTimeNumber(x.startDate) - dateStringAsTimeNumber(y.startDate),
		);
		const selectedStageIndexInSortedArray = sortedSeasonStagesByDate.findIndex((sortedStageEl) => stage == sortedStageEl);

		if (selectedStageIndexInSortedArray > 0) {
			return sortedSeasonStagesByDate[selectedStageIndexInSortedArray - 1];
		} else {
			return null;
		}
	};

	// Will get the earliest stage from previous season (earliest stage from previous season)
	public getEarliestStageFromPreviousSeason = (seasonStages: StageModel[]): StageModel | null => {
		let sortedSeasonStagesByDate = [...seasonStages].sort(
			(x: StageModel, y: StageModel) => dateStringAsTimeNumber(y.startDate) - dateStringAsTimeNumber(x.startDate),
		);

		if (sortedSeasonStagesByDate.length > 0) {
			return sortedSeasonStagesByDate[sortedSeasonStagesByDate.length - 1];
		}
		return null;
	};

	// Will check is the current season latest and will find previous season id
	public extractSeasonIdsInfo = (seasons: SeasonModel[], currentSeasonId: string) => {
		try {
			const allSeasonsIds = [...seasons].map((season) => season.id);
			const currentSeasonIndexInArray = allSeasonsIds.findIndex((seasonId) => seasonId === +currentSeasonId);
			const isCurrentSeasonLatest = currentSeasonIndexInArray === 0;
			const oldestSeasonIndexInArray = allSeasonsIds.length - 1;
			const previousSeasonId = currentSeasonIndexInArray === oldestSeasonIndexInArray ? null : allSeasonsIds[currentSeasonIndexInArray + 1];

			return {
				currentSeasonId: +currentSeasonId,
				isCurrentSeasonLatest,
				previousSeasonId: previousSeasonId,
			};
		} catch {
			return {
				currentSeasonId: null,
				isCurrentSeasonLatest: null,
				previousSeasonId: null,
			};
		}
	};
}

import React, { Component } from 'react';
import Button from 'reactstrap/es/Button';
import './manual-linking.component.scss';
import DropdownEntityTag from './dropdown-entity-tag.component';
import EditorHelper from '../helpers/editor-block-edit.helper';
import { Tooltip } from 'reactstrap';

export default class ManualLinkingControls extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tooltipOpen: false,
		};
	}

	onSingleTagInsert = (tag) => {
		if (this.props.tags && tag && tag.parent) {
			const pos = this.props.tags.findIndex((el) => el.id === tag.parent);
			if (pos >= 0) this.props.tags.splice(pos, 1);
		}

		this.props.onSingleTagInsert(tag);
	};

	onMultipleTagsInsert = (firstOnly) => {
		const tags = this.markTagsForOccurrenceLinking(firstOnly);
		this.props.onMultipleTagsInsert(tags);
	};

	requestTags = () => {
		this.props.requestTags();
	};

	removeMarkedTags = (tags) => {
		let markedTags = JSON.parse(JSON.stringify(tags ? tags : []));

		markedTags = markedTags
			? markedTags.filter((tag) => {
					if (tag.removed !== undefined) {
						return !tag.removed;
					} else {
						return true;
					}
			  })
			: [];

		return markedTags;
	};

	markTagsForOccurrenceLinking = (firstOnly) => {
		let tags = this.props.tags ? [...this.props.tags] : [];

		tags = tags.map((tag) => {
			tag['firstOnly'] = firstOnly;

			return tag;
		});

		return tags;
	};

	toggle = () => {
		this.setState({ ...this.state, tooltipOpen: !this.state.tooltipOpen });
	};

	getTooltip = (id, t) => {
		return (
			<div className='controls-container-info'>
				<i id={`tooltip-manual-linking-${id}`} className='fa fa-question-circle-o'></i>
				<Tooltip placement='top' isOpen={this.state.tooltipOpen} target={`tooltip-manual-linking-${id}`} toggle={this.toggle}>
					{t('manual_linking_tooltip')}
				</Tooltip>
			</div>
		);
	};

	render() {
		const { isLoading, t, id } = this.props;
		let tags = this.removeMarkedTags(this.props.tags);

		return (
			<div className={isLoading ? 'controls-container loading-overlay-h-auto' : 'controls-container'}>
				<div className='general-controls mr-3'>
					<Button
						outline
						className='mr-2'
						size='sm'
						color='primary'
						onClick={() => {
							this.requestTags();
						}}
					>
						<i className='fa fa-refresh'></i>
					</Button>

					<Button
						outline
						className='mr-2'
						size='sm'
						color='primary'
						onClick={() => {
							this.onMultipleTagsInsert(false);
						}}
					>
						{t('add_all')}
					</Button>

					<Button
						outline
						className='mr-2'
						size='sm'
						color='primary'
						onClick={() => {
							this.onMultipleTagsInsert(true);
						}}
					>
						{t('add_first_occurrences')}
					</Button>
					{this.getTooltip(id, t)}
				</div>

				<div className='control-container-tags'>
					{tags
						? tags
								.filter((tag) => {
									return new EditorHelper().autoTagFeatureLinkOptionsHasLinkType(tag.subtype);
								})
								.map((tag) => {
									return (
										<DropdownEntityTag
											t={t}
											tag={tag}
											key={tag ? `key-entity-tag-${tag.type}-${tag.text}` : 'key-entity-tag-'}
											onTagInsert={this.onSingleTagInsert}
										/>
									);
								})
						: null}
				</div>
			</div>
		);
	}
}

import React from 'react';
import { Col, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import BlockModel from '../../../../models/block.model';
import LivescoreWidgetModel from '../models/livescore-widget.model';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { SportsDateSelect } from './subcomponents/sports-date.select';
import SportBookmakerSelect from '../../../../partials/sport-bookmaker-select/sport-bookmaker-select.component';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { SportTypes } from '../../../../../../../constants/sport.types';
import DisplayOddsComponent from '../../../../partials/bookmaker-select/display-odds.component';
import { SPORT_SELECT_ATTRIBUTES } from '../../../../v2/widgets-v2/helpers/component-attributes.constants';
import { createBlock } from '../../../../v2/partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsIds, WidgetsV2Ids } from '../../../../constants/block.types';
import { onRefreshTimeChangeFromMainComponent } from '../../../../v2/partials/refresh-time-input/helper';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import RefreshTimeWrapper from '../../../../v2/partials/refresh-time-input/refresh-time-wrapper';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: LivescoreWidgetModel;
	isValid: boolean;
};

export default class BasketballLivescoreWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetBasketballLivescore,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widgetModel: this.state.widgetModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) === true;
		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const livescoreModel = LivescoreWidgetModel.builder(this.state.widgetModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.widgetModel.bookmakers : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: livescoreModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: LivescoreWidgetModel) => this.setState({ ...this.state, widgetModel: model });

	onDateChange = (date: string) => {
		this.updateWidgetModelState(LivescoreWidgetModel.builder(this.state.widgetModel).withDate(date).build());
	};

	onDisplayOddsChange = (e: any) => {
		this.updateWidgetModelState(LivescoreWidgetModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build());
	};

	onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		this.updateWidgetModelState(LivescoreWidgetModel.builder(this.state.widgetModel).withBookmakers(bookmakers).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, LivescoreWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t } = this.props;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);

		return (
			<>
				<Row>
					<Col>
						<SportsDateSelect
							id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.DATE_SELECT}`}
							t={t}
							onDateChange={this.onDateChange}
							date={widgetModel.date}
						/>
					</Col>
				</Row>
				{!isValid && (!widgetModel.date || widgetModel.date === '') && (
					<div className='mb-3'>
						<ErrorHandler t={t} errorMessage='no_date_selected' />
					</div>
				)}
				<DisplayOddsComponent
					t={t}
					block={this.props.block}
					displayOddsChecked={widgetModel.displayOdds ? widgetModel.displayOdds : false}
					onDisplayOddsChange={this.onDisplayOddsChange}
					id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
				/>
				{widgetModel && widgetModel.displayOdds && bookmakerSelectionEnabled && (
					<Row className='mb-2'>
						<Col>
							<SportBookmakerSelect
								isValid={this.state.isValid}
								showAllBookmakers={false}
								bookmakerSelectionEnabled={bookmakerSelectionEnabled}
								t={this.props.t}
								valueList={widgetModel.bookmakers ? widgetModel.bookmakers : null}
								onSelect={this.onBookmakerChange}
								contentLanguage={this.props.contentLanguage}
								sportType={SportTypes.BASKETBALL}
								id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
							/>
						</Col>
					</Row>
				)}
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</>
		);
	}
}

import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { authorsToOptions } from '../../helpers/authors-select.helper';
import Author from '../../../../../../models/author/Author';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import {
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	isArrayEmpty,
} from '../../../../../../global-helpers/global.helpers';
import { ContentMode } from '../../../../../../constants/content-types';
import { customOption } from '../../../../Shared/custom-select-options/custom-select-option';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxGeneralContentProps } from '../../constants/redux-general-content.attributes';

type Properties = {
	t: any;
	availableAuthors: Author[];
	defaultAuthor: Author | null;
	selectedAuthors: Author[];
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const AuthorsMultiselect: FunctionComponent<Properties> = ({ t, availableAuthors, selectedAuthors, defaultAuthor, updateTempProperty }) => {
	useEffect(() => {
		if (extractContentModeBasedOnUrl() === ContentMode.CREATE && selectedAuthors && selectedAuthors.length === 0 && defaultAuthor) {
			updateTempProperty({ [ReduxGeneralContentProps.AUTHORS]: [defaultAuthor] });
		}
	}, []);

	const onAuthorSelect = (authorsData: Array<SelectMenuOptionType>) => {
		if (authorsData && authorsData.length > 0) {
			const authorsDataForRedux = authorsData
				.filter((authorData: SelectMenuOptionType) => !!authorData.data)
				.map((authorData: SelectMenuOptionType) => authorData.data);
			updateTempProperty({ [ReduxGeneralContentProps.AUTHORS]: authorsDataForRedux });
		} else {
			updateTempProperty({ [ReduxGeneralContentProps.AUTHORS]: [] });
		}
	};

	return isArrayEmpty(availableAuthors) ? null : (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.AUTHOR}>{t('author')}</Label>
			<Select
				id={DATA_QA_ATTRIBUTES.AUTHOR}
				value={authorsToOptions(selectedAuthors)}
				options={authorsToOptions(availableAuthors)}
				onChange={onAuthorSelect}
				isMulti
				formatOptionLabel={customOption}
				placeholder={t('select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		selectedAuthors: state[tempPropertyNaming][nestedTempPropertyNaming].authors || [],
		availableAuthors: state.author.authors || [],
		defaultAuthor: state.author.defaultAuthor || null,
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorsMultiselect);

import React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Collapse from 'reactstrap/lib/Collapse';
import BlockModel from '../../../../models/block.model';
import { checkBlockIsSetAndEnabled, displayBlockPreview } from './hepers/block-list-category.helper';
import { Card, CardBody, CardImg, CardText, CardTitle, Label, Tooltip } from 'reactstrap';
import { ContentTypes } from '../../../../../../../constants/content-types';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { WidgetTypesV2 } from '../../../../constants/block.types';

type Properties = {
	blockListCategory: any;
	isOpen: boolean;
	onToggleAccordion: (toggle: boolean) => void;
	onBlockAdd: (block: BlockModel) => void;
	t: any;
	id: string;
	contentType: string;
};

type State = {
	tooltipOpen: string;
	isShown: boolean;
};

export default class BlockListCategory extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			tooltipOpen: '',
			isShown: false,
		};
	}

	private toggleAccordion = (toggle: boolean) => {
		this.props.onToggleAccordion(toggle);
	};

	private addBlock = (data: any) => {
		this.props.onBlockAdd(data);
	};

	setIsShown = (bool: boolean, id: string) => {
		this.setState({ ...this.state, tooltipOpen: id, isShown: bool });
	};

	render() {
		const { t, blockListCategory, id, contentType } = this.props;
		const { tooltipOpen, isShown } = this.state;
		return (
			<Row>
				<Col>
					<Label className='label-collapse' onClick={() => this.toggleAccordion(!this.props.isOpen)}>
						{t(blockListCategory && blockListCategory.category_name_translation)}
					</Label>
					<Collapse isOpen={this.props.isOpen} data-parent='#accordion' id='collapseOne' aria-labelledby='headingOne'>
						<Row>
							{blockListCategory &&
								blockListCategory.blocks &&
								blockListCategory.blocks.map((block: any, index: any) => {
									if (!checkBlockIsSetAndEnabled(block.name)) {
										return null;
									}

									if (contentType === ContentTypes.WIKI_PAGE && block.feature_id === FeatureTypes.AMG_INTEGRATION) {
										return null;
									}

									return (
										<div key={index} className='block-add-button'>
											<div
												id={`tooltip-${block.name}-${index}-${id}`}
												className='components-button'
												key={JSON.stringify(block)}
												color='primary'
												onClick={() => {
													this.addBlock(JSON.parse(JSON.stringify(block.default_data)));
												}}
												onMouseOver={() => this.setIsShown(displayBlockPreview(block), `tooltip-${block.name}-${index}-${id}`)}
												onMouseLeave={() => this.setIsShown(false, '')}
											>
												<span className='block-add-button-icon'>
													<i className={block.icon} />
												</span>
												<span className='block-add-button-name'>{t(block.name)}</span>
											</div>
											{isShown && (
												<Tooltip
													placement='left'
													isOpen={tooltipOpen === `tooltip-${block.name}-${index}-${id}`}
													target={`tooltip-${block.name}-${index}-${id}`}
													key={`tooltip-${block.name}-${index}-${id}`}
													fade={false}
													className={'add-block-tooltip'}
												>
													<Card className='card'>
														<CardImg className='card-image' top width='100%' src={block.preview} />
														<CardBody className='card-body'>
															<CardTitle className={'card-title'}>
																<i className={block.icon} />
																{t(block.name)}
															</CardTitle>
															<CardText className={'card-text'}>{t(block.description)}</CardText>
														</CardBody>
													</Card>
												</Tooltip>
											)}
										</div>
									);
								})}
						</Row>
					</Collapse>
				</Col>
			</Row>
		);
	}
}

import React from 'react';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import { Properties, State } from './Properties/ArticleListingProperties';
import ListingComponent from '../../Partials/BaseComponents/ListingComponent/ListingComponent';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
	ARTICLE_ENTITY_DELETE_SUCCESS,
	returnObjectForArticleDelete,
	returnObjectForArticleListingRequest,
	returnObjectForArticleSearch,
} from '../../../store/action-creators/ArticleActionCreator';
import { ContentTypes } from '../../../constants/content-types';
import ConditionalRenderWrapper from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../store/action-creators/ImageActionCreator';
import AdvancedFilterModel from '../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import { extractSearchQueryFromFilters } from '../../Partials/Shared/advanced-content-filter/helpers/advanced-content-filter.helper';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { remmapContentFilters } from './Helpers/ArticleHelper';
import { CopyToProjectsModal } from '../../Partials/Modals/CopyToProjects/CopyToProjectsModal';
import { LANGUAGE_CODE_UPDATE_SUCCESS } from '../../../store/action-creators/ProjectResourcesActionCreators';
import { Subscription } from 'rxjs';
import { actionService, multiLingualService } from '../../../App';
import Article from '../../../models/article/Article';
import { updateTempArticle } from '../../../store/action-creators/ArticleTempActionCreator';
import TranslationGroupModel from '../../../models/translation-groups/translation-group-model';
import { returnObjectForWikiPaginationUpdate } from '../../../store/action-creators/WikiActionCreator';

class ArticleListing extends React.Component<Properties, State> {
	private actionServiceSubject = new Subscription();

	constructor(props: any) {
		super(props);
		this.state = {
			toggleModal: false,
			currentPage: this.props.pagination.currentPage ? this.props.pagination.currentPage.toString() : '1',
		};
		this.registerActionEventListener();
	}

	getArticlesListener = () => this.applyFilters(this.props.filters);

	registerActionEventListener() {
		window.addEventListener(ARTICLE_ENTITY_DELETE_SUCCESS, this.getArticlesListener);

		this.actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => {
			if (action === LANGUAGE_CODE_UPDATE_SUCCESS) {
				this.setState({ currentPage: '1' });
				this.getArticlesListener();

				//Update language in temp article
				const article = Article.builder(this.props.tempArticle)
					.withId('temp-id')
					.withLanguage(this.props.currentProject.languages.defaultLanguageCode.languageCode)
					.build();
				this.props.updateTempArticle(article);
			}
		});
	}

	setCurrentPage(page: string) {
		this.props.analytics.sendPageView(
			this.props.location.pathname,
			`${parseInt(page) > 1 ? 'ArticleListing - Page ' + page : 'ArticleListing'}`,
		);
		this.setState({
			...this.state,
			currentPage: page,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'ArticleListing');
		this.applyFilters(this.props.filters);
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateWikiPagesPagination(PaginationMeta.builder().withCurrentPage(1).build());

		const filters = remmapContentFilters(this.props.filters, {}, {}, {}, {});
		this.props.updateContentFilters(filters);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (this.state.currentPage !== prevState.currentPage) {
			this.registerActionEventListener();
		}
	}

	componentWillUnmount() {
		window.removeEventListener(ARTICLE_ENTITY_DELETE_SUCCESS, this.getArticlesListener);
		this.actionServiceSubject.unsubscribe();
	}

	initPageTitle() {
		document.title = this.props.t('articles');
	}

	applyFilters = (filters: AdvancedFilterModel) => {
		const query = extractSearchQueryFromFilters(filters);
		query.length > 0 && query !== '*'
			? this.props.getArticles(this.props.pagination.currentPage, this.props.currentProject, query)
			: this.props.getArticles(this.props.pagination.currentPage, this.props.currentProject);
	};

	onDeleteContent = (id: string) => {
		this.props.deleteArticle(id, this.props.currentProject);
		if (
			this.props.tempArticle.translationGroup &&
			multiLingualService.checkIfProjectIsMultiLingual(this.props.currentProject.languages) &&
			id === this.props.tempArticle.translationGroup.masterContentId
		) {
			const tempArticle = Article.builder(this.props.tempArticle).withTranslationGroup(TranslationGroupModel.builder().build()).build();
			this.props.updateTempArticle(tempArticle);
		}
	};

	render() {
		return (
			<ConditionalRenderWrapper expectedPermissions={['read_articles', 'write_articles', 'delete_articles']}>
				<ListingComponent
					contentType={ContentTypes.ARTICLE}
					getContent={this.props.getArticles}
					searchContent={this.props.searchArticles}
					analytics={this.props.analytics}
					currentProject={this.props.currentProject}
					history={this.props.history}
					onPageChange={this.setCurrentPage.bind(this)}
					content={this.props.articles}
					onDeleteContent={(id: string) => this.onDeleteContent(id)}
					contentPagination={this.props.pagination}
					showSearchBar={true}
				/>
				<CopyToProjectsModal t={this.props.t} />
			</ConditionalRenderWrapper>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		articles: state.article.articles,
		articleDeleted: state.article.articleDeleted,
		currentProject: state.project.currentProject,
		pagination: state.article.pagination,
		filters: state.article.filters,
		tempArticle: state.tempArticle.article,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getArticles: (page: string, project: Project, text: string) => dispatch(returnObjectForArticleListingRequest(page, project, text)),
		searchArticles: (text: string, project: Project) => dispatch(returnObjectForArticleSearch(text, project)),
		deleteArticle: (id: string, project: Project) => dispatch(returnObjectForArticleDelete(id, project)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateWikiPagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForWikiPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
		updateTempArticle: (article: Article) => dispatch(updateTempArticle(article)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ArticleListing);

import AuthorBuilder from './AuthorBuilder';
import { AuthorJson } from './AuthorJson';
import Seo from '../seo/Seo';

export default class Author {
	readonly id: string;
	readonly name: string;
	readonly bio: string;
	readonly active: boolean;
	readonly isDefault: boolean;
	readonly avatarImage: string;
	readonly generic: string;
	readonly createdAt: string;
	readonly updatedAt: string;
	readonly seo: Seo;

	private constructor(
		id: string,
		name: string,
		bio: string,
		active: boolean,
		isDefault: boolean,
		avatarImage: string,
		generic: string,
		createdAt: string,
		updatedAt: string,
		seo: Seo,
	) {
		this.id = id;
		this.name = name;
		this.bio = bio;
		this.active = active;
		this.isDefault = isDefault;
		this.avatarImage = avatarImage;
		this.generic = generic;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.seo = seo;
	}

	toJSON(): AuthorJson {
		return {
			id: this.id,
			name: this.name,
			bio: this.bio,
			active: this.active,
			isDefault: this.isDefault,
			avatarImage: this.avatarImage,
			generic: this.generic,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			seo: this.seo,
		};
	}

	static fromJSON(json: AuthorJson): Author {
		return new Author(
			json.id,
			json.name,
			json.bio,
			json.active,
			json.isDefault,
			json.avatarImage,
			json.generic,
			json.createdAt,
			json.updatedAt,
			json.seo,
		);
	}

	static builder(author?: Author): AuthorBuilder {
		return new AuthorBuilder(author);
	}
}

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Subscription } from 'rxjs';
import { actionService } from '../../../../../App';
import Project from '../../../../../models/project/Project';
import ContentTitleContainer from '../../../../Partials/BaseComponents/ListingComponent/subcomponents/ContentTitle/ContentTitleContainer';
import ListingComponent from '../../listing-container.component';
import { ContentTypes } from '../../../../../constants/content-types';
import * as uiActions from '../../../../../store/action-creators/UiActionCreator';
import PaginationMeta from '../../../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../../../store/action-creators/ImageActionCreator';
import { remmapContentFilters } from '../../../Articles/Helpers/ArticleHelper';
import { returnObjectForContentFiltersUpdate } from '../../../../../store/action-creators/content-resources';
import {
	coachSearchFilterUpdate,
	returnObjectForCoachListingRequest,
	returnObjectForCoachSearch,
} from '../../../../../store/action-creators/coach-action-creator';
import CoachManualDataModel from '../../../../../models/coach-manual-data/coach-manual-data.model';

export type Properties = {
	t: any;
	coaches: CoachManualDataModel[];
	currentProject: Project;
	analytics: any;
	tReady: boolean;
	i18n: any;
	location: any;
	history: any;
	getCoaches: (page: string, project: Project) => any;
	dispatch: any;
	isToggledModal: boolean;
	toggleLoadingState: boolean;
	pagination: PaginationMeta;
	searchCoaches: any;
	lang: string;
	updateVideosPagination: (pagination: PaginationMeta) => any;
	updateGalleriesPagination: (pagination: PaginationMeta) => any;
	updateArticlesPagination: (pagination: PaginationMeta) => any;
	updateImagesPagination: (pagination: PaginationMeta) => any;
	updateContentFilters: (filters: any) => any;
	searchText: string;
};

export type State = {
	currentPage: string;
	toggleModal: boolean;
};

class CoachesListingComponent extends React.Component<Properties, State> {
	coachesSubscription: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			toggleModal: false,
			currentPage: '1',
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'CoachListing');
		if (this.props.searchText && this.props.searchText.length === 0) {
			this.props.getCoaches(this.state.currentPage, this.props.currentProject);
		}
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {});
		this.props.updateContentFilters(filters);
	}

	setCurrentPage = (page: string) => {
		this.props.analytics.sendPageView(this.props.location.pathname, `${parseInt(page) > 1 ? 'CoachListing - Page ' + page : 'CoachListing'}`);
		this.setState({ ...this.state, currentPage: page });
	};

	initPageTitle() {
		document.title = this.props.t('coaches');
	}

	render() {
		const { t, analytics, toggleLoadingState, coaches } = this.props;

		return (
			<div className='animated fadeIn'>
				<div className='card'>
					<ContentTitleContainer contentType={ContentTypes.COACH} t={t} />
					<div className='card-body'>
						<ListingComponent
							t={t}
							content={coaches}
							contentType={ContentTypes.COACH}
							analytics={analytics}
							toggleLoadingState={toggleLoadingState}
							history={this.props.history}
							getContent={this.props.getCoaches}
							onPageChange={this.setCurrentPage}
							currentProject={this.props.currentProject}
							contentPagination={this.props.pagination}
							searchContent={this.props.searchCoaches}
							lang={this.props.lang}
							showSearchBar={true}
						/>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		coaches: state.coach.coaches,
		currentProject: state.project.currentProject,
		pagination: state.coach.pagination,
		isToggledModal: state.ui.isToggleModal,
		toggleLoadingState: state.ui.toggleLoadingState,
		lang: state.project.currentProject.language,
		searchText: state.coach.searchFilter,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		dispatch: (action: any) => dispatch(action),
		getCoaches: (page: string, project: Project) => dispatch(returnObjectForCoachListingRequest(page, project)),
		searchCoaches: (text: string, lang: string, type: string) => dispatch(returnObjectForCoachSearch(text, lang, type)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CoachesListingComponent) as React.ComponentType;

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { customOption } from '../../../partials/shared/custom-select-option';
import TennisRoundModel from '../../../../../../models/v2/round/tennis-round/tennis-round.model';
import ErrorHandler from '../error-handler/error-handler';
import { tennisRoundsToOptions } from './tennis-rounds-select.helper';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';

type Properties = {
	onRoundsSelect: (selection: TennisRoundModel[]) => any;
	selectedRounds: TennisRoundModel[];
	t: any;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	language: string;
	sport: string;
	id: string;
	rounds: TennisRoundModel[];
	preselectRounds: boolean;
};

export const TennisTournamentRoundsSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [roundsOptions, setRoundsOptions] = useState<TennisRoundModel[]>([]);
	const { rounds } = props;

	useEffect(() => {
		updateRoundsOptionsState(rounds);
	}, [rounds]);

	const updateRoundsOptionsState = (rounds: TennisRoundModel[]) => {
		if ((!props.selectedRounds || props.selectedRounds.length < 1) && props.preselectRounds) {
			props.onRoundsSelect(rounds);
		}
		setRoundsOptions(rounds);
	};

	const onSelect = (rounds: SelectMenuOptionType[]) => {
		if (rounds && rounds.length > 0) {
			props.onRoundsSelect(rounds.map((round: any) => round.data));
		} else props.onRoundsSelect([] as TennisRoundModel[]);
	};

	const roundsSelected = props.selectedRounds === null || (props.selectedRounds && props.selectedRounds.length === 0);
	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{props.t('rounds')}</Label>
				{props.isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={props.t('select')}
					formatOptionLabel={customOption}
					options={tennisRoundsToOptions(roundsOptions)}
					value={tennisRoundsToOptions(props.selectedRounds)}
					noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
					isClearable={props.isClearable}
					onChange={onSelect}
					isMulti
				/>
				{props.isRequired && (
					<ErrorHandler t={props.t} arePropsValid={props.isValid} isValueSelected={roundsSelected} errorMessage='field_is_required' />
				)}
			</Col>
		</Row>
	);
};

import { ContentMode } from '../../constants/content-types';
import Article from '../../models/article/Article';

export const ARTICLE_TEMP_UPDATE = '[ACTION] ARTICLE_TEMP_UPDATE';
export const ARTICLE_TEMP_EDIT_UPDATE = '[ACTION] ARTICLE_TEMP_EDIT_UPDATE';
export const ARTICLE_TEMP_CLEAR = '[ACTION] ARTICLE_TEMP_CLEAR';
export const ARTICLE_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING = '[ACTiON] ARTICLE_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING';
export const ARTICLE_TEMP_PROPERTY_UPDATE = '[ACTION] ARTICLE_TEMP_PROPERTY_UPDATE';

export function updateTempArticle(article: Article) {
	return {
		type: ARTICLE_TEMP_UPDATE,
		payload: article,
	};
}

export function updateTempEditArticle(article: Article) {
	return {
		type: ARTICLE_TEMP_EDIT_UPDATE,
		payload: article,
	};
}

export function updateTempArticleProperty(propertyObject: Record<string, any>, contentMode: ContentMode) {
	return {
		type: ARTICLE_TEMP_PROPERTY_UPDATE,
		payload: propertyObject,
		contentMode,
	};
}

export function updateTempEditArticleBeforeRedirecting() {
	return {
		type: ARTICLE_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING,
	};
}

export function clearTempArticle() {
	return {
		type: ARTICLE_TEMP_CLEAR,
	};
}

import React from 'react';
import { SearchProperties, State } from './properties/searchBarProperties';
import { Col, FormGroup, Input, InputGroupAddon, InputGroup, Button } from 'reactstrap';

class SearchBar extends React.Component<SearchProperties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			contentSearchInput: props.contentSearchInput ? props.contentSearchInput : '',
		};
	}

	onContentSearchInput = (data: any) => {
		const { advancedContentSearchInput } = this.props;

		let searchText = data.target.value;
		this.setState({ ...this.state, contentSearchInput: data.target.value });

		if (searchText.length < 1 && this.props.advancedContentSearchInput.length < 1) {
			this.props.analytics.sendEvent('Click', 'List Page', 'Search Performed');
			this.props.getContent(1, this.props.currentProject);
			this.props.updateSearchText('', '');
		} else {
			const contentSearch = searchText.length > 0 ? searchText + advancedContentSearchInput : `*${advancedContentSearchInput}`;
			this.props.updateSearchText(searchText, contentSearch);
		}
	};

	onContentSearch = (event: any) => {
		if (event.keyCode === 13 && (this.state.contentSearchInput.length > 0 || this.props.advancedContentSearchInput.length > 0)) {
			this.searchContent(this.state.contentSearchInput);
		}
	};

	searchContent = (text: string) => {
		const { advancedContentSearchInput, currentPage } = this.props;

		if (text.match(/^ *$/) !== null && advancedContentSearchInput.match(/^ *$/) !== null) {
			this.setState({ ...this.state, contentSearchInput: '' });
			this.props.updateSearchText('', '');
			this.props.getContent(currentPage, this.props.currentProject);
		} else {
			const contentSearch = text.length > 0 ? text + advancedContentSearchInput : `*${advancedContentSearchInput}`;
			this.props.searchContent(contentSearch, this.props.currentProject);
			this.props.updateSearchText(text, contentSearch);
		}
	};

	render() {
		const { showSearchBar, t } = this.props;

		if (showSearchBar === false) {
			return null;
		}

		return (
			<>
				<FormGroup row className='mb-0'>
					<Col md='12'>
						<InputGroup>
							<Input
								type='text'
								id='search-input'
								name='search-input'
								placeholder={t('content_search_placeholder')}
								onChange={this.onContentSearchInput}
								onKeyDown={this.onContentSearch}
								value={this.state.contentSearchInput}
							/>
							<InputGroupAddon addonType='append'>
								<Button
									type='button'
									color='primary'
									id='content-listing-search-button'
									onClick={() => this.searchContent(this.state.contentSearchInput)}
								>
									<i className={'fa fa-search'}>&nbsp;</i>
									{t('search')}
								</Button>
							</InputGroupAddon>
						</InputGroup>
					</Col>
				</FormGroup>
			</>
		);
	}
}

export default SearchBar;

import { Editor } from '@tiptap/core';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { NodeClassNames } from '../../../constants/NodeClassNames';
import { Link } from '../../../helpers/collaborative-editor-extensions.helper';
import createCustomNodes from '../../../helpers/create-custom-nodes';
import getDataAttributes from '../../../helpers/get-data-attributes';
import { OpenModalCallback } from '../../main-components/live-blog-editorial-admin.component';
import ContentBlocksSelection from '../content-blocks-selection/content-blocks-selection';
import EditorToolbar from './collaborative-editor-toolbar';

import '../../../style/collaborative-editor.scss';

const COLLABORATIVE_EDITOR_CONTENT_UPDATE_TIMEOUT = 10;

declare module '@tiptap/core' {
	interface Commands<ReturnType> {
		smp_widget: {
			/**
			 * Inserts a widget.
			 */
			insertAdvancedContent: (attributes: WidgetDetails['attrs']) => ReturnType;
		};
	}
}

export type WidgetDetails = {
	subDocumentId?: string;
	attrs: Record<Attr['name'], Attr['value']>;
};

export interface CustomNode {
	name: string;
	content?: string;
	group?: string;
	atom?: boolean;
	attributes: Record<string, { default: any }>;
	parseHTMLDetails: {
		tag: string;
		getAttrs: (node: string | HTMLElement) => false | null;
	}[];
	renderDetails: { tag: string; additionalAttributes?: Record<string, any> };
	component: FC;
}

export enum WidgetActionType {
	ADD = 'add',
	EDIT = 'edit',
}

export type CollaborativeEditorProps = {
	content?: Content;
	lastUpdatedWidget?: {
		actionType: WidgetActionType;
		widgetDetails: WidgetDetails;
	};
	onUpdate?: (editor: Editor) => void;
	onWidgetBeginEdit?: (widgetDetails: WidgetDetails) => void;
	subDocumentId?: string;
	customNodes?: CustomNode[];
	resetToken?: {} | null;
} & (
	| {
			editable: true;
			openModalCallback: OpenModalCallback;
	  }
	| { editable: false; openModalCallback: undefined }
);

const CollaborativeEditor: FC<CollaborativeEditorProps> = ({
	content,
	editable,
	lastUpdatedWidget,
	onUpdate,
	onWidgetBeginEdit,
	subDocumentId,
	customNodes = [],
	resetToken,
	openModalCallback,
}) => {
	const [linkClicked, setLinkClicked] = useState<{ href: string } | null>(null);
	const initialized = useRef(false);

	const onLinkClicked = (element: HTMLAnchorElement) => {
		setLinkClicked({ href: element.href });
	};

	const onWidgetEditClicked = (element: Element) => {
		if (!onWidgetBeginEdit) {
			return;
		}

		onWidgetBeginEdit({
			subDocumentId,
			attrs: getDataAttributes(element),
		});
	};

	const customExtensions = useMemo(() => createCustomNodes(customNodes), [customNodes]);

	const editor = useEditor({
		editorProps: {
			handleClick: (_view, _pos, event) => {
				const targetElement = event.target;

				if (!editable || !targetElement || !(targetElement instanceof HTMLElement || targetElement instanceof SVGElement)) {
					return;
				}

				const link = targetElement.closest(`.${NodeClassNames.LINK}`);

				if (link) {
					onLinkClicked(targetElement as HTMLAnchorElement);
				} else if (!(targetElement instanceof HTMLAnchorElement)) {
					const widgetPreviewElement = targetElement.closest('.editor-widget-preview');

					if (widgetPreviewElement) {
						onWidgetEditClicked(widgetPreviewElement);
					}
				}
			},
		},
		extensions: [
			...customExtensions,
			Link.configure({ HTMLAttributes: { class: NodeClassNames.LINK } }),
			Table,
			TableCell,
			TableHeader,
			TableRow,
			TextAlign.configure({
				types: ['heading', 'paragraph'],
				alignments: ['left', 'right', 'center', 'justify'],
			}),
			TextStyle,
			Underline,
			StarterKit.configure({
				bulletList: {
					keepMarks: true,
					keepAttributes: false,
				},
				orderedList: {
					keepMarks: true,
					keepAttributes: false,
				},
			}),
		],
		content,
		editable,
	});

	useEffect(() => {
		if (lastUpdatedWidget && lastUpdatedWidget.widgetDetails.subDocumentId === subDocumentId && editor) {
			const timeout = setTimeout(() => {
				if (lastUpdatedWidget.actionType === WidgetActionType.ADD) {
					editor
						.chain()
						.focus()
						.insertAdvancedContent({
							...lastUpdatedWidget.widgetDetails.attrs,
						})
						.createParagraphNear()
						.run();
				} else if (lastUpdatedWidget.actionType === WidgetActionType.EDIT) {
					editor
						.chain()
						.focus()
						.updateAttributes('smp_widget', {
							...lastUpdatedWidget.widgetDetails.attrs,
						})
						.run();
				}
			}, COLLABORATIVE_EDITOR_CONTENT_UPDATE_TIMEOUT);

			return () => clearTimeout(timeout);
		}
	}, [lastUpdatedWidget, editor, subDocumentId]);

	useEffect(() => {
		if (!onUpdate || !editor) {
			return;
		}

		const callback = ({ editor }: { editor: Editor }) => {
			onUpdate(editor);
		};

		editor.on('update', callback);

		return () => {
			editor.off('update', callback);
		};
	}, [editor, onUpdate]);

	useEffect(() => {
		if (editor && resetToken !== undefined && resetToken !== null) {
			const timeout = setTimeout(() => editor.commands.clearContent(), COLLABORATIVE_EDITOR_CONTENT_UPDATE_TIMEOUT); // Timeout is necessary in order to avoid a Tiptap bug: https://github.com/ueberdosis/tiptap/issues/3764

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [editor, resetToken]);

	useEffect(() => {
		if (!initialized.current) {
			initialized.current = true;
			return;
		}

		if (editor) {
			let timeout: NodeJS.Timeout;

			if (content) {
				timeout = setTimeout(() => editor.commands.setContent(content), COLLABORATIVE_EDITOR_CONTENT_UPDATE_TIMEOUT); // Timeout is necessary in order to avoid a Tiptap bug: https://github.com/ueberdosis/tiptap/issues/3764
			} else {
				timeout = setTimeout(() => editor.commands.clearContent(), COLLABORATIVE_EDITOR_CONTENT_UPDATE_TIMEOUT); // Timeout is necessary in order to avoid a Tiptap bug: https://github.com/ueberdosis/tiptap/issues/3764
			}

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [content]);

	if (!editor) {
		return null;
	}

	return (
		<>
			<div>
				{editable && <EditorToolbar editor={editor} linkClicked={linkClicked}></EditorToolbar>}
				<EditorContent editor={editor} />
			</div>
			{editable && <ContentBlocksSelection openModalCallback={openModalCallback} subDocumentId={subDocumentId} editor={editor} />}
		</>
	);
};

export default CollaborativeEditor;

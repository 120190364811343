import axios from 'axios';
import { store } from '../../store/store';
import { loaderUiService } from '../../views/Partials/Blocky/blocks/editor/editor-block-edit.component';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';

export default class AutoTagHttp {
	source = {};

	cancel() {
		if (this.source.hasOwnProperty('cancel')) {
			this.source.cancel('Cancelled because of component close!');
		}
	}

	instanceAutoTag = () =>
		axios.create({
			baseURL: REACT_APP_URLS.REACT_APP_AUTO_TAGGING_URL,
			headers: {
				'X-Project': store.getState().project.currentProject.domain,
			},
		});

	getTags = (text, id) => {
		const regex = /(<([^>]+)>)/gi;
		const regexSpace = /&nbsp;/gi;
		const lang = store.getState().project.currentProject.language.split('-')[0];
		const postObj = { input_language: lang, text: text.replace(regex, '').replace(regexSpace, ' ').trim() };

		return new Promise((resolve, reject) => {
			const cancelToken = axios.CancelToken;
			this.source = cancelToken.source();
			loaderUiService.toggleIsAutolinking({ isLoading: true, id });
			this.instanceAutoTag()
				.post(null, postObj, { cancelToken: this.source.token })
				.then((response) => {
					let data = {};
					Object.keys(response.data).forEach((key, index) => {
						let dataInner = {};
						dataInner = response.data[key][0].entity;
						dataInner['source'] = response.data[key][0].source;
						dataInner['type'] = response.data[key][0].type;
						dataInner['subtype'] = response.data[key][0].subtype;
						dataInner['more'] = response.data[key].length;
						dataInner['moreTags'] = JSON.parse(JSON.stringify(response.data[key] ? response.data[key] : []));
						data[key] = [dataInner];
					});

					loaderUiService.toggleIsAutolinking({ isLoading: false, id });
					resolve(Object.assign({}, data));
				})
				.catch((e) => {
					loaderUiService.toggleIsAutolinking({ isLoading: false, id });
					reject(e);
				});
		});
	};
}

import React from 'react';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import { Properties, State } from './Properties/ImageListingProperties';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
	IMAGES_RECEIVED,
	IMAGES_REQUEST,
	imageSearchRequest,
	imagesRequest,
	returnObjectForImageFiltersUpdate,
} from '../../../store/action-creators/ImageActionCreator';
import { Button, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { ImageCells } from './Subcomponents/image-cells/ImageCells';
import ImageEditModal from './Subcomponents/image-edit-modal/ImageEditModal';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import ReactPaginate from 'react-paginate';
import { PaginationProps } from '../../Partials/Sidebar/Media/subcomponents/media-main-image/subcomponents/image-listing/properties/ImageListPaginateProps';
import { Title } from '../../Partials/BaseComponents/ListingComponent/subcomponents/ContentTitle/ContentTitle';
import Image from '../../../models/image/Image';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import ConditionalRenderWrapper, {
	ConditionalRenderContext,
} from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { actionService } from '../../../App';
import LightboxContainer from './Subcomponents/lightbox/LightboxContainer';
import ImageCropContainer from '../../Partials/Shared/image-crop/image-crop-container';
import ImageUploadButtonContainer from '../../Partials/Shared/image-upload/image-upload-button-container';
import AdvancedContentFilters from '../../Partials/Shared/advanced-content-filter/advanced-content-filter.component';
import { Subscription } from 'rxjs';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import { ContentTypes } from '../../../constants/content-types';
import AdvancedFilterModel from '../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import { extractSearchQueryFromFilters } from '../../Partials/Shared/advanced-content-filter/helpers/advanced-content-filter.helper';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import { checkForWatermarkFeature } from '../../../services/watermark-service/watermark-helper';
import { returnObjectForWikiPaginationUpdate } from '../../../store/action-creators/WikiActionCreator';

class ImageListing extends React.Component<Properties, State> {
	actionServiceSubscription: Subscription = {} as Subscription;
	watermarkEnabled = checkForWatermarkFeature();

	constructor(props: any) {
		super(props);
		this.state = {
			imageSearchInput: props.imageFilters && props.imageFilters.searchText ? props.imageFilters.searchText : '',
			searchedText: '',
			currentPage: '',
			imageId: '',
			showCropModal: false,
			showEditModal: false,
			enlargeImage: false,
			imageToEnlarge: {} as Image,
			isContentLoading: false,
			advancedContentSearchInput: '',
			pageSearchQuery: '',
		};
		this.registerActionEventListener();
	}

	setCurrentPage(page: string) {
		this.props.analytics.sendPageView(this.props.location.pathname, `${parseInt(page) > 1 ? 'Images - Page ' + page : 'Images'}`);
		this.setState({
			...this.state,
			currentPage: page,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'Images');
		this.requestImages();
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateWikiPagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, this.props.imageFilters, {});
		this.props.updateContentFilters(filters);
	}

	componentWillUnmount() {
		this.actionServiceSubscription.unsubscribe();
	}

	initPageTitle() {
		document.title = this.props.t('images');
	}

	requestImages(page?: string) {
		const { currentProject, pagination } = this.props;

		const searchQuery = this.state.pageSearchQuery.length > 0 ? this.state.pageSearchQuery : '';
		const queryFromFilters = extractSearchQueryFromFilters(this.props.imageFilters);

		const selectedCurrentPage = page ? page : pagination.currentPage ? pagination.currentPage.toString() : '1';

		queryFromFilters.length > 0 && queryFromFilters !== '*'
			? // get images by selected filters
			  this.props.getImages(selectedCurrentPage ? selectedCurrentPage : '1', currentProject, queryFromFilters)
			: this.props.getImages(selectedCurrentPage ? selectedCurrentPage : '1', currentProject, searchQuery);
	}

	toggleEditModal(imageId: string, isOpen: boolean) {
		this.setState({
			...this.state,
			showEditModal: isOpen,
			imageId,
		});
	}

	toggleCropModal(imageId: string, isOpen: boolean) {
		this.setState({
			...this.state,
			showCropModal: isOpen,
			imageId,
		});
	}

	toggleImageEnlarge = (image: Image, enlarge: boolean) => {
		this.setState({
			...this.state,
			enlargeImage: enlarge,
			imageToEnlarge: image,
		});
	};

	displayPagination(pagination: PaginationMeta) {
		return (
			<ReactPaginate
				{...PaginationProps(this.props.t, pagination.currentPage)}
				pageCount={pagination.totalPages ? pagination.totalPages : 0}
				onPageChange={(data: any) => this.onPageChange(data.selected + 1)}
			/>
		);
	}

	onContentSearchInput = (data: any) => {
		let searchText = data.target.value;
		this.setState({ imageSearchInput: searchText });
		this.props.imageFiltersUpdate(AdvancedFilterModel.builder(this.props.imageFilters).withSearchText(searchText).build());

		if (searchText.length < 1 && this.state.advancedContentSearchInput.length < 1) {
			this.props.analytics.sendEvent('Click', 'List Page', 'Search Performed');
			this.props.imageFiltersUpdate(AdvancedFilterModel.builder(this.props.imageFilters).withSearchText('').build());
			this.setState(
				(state) => {
					return { ...state, imageSearchInput: '', searchedText: '', pageSearchQuery: '' };
				},
				() => this.requestImages(),
			);
		}
	};

	onImageSearch(event: any) {
		if (event.keyCode === 13 && (this.state.imageSearchInput.length > 0 || this.state.advancedContentSearchInput.length > 0)) {
			this.onSearchContent(this.state.imageSearchInput + this.state.advancedContentSearchInput);
		}
	}

	onSearchContent = (text: string) => {
		const { advancedContentSearchInput } = this.state;

		if (text.trim().length === 0 && advancedContentSearchInput.trim().length === 0) {
			this.setState(
				(state) => {
					return { ...state, imageSearchInput: '', searchedText: '', pageSearchQuery: '' };
				},
				() => this.requestImages(),
			);
		} else {
			const searchText = text.length > 0 ? text + advancedContentSearchInput : `*${advancedContentSearchInput}`;
			this.props.searchImage(searchText, this.props.currentProject);
			this.setState({ ...this.state, searchedText: this.state.imageSearchInput, pageSearchQuery: searchText });
		}
	};

	onPageChange = (page: any) => {
		const { advancedContentSearchInput, imageSearchInput } = this.state;

		if (imageSearchInput.trim().length === 0 && advancedContentSearchInput.trim().length === 0) {
			this.setState(
				(state) => {
					return { ...state, imageSearchInput: '', searchedText: '', pageSearchQuery: '' };
				},
				() => this.requestImages(page),
			);
		} else {
			const searchText = imageSearchInput.length > 0 ? imageSearchInput + advancedContentSearchInput : `*${advancedContentSearchInput}`;
			this.requestImages(page);
			this.setState({ ...this.state, searchedText: this.state.imageSearchInput, pageSearchQuery: searchText });
		}
	};

	registerActionEventListener() {
		this.actionServiceSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === IMAGES_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === IMAGES_RECEIVED) {
				this.toggleContentLoadingState(false);
			}
		});
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState((state: State) => {
			return { ...state, isContentLoading: isLoading };
		});
	}

	updateAdvancedInputState = (text: string) => {
		this.props.imageFiltersUpdate(AdvancedFilterModel.builder(this.props.imageFilters).withSearchText(this.state.imageSearchInput).build());
		this.setState({ ...this.state, advancedContentSearchInput: text });
	};

	filterContent = () => {
		const { advancedContentSearchInput, imageSearchInput } = this.state;

		if (imageSearchInput.trim().length === 0 && advancedContentSearchInput.trim().length === 0) {
			this.setState(
				(state) => {
					return { ...state, imageSearchInput: '', searchedText: '', pageSearchQuery: '' };
				},
				() => this.requestImages(),
			);
		} else {
			const searchText = imageSearchInput.length > 0 ? imageSearchInput + advancedContentSearchInput : `*${advancedContentSearchInput}`;
			this.props.searchImage(searchText, this.props.currentProject);
			this.setState({ ...this.state, searchedText: this.state.imageSearchInput, pageSearchQuery: searchText });
		}
	};

	getAllContent = () => {
		this.props.getImages('1', this.props.currentProject, '');
	};

	render() {
		const { images, t, currentProject, pagination } = this.props;
		const { imageId, showEditModal, showCropModal, enlargeImage, imageToEnlarge } = this.state;

		return (
			<ConditionalRenderWrapper expectedPermissions={['read_images', 'write_images', 'delete_images']}>
				<div className='animated fadeIn'>
					<div className='card'>
						<Title title={t('images')} />
						<div className='card-body'>
							<div className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
								<Row>
									<Col md='6'>
										<ConditionalRenderContext.Consumer>
											{(value) => {
												return (
													<ImageUploadButtonContainer
														imageUploadButtonId={'image-listing-upload-button'}
														display={value.hasWritePermission}
														onImageUploadSuccess={() => {
															this.requestImages();
														}}
														project={currentProject}
														applyQuickWatermark={false}
														t={t}
													/>
												);
											}}
										</ConditionalRenderContext.Consumer>
									</Col>
									<Col md='6' className='d-flex flex-column'>
										<InputGroup>
											<Input
												type='text'
												id='search-input-image-listing'
												name='search-input'
												placeholder={this.props.t('content_search_placeholder')}
												onChange={this.onContentSearchInput.bind(this)}
												onKeyDown={this.onImageSearch.bind(this)}
												value={this.state.imageSearchInput}
											/>
											<InputGroupAddon addonType='append'>
												<Button
													type='button'
													color='primary'
													id='image-listing-search-button'
													onClick={() => this.onSearchContent(this.state.imageSearchInput)}
												>
													<i className={'fa fa-search'}>&nbsp;</i>
													{this.props.t('search')}
												</Button>
											</InputGroupAddon>
										</InputGroup>
									</Col>
								</Row>
								<Row className='mb-2'>
									<Col className='ml-auto'>
										<AdvancedContentFilters
											t={t}
											filtersInSidebar={true}
											searchContent={this.filterContent}
											updateAdvancedSearchText={this.updateAdvancedInputState}
											getAllContent={this.getAllContent}
											contentSearchInput={this.state.imageSearchInput}
											showOriginSelect={true}
											calledFrom='image-listing'
											contentType={ContentTypes.IMAGE}
										/>
									</Col>
								</Row>
								<Row className={'mb-3'}>
									<ImageCells
										images={images}
										onEdit={(imageId: string) => {
											this.toggleEditModal(imageId, true);
										}}
										onImageClick={(image: Image) => {
											this.toggleImageEnlarge(image, true);
										}}
										onCrop={(imageId: string) => {
											this.toggleCropModal(imageId, true);
										}}
										t={t}
									/>
								</Row>
								{images && images.length === 0 && <h6 className='d-flex justify-content-center mb-2'>{this.props.t('no_data_found')}</h6>}
							</div>
							<ImageEditModal
								onClose={(imageUpdated: boolean) => {
									this.toggleEditModal('', false);

									if (imageUpdated) {
										this.requestImages();
									}
								}}
								imageId={imageId}
								currentProject={currentProject}
								open={showEditModal}
								watermarkEnabled={this.watermarkEnabled}
								t={t}
								isPlayingSurface={false}
								isImagoImage={false}
							/>
							{this.displayPagination(pagination)}
						</div>
					</div>
				</div>
				<ImageCropContainer
					t={t}
					imageId={imageId}
					currentProject={currentProject}
					open={showCropModal}
					onClose={() => {
						this.toggleCropModal('', false);
					}}
				/>
				{enlargeImage && (
					<LightboxContainer
						t={t}
						currentProject={currentProject}
						imageToEnlarge={imageToEnlarge}
						toggleImageEnlarge={this.toggleImageEnlarge}
					/>
				)}
			</ConditionalRenderWrapper>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		images: state.image.images,
		currentProject: state.project.currentProject,
		pagination: state.image.pagination,
		imageFilters: state.image.filters,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getImages: (page: string, project: Project, text: string) => dispatch(imagesRequest(page, project, text)),
		searchImage: (text: string, project: Project) => dispatch(imageSearchRequest(text, project)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateWikiPagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForWikiPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		imageFiltersUpdate: (filters: any) => dispatch(returnObjectForImageFiltersUpdate(filters)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ImageListing) as React.ComponentType<Properties>;

import React from 'react';
import './styles/imago-images-block.component.scss';
import { blockManagementService, blockWrapperService } from '../../../subcomponents/blocky.component';
import BlockValidation from '../../../helpers/block-validation.helper';
import ImagoImageContainer from './imago-image-container.component';
import BlockModel from '../../../models/block.model';
import ImagoImageModel from './models/imago-image.model';

export type Properties = {
	block: BlockModel;
	t: any;
	blocks: BlockModel[];
};

export type State = {
	selectedImagoImage: ImagoImageModel;
	isValid: boolean;
	searchText: string;
	searchIn: string;
	pageOffset: number;
	selectedImageType: string;
};

class ImagoImageEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			selectedImagoImage:
				props.block && props.block.data && props.block.data.id ? props.block.data.preview.image : ImagoImageModel.builder().build(),
			isValid: true,
			searchText: '',
			searchIn: '',
			pageOffset: props.block && props.block.data && props.block.data.preview ? props.block.data.preview.pageOffset : 0,
			selectedImageType: '',
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const { selectedImagoImage, searchIn, pageOffset, selectedImageType } = this.state;
		const data = { imagoImage: selectedImagoImage };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = selectedImagoImage.id;
			const imageObject = ImagoImageModel.builder()
				.withId(selectedImagoImage.id)
				.withType(selectedImagoImage.type)
				.withSource(selectedImagoImage.source)
				.withWidth(selectedImagoImage.width)
				.withHeight(selectedImagoImage.height)
				.withImage(selectedImagoImage.image)
				.withDescription(selectedImagoImage.description)
				.withDate(selectedImagoImage.dateCreated)
				.build();
			block.data.content = imageObject;
			block.data.preview = { image: selectedImagoImage, searchInput: searchIn, pageOffset: pageOffset, selectedImageType: selectedImageType };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	selectImagoImage = (image: ImagoImageModel) => {
		this.setState({ ...this.state, selectedImagoImage: image });
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateSearchInput = (search: string, pageOffset: number, imageType: string) => {
		this.setState({ ...this.state, searchText: search, searchIn: search, pageOffset: pageOffset, selectedImageType: imageType });
	};

	render() {
		const { t, blocks } = this.props;

		return (
			<div className={'mb-2'}>
				<ImagoImageContainer
					t={t}
					isMediaSidebar={false}
					onSelect={this.selectImagoImage}
					updateSearchInput={this.updateSearchInput}
					block={this.props.block}
					selectedImagoImage={this.state.selectedImagoImage}
					blocks={blocks}
					isValid={this.state.isValid}
					inBlocky={true}
					pageOffset={this.state.pageOffset}
					{...this.props}
				/>
			</div>
		);
	}
}

export default ImagoImageEdit;

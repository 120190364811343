import BookmakerModel from '../models/bookmaker.model';
import { TypedJSON } from 'typedjson';

export const bookmakerToOption = (bookmaker: BookmakerModel) => {
	if (bookmaker && bookmaker.id) {
		return {
			data: bookmaker,
			value: bookmaker.id,
			label: bookmaker.name,
			logo: bookmaker.logo,
			type: 'bookmaker',
		};
	}

	return {};
};

export const optionListToBookmakerList = (selectionList: any) => {
	if (selectionList && selectionList.length > 0) {
		return selectionList.map((selection: any) => {
			return selection.data;
		});
	}

	return [];
};

export const bookmakerListToOptionList = (bookmakerList: BookmakerModel[]) => {
	if (bookmakerList && bookmakerList.length > 0) {
		return bookmakerList.map((bookmaker: BookmakerModel) => bookmakerToOption(bookmaker));
	}

	return [];
};

export const responseToBookmakerModel = (response: any) => {
	const serializer = new TypedJSON(BookmakerModel);

	return serializer.parse(response) as BookmakerModel;
};

export const remapResponseToBookmakerModel = (response: any, remapOddProviderFlag?: boolean) => {
	return response.data.map((bookmaker: any) => responseToBookmakerModel(remapOddProviderFlag ? bookmaker.odd_provider : bookmaker));
};
